import {useCallback, useEffect, useRef, useState} from 'react';
import find from 'lodash/find';
import inRange from 'lodash/inRange';
import get from 'lodash/get';

import {useIsMobile} from '@/hooks';

const IPHONE_MODELS = [
  {
    model: 'iPhone 8',
    toolbarHeightDeltaRange: [75, 85],
    toolbarHeight: 45,
  },
  {
    model: 'iPhone X',
    toolbarHeightDeltaRange: [113, 123],
    toolbarHeight: 84,
  },
];

const useMobileSizings = () => {
  const isMobile = useIsMobile();
  const initialViewportHeight = useRef(0);
  const [toolbarClearance, setToolbarClearance] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    initialViewportHeight.current = window.innerHeight;
  }, []);

  const handleResize = useCallback(() => {
    const viewport = window.visualViewport;
    const unscaledHeight = viewport.height * viewport.scale;
    setInnerHeight(unscaledHeight);

    let threshold = window.innerHeight - unscaledHeight;
    const isLandscape = window.orientation === 90 || window.orientation === -90;

    const isIOS15 = navigator.userAgent.indexOf('iPhone OS 15') !== -1;
    if (threshold > 0 && isIOS15) {
      threshold += 55;
    }

    setOffsetY(isLandscape ? 0 : threshold);

    const heightDifference = window.innerHeight - initialViewportHeight.current;
    const device = find(IPHONE_MODELS, ({toolbarHeightDeltaRange: [min, max]}) => inRange(heightDifference, min, max + 1));
    setToolbarClearance(get(device, 'toolbarHeight', 0));
  }, []);

  useEffect(() => {
    if (!isMobile) return;
    if (!window.visualViewport) return;

    window.visualViewport.addEventListener('resize', handleResize);
    window.visualViewport.addEventListener('scroll', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.visualViewport.removeEventListener('scroll', handleResize);
    };
  }, [isMobile, handleResize]);

  return {
    toolbarClearance,
    offsetY,
    innerHeight,
  };
};

export default useMobileSizings;
