import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

import {snakifyKeys, flattenObject} from '@/util';

const datetimeRegex = /^\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d(\.\d+)?)?(Z|[-+]\d\d:\d\d))?$/;

export const mixpanelPropertiesToFullstory = properties => (
  mapValues(snakifyKeys(properties), value =>
    typeof(value) === 'string' && datetimeRegex.test(value) ? new Date(value) : value
  )
);

export const prepareMetadataMixpanelProps = (data) => {
  const mixpanelProps = {...data};
  if (mixpanelProps.song_relationships) {
    mixpanelProps.num_song_relationships = mapValues(
      keyBy(mixpanelProps.song_relationships, 'relationshipType'),
      relationship => relationship.songs.length,
    );
    Reflect.deleteProperty(mixpanelProps, 'song_relationships');
  }

  return flattenObject({updated_fields: mixpanelProps});
};
