import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {InlineSvg} from '@/components';
import Checkmark from '@/svg/checkmark.svg';
import Eyedropper from '@/svg/eyedropper.svg';
import XSmall from '@/svg/xSmall.svg';

const ColorPicker = ({
  className,
  color,
  contrast,
  isValid,
  onChange,
  onReset,
  showReset,
  text,
  textColor,
}) => {
  const {t} = useTranslation();
  return (
    <ColorPicker.Container className={className}>
      <ColorPicker.Swatch $color={color} textColor={textColor}>
        <ColorPicker.ColorValue>
          {text}:
          <ColorPicker.Contrast isValid={isValid}>
            {contrast.toFixed(2)} <InlineSvg aspectRatio={1} lineHeight={.75} svg={isValid ? Checkmark : XSmall} />
          </ColorPicker.Contrast>
        </ColorPicker.ColorValue>
      </ColorPicker.Swatch>
      <ColorPicker.Controls>
        <ColorPicker.UpdateControl>
          <ColorPicker.UpdateLabel>
            <ColorPicker.Input type="color" onChange={evt => onChange(evt.target.value)} value={color} />
            <InlineSvg aspectRatio={1} lineHeight={1.25} svg={Eyedropper} />
            <ColorPicker.UpdateLabelText>{t('edit_metadata.gradient.update')}</ColorPicker.UpdateLabelText>
          </ColorPicker.UpdateLabel>
        </ColorPicker.UpdateControl>
        {showReset && (
          <ColorPicker.ResetButton onClick={onReset}>{t('edit_metadata.gradient.reset')}</ColorPicker.ResetButton>
        )}
      </ColorPicker.Controls>
      {!isValid && <ColorPicker.Invalid>{t('edit_metadata.gradient.low_contrast')}</ColorPicker.Invalid>}
    </ColorPicker.Container>
  );
};

ColorPicker.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  contrast: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  showReset: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default ColorPicker;

ColorPicker.Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
`;

ColorPicker.Swatch = styled.div.attrs(props => ({
  style: {
    color: props.textColor,
    backgroundColor: props.$color,
  },
}))`
  min-height: 120px;
  padding: ${p => p.theme.space.full};
  margin-bottom: ${p => p.theme.space.half};
  border: 1px solid ${p => p.theme.color.background.on};
  svg {
    fill: ${p => p.textColor};
  }
`;

ColorPicker.Controls = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: ${p => p.theme.fontSize.reading};
  font-size: ${p => p.theme.fontSize.smallReading};
`;

ColorPicker.ResetButton = styled.button`
  text-decoration: underline;
  line-height: inherit;
  font-size: ${p => p.theme.fontSize.smallReading};
`;

ColorPicker.Contrast = styled.div`
  line-height: .75;
  margin-top: ${p => p.theme.space.quarter};

  svg {
    fill: ${p => p.isValid ? p.textColor : p.theme.color.error.main};
  }
`;

ColorPicker.Invalid = styled.p`
  margin-top: ${p => p.theme.space.half};
  color: ${p => p.theme.color.error.main};
  font-size: ${p => p.theme.fontSize.smallReading};
`;

ColorPicker.UpdateControl = styled.div`
  flex-grow: 1;
`;

ColorPicker.UpdateLabel = styled.label`
  vertical-align: top;
  cursor: pointer;

  &:focus-within {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

ColorPicker.UpdateLabelText = styled.span`
  text-decoration: underline;
  margin-left: ${p => p.theme.space.hair};
`;

ColorPicker.Input = styled.input`
  opacity: 0;
  height: 1px;
  width: 1px;
`;

ColorPicker.ColorValue = styled.div`
  flex-grow: 1;
`;
