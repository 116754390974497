export const MORE_ANNOTATION_COMMENTS_CLICKED = 'songPage/MORE_ANNOTATION_COMMENTS_CLICKED';
export const moreAnnotationCommentsClicked = annotationId => ({
  type: MORE_ANNOTATION_COMMENTS_CLICKED,
  annotationId,
});

export const ANNOTATION_COMMENTS_FETCHED = 'songPage/ANNOTATION_COMMENTS_FETCHED';
export const annotationCommentsFetched = (annotationId, {result, entities}) => ({
  type: ANNOTATION_COMMENTS_FETCHED,
  annotationId,
  result,
  entities,
});

export const ANNOTATION_COMMENTS_FETCH_FAILED = 'songPage/ANNOTATION_COMMENTS_FETCH_FAILED';
export const annotationCommentsFetchFailed = annotationId => ({
  type: ANNOTATION_COMMENTS_FETCH_FAILED,
  annotationId,
});

export const ANNOTATION_COMMENT_SUBMITTED = 'songPage/ANNOTATION_COMMENT_SUBMITTED';
export const annotationCommentSubmitted = (annotationId, {body, reason, name, email, userId, onSuccess, onError}) => ({
  type: ANNOTATION_COMMENT_SUBMITTED,
  annotationId,
  body,
  reason,
  name,
  email,
  userId,
  onSuccess,
  onError,
});

export const ANNOTATION_COMMENT_SUCCEEDED = 'songPage/ANNOTATION_COMMENT_SUCCEEDED';
export const annotationCommentSucceeded = (annotationId, {result, entities}) => ({
  type: ANNOTATION_COMMENT_SUCCEEDED,
  annotationId,
  result,
  entities,
});

export const ANNOTATION_COMMENT_MODERATION_SUBMITTED = 'songPage/ANNOTATION_COMMENT_MODERATION_SUBMITTED';
export const annotationCommentModerationSubmitted = (annotationId, {commentId, moderationType, onSuccess, onError}) => ({
  type: ANNOTATION_COMMENT_MODERATION_SUBMITTED,
  annotationId,
  commentId,
  moderationType,
  onSuccess,
  onError,
});

export const ANNOTATION_COMMENT_MODERATION_SUCCEEDED = 'songPage/ANNOTATION_COMMENT_MODERATION_SUCCEEDED';
export const annotationCommentModerationSucceeded = (annotationId, {result, entities}) => ({
  type: ANNOTATION_COMMENT_MODERATION_SUCCEEDED,
  annotationId,
  result,
  entities,
});
