import {combineReducers} from 'redux';
import userActions from './userActions';
import openHeaderItem from './openHeaderItem';
import entities from './entities';
import ads from './ads';
import stickyNav from './stickyNav';
import modal from './modal';
import authModal from './authModal';
import mergeWith from 'lodash/mergeWith';
import toast from './toast';

const sharedSlices = {
  userActions,
  openHeaderItem,
  entities,
  ads,
  stickyNav,
  modal,
  authModal,
  toast,
  currentPage: s => s,
  deviceType: s => s,
  session: s => s,
};

const mergeReducerFunctions = (existingValue, newValue) => {
  if (existingValue) {
    return (state = {}, action) => existingValue(newValue(state, action), action);
  } else {
    return (state = {}, action) => newValue(state, action);
  }
};

export const mergeSlices = (...partialReducers) =>
  combineReducers(mergeWith({}, ...partialReducers, mergeReducerFunctions));

export default pageSlices => mergeSlices(sharedSlices, pageSlices);
