import {get, put} from './client';
import {chartItemSchema, normalize} from '@/normalize';

export const getChartItems = async ({chartType = 'songs', chartGenre = 'all', timePeriod = 'day', page = 1, perPage = 10}) => {
  const response = await get(`/${chartType}/chart`, {
    time_period: timePeriod,
    chart_genre: chartGenre,
    page,
    per_page: perPage,
  });
  return normalize(response, {
    chartItems: [chartItemSchema],
  });
};

export const getChartState = async ({chartType = 'songs', id}) => {
  const response = await get(`/${chartType}/${id}/charts/state`);
  return response;
};

export const excludeChartItem = async ({chartType = 'songs', id}) => {
  const {chart_item} = await put(`/${chartType}/${id}/charts/exclude`);
  return normalize(chart_item, chartItemSchema);
};

export const unexcludeChartItem = async ({chartType = 'songs', id}) => {
  const {chart_item} = await put(`/${chartType}/${id}/charts/unexclude`);
  return normalize(chart_item, chartItemSchema);
};
