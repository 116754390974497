import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import {calculateContrast} from '@/util/color';
import ColorPicker from './ColorPicker';

const ACCEPTABLE_CONTRAST_VALUE = 4.5;
const BLACK_HEX = '#000000';
const WHITE_HEX = '#ffffff';

const isAcceptableContrast = contrast => contrast >= ACCEPTABLE_CONTRAST_VALUE;

const GradientColorPicker = ({
  helperText,
  id,
  previewImage,
  previewImageAltText,
  previewTitle,
  previewArtist,
  initialPrimaryColor,
  initialSecondaryColor,
  initialTextColor,
  onSelection,
  selectionDebounceDuration,
}) => {
  const {t} = useTranslation();
  const [primaryColor, setPrimaryColor] = useState(initialPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState(initialSecondaryColor);
  const [textColor, setTextColor] = useState(initialTextColor);
  const callback = useRef(
    debounce(colors => onSelection(colors), selectionDebounceDuration)
  );

  const onPrimaryChange = (newColor) => {
    const contrastWithBlack = calculateContrast(newColor, BLACK_HEX);
    const newTextColor = isAcceptableContrast(contrastWithBlack) ? BLACK_HEX : WHITE_HEX;
    setPrimaryColor(newColor);
    setTextColor(newTextColor);
  };
  const onPrimaryReset = () => {
    setPrimaryColor(initialPrimaryColor);
    setTextColor(initialTextColor);
  };

  const onSecondaryChange = newColor => setSecondaryColor(newColor);
  const onSecondaryReset = () => setSecondaryColor(initialSecondaryColor);

  const primaryContrast = calculateContrast(primaryColor, textColor);
  const secondaryContrast = calculateContrast(secondaryColor, textColor);

  useEffect(() => {
    const isValid = isAcceptableContrast(primaryContrast) && isAcceptableContrast(secondaryContrast);
    callback.current({primaryColor, secondaryColor, textColor, isValid});
  }, [primaryColor, secondaryColor, textColor, primaryContrast, secondaryContrast]);

  return (
    <GradientColorPicker.Container id={id}>
      <GradientColorPicker.HelperText>{helperText}</GradientColorPicker.HelperText>
      <GradientColorPicker.Preview primaryColor={primaryColor} secondaryColor={secondaryColor} textColor={textColor}>
        <GradientColorPicker.PreviewImage>
          <img src={previewImage} alt={previewImageAltText} />
        </GradientColorPicker.PreviewImage>
        <div>
          <GradientColorPicker.PreviewTitle>{previewTitle}</GradientColorPicker.PreviewTitle>
          <div>{previewArtist}</div>
        </div>
      </GradientColorPicker.Preview>
      <GradientColorPicker.Blocks>
        <GradientColorPicker.Picker
          color={primaryColor}
          textColor={textColor}
          contrast={primaryContrast}
          text={t('edit_metadata.gradient.primary_contrast')}
          isValid={isAcceptableContrast(primaryContrast)}
          onChange={onPrimaryChange}
          showReset={primaryColor !== initialPrimaryColor}
          onReset={onPrimaryReset}
        />
        <GradientColorPicker.Picker
          color={secondaryColor}
          textColor={textColor}
          contrast={secondaryContrast}
          text={t('edit_metadata.gradient.secondary_contrast')}
          isValid={isAcceptableContrast(secondaryContrast)}
          onChange={onSecondaryChange}
          showReset={secondaryColor !== initialSecondaryColor}
          onReset={onSecondaryReset}
        />
      </GradientColorPicker.Blocks>
    </GradientColorPicker.Container>
  );
};

export default React.memo(GradientColorPicker);

GradientColorPicker.propTypes = {
  helperText: PropTypes.string.isRequired,
  id: PropTypes.string,
  previewImage: PropTypes.string.isRequired,
  previewImageAltText: PropTypes.string.isRequired,
  previewTitle: PropTypes.string.isRequired,
  previewArtist: PropTypes.string.isRequired,
  initialPrimaryColor: PropTypes.string.isRequired,
  initialSecondaryColor: PropTypes.string.isRequired,
  initialTextColor: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  selectionDebounceDuration: PropTypes.number,
};

GradientColorPicker.defaultProps = {
  initialTextColor: BLACK_HEX,
  selectionDebounceDuration: 100,
};

GradientColorPicker.Container = styled.div`
  width: 100%;
  font-family: ${p => p.theme.font.alternate};
`;

GradientColorPicker.HelperText = styled.div`
  font-size: ${p => p.theme.fontSize.smallReading};
  color: ${p => p.theme.color.background.onVariant};
  margin-bottom: ${p => p.theme.space.small};
`;

GradientColorPicker.Preview = styled.div.attrs(props => ({
  style: {
    color: props.textColor,
    backgroundImage: `linear-gradient(${props.primaryColor}, ${props.secondaryColor})`,
  },
}))`
  margin-bottom: ${p => p.theme.space.full};
  padding: ${p => p.theme.space.xxLarge} ${p => p.theme.space.xLarge};
  display: flex;
  align-items: center;
`;

GradientColorPicker.PreviewImage = styled.div`
  border: 1px solid black;
  margin-right: ${p => p.theme.space.full};
  width: min(40%, 250px);
  flex-shrink: 0;

  img {
    display: block;
  }
`;

GradientColorPicker.PreviewTitle = styled.div`
  font-family: ${p => p.theme.font.reading};
  font-size: ${p => p.theme.fontSize.smallHeadline};
`;

GradientColorPicker.Blocks = styled.div`
  display: flex;
`;

GradientColorPicker.Picker = styled(ColorPicker)`
  width: 50%;
  &:not(:last-child) {
    margin-right: ${p => p.theme.space.full};
  }
`;
