export const CONTRIBUTORS_VISIBLE = 'songPage/CONTRIBUTORS_VISIBLE';
export const contributorsVisible = songId => ({
  type: CONTRIBUTORS_VISIBLE,
  songId,
});

export const CONTRIBUTORS_FETCHED = 'songPage/CONTRIBUTORS_FETCHED';
export const contributorsFetched = (songId, {entities, result}) => ({
  type: CONTRIBUTORS_FETCHED,
  songId,
  entities,
  result,
});

export const CONTRIBUTORS_FETCH_FAILED = 'songPage/CONTRIBUTORS_FETCH_FAILED';
export const contributorsFetchFailed = songId => ({
  type: CONTRIBUTORS_FETCH_FAILED,
  songId,
});

export const MORE_IQ_EARNERS_CLICKED = 'songPage/MORE_IQ_EARNERS_CLICKED';
export const moreIqEarnersClicked = (songId, page = 1) => ({
  type: MORE_IQ_EARNERS_CLICKED,
  songId,
  page,
});

export const MORE_IQ_EARNERS_FETCHED = 'songPage/MORE_IQ_EARNERS_FETCHED';
export const moreIqEarnersFetched = (songId, {entities, result}) => ({
  type: MORE_IQ_EARNERS_FETCHED,
  songId,
  entities,
  result,
});

export const MORE_IQ_EARNERS_FETCH_FAILED = 'songPage/MORE_IQ_EARNERS_FETCH_FAILED';
export const moreIqEarnersFetchFailed = songId => ({
  type: MORE_IQ_EARNERS_FETCH_FAILED,
  songId,
});
