import {useRef, useEffect} from 'react';

let counter = 0;

const usePrimisApi = (callbacks) => {
  const uniqueId = useRef();
  if (!uniqueId.current) {
    uniqueId.current = `geniusPrimisPlayer${counter++}`;
  }

  const primisPlayerRef = useRef();
  const currentCallbacks = useRef({});

  useEffect(() => {
    const registerApi = ({detail: primisPlayer}) => {
      if (primisPlayer.playerApiId === uniqueId.current) {
        primisPlayerRef.current = primisPlayer;

        Object.entries(currentCallbacks.current).forEach(([eventName, callback]) => {
          if (!callback.registeredObject) {
            callback.registeredObject = primisPlayer.addEventListener(eventName, callback.handler);
          }
        });
      }
    };

    window.addEventListener('primisPlayerInit', registerApi);

    return () => {
      window.removeEventListener('primisPlayerInit', registerApi);

      if (primisPlayerRef.current) {
        // unrelated warning about refs of React components
        // eslint-disable-next-line react-hooks/exhaustive-deps
        Object.values(currentCallbacks.current).forEach((callback) => {
          if (callback.registeredObject) {
            primisPlayerRef.current.removeEventListener(callback.registeredObject);
            callback.registeredObject = null;
          }
        });
        primisPlayerRef.current = null;
      }
    };
  }, []);

  const callbacksToAdd = new Set(Object.keys(callbacks));

  for (const [eventName, {handler, registeredObject}] of Object.entries(currentCallbacks.current)) {
    if (callbacks[eventName] !== handler) {
      if (primisPlayerRef.current) {
        primisPlayerRef.current.removeEventListener(registeredObject);
      }
    } else {
      callbacksToAdd.delete(eventName);
    }
  }

  for (const eventName of callbacksToAdd) {
    let registeredObject;

    if (primisPlayerRef.current) {
      registeredObject = primisPlayerRef.current.addEventListener(eventName, callbacks[eventName]);
    }

    currentCallbacks.current[eventName] = {
      handler: callbacks[eventName],
      registeredObject,
    };
  }

  return uniqueId.current;
};

export default usePrimisApi;
