import {post} from '@/api/client';
import {answerSchema, questionSchema, normalize} from '@/normalize';

const buildQuestionAnswerParams = payload => ({
  question: payload.question,
  answer: {
    pin_question: payload.pin_question,
    answer_source: payload.add_source ? payload.answer.answer_source : null,
    body: payload.answer.body,
  },
});

export const createQuestionAnswer = async (payload) => {
  const response = await post('/answers', buildQuestionAnswerParams(payload));
  return normalize(response, {answer: answerSchema, question: questionSchema});
};
