import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const TextLabel = styled.span`
  font-size: ${p => p.theme.fontSize[p.size]};
  font-weight: ${p => p.theme.fontWeight[p.fontWeight]};
  line-height: 1;
  letter-spacing: 1px;
  text-transform: ${p => p.textTransform};
  color: ${p => get(p.theme.color, p.color, p.theme.color.background.on)};
`;

TextLabel.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  fontWeight: PropTypes.string,
  textTransform: PropTypes.string,
};

TextLabel.defaultProps = {
  size: 'xSmallReading',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export default TextLabel;
