import React, {useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import MagnifyingGlass from '@/svg/magnifyingGlass.svg';
import {PageHeaderDropdown, PageHeaderSearchResults} from '@/components';
import {useHeaderItem, useOnClickOutside, useDebouncedCallback} from '@/hooks';
import {useTranslation} from 'react-i18next';

const PageHeaderSearch = () => {
  const {isOpen, open, close} = useHeaderItem('search');

  const {t} = useTranslation();
  const [query, setQuery] = useState('');
  const formElement = useRef(null);
  const inputElement = useRef(null);

  const debouncedSetQuery = useDebouncedCallback({
    callback: searchText => setQuery(searchText),
    delay: 500,
    dependencies: [],
  });

  const onClick = useCallback((event) => {
    event.preventDefault();
    if (inputElement.current.value.trim()) {
      formElement.current.submit();
    }
  }, []);

  const onChange = useCallback(() => {
    const searchInputText = inputElement.current.value.trim();
    if (searchInputText) {
      isOpen || open();
      debouncedSetQuery(searchInputText);
    } else {
      close();
    }
  }, [close, open, isOpen, debouncedSetQuery]);

  const onFocus = useCallback(() => {
    if (inputElement.current.value.trim()) {
      isOpen || open();
    }
  }, [isOpen, open]);

  const onClickOutside = useCallback(() => {
    if (isOpen) {
      close();
    }
  }, [isOpen, close]);
  useOnClickOutside({element: formElement, onClickOutside});

  return (
    <PageHeaderSearch.Form ref={formElement} action="/search" method="get">
      <PageHeaderSearch.Input
        onChange={onChange}
        onFocus={onFocus}
        name="q"
        placeholder={t('page_header.search_bar.placeholder')}
        ref={inputElement}
        autoComplete="off"
        required
      />
      <PageHeaderDropdown
        width="medium"
        align="left"
        isOpen={isOpen}
      >
        <PageHeaderSearchResults query={query} />
      </PageHeaderDropdown>
      <PageHeaderSearch.Icon onClick={onClick}>
        <MagnifyingGlass />
      </PageHeaderSearch.Icon>
    </PageHeaderSearch.Form>
  );
};

export default React.memo(PageHeaderSearch);

PageHeaderSearch.Form = styled.form`
  display: flex;
  background: ${p => p.theme.color.primary.main};
  margin-left: ${p => p.theme.space.xSmall};
  align-items: center;
  width: 220px;
  height: ${p => `calc(${p.theme.header.height} - 2*${p.theme.space.xSmall})`};
`;

PageHeaderSearch.Icon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0 ${p => p.theme.space.xSmall};

  svg {
    fill: ${p => p.theme.color.primary.on};
    width: 18px;
  }
`;

PageHeaderSearch.Input = styled.input`
  flex-grow: 1;
  position: relative;
  border: none;
  border-radius: 0;
  background: ${p => p.theme.color.primary.main};
  color: ${p => p.theme.color.primary.on};
  font-family: ${p => p.theme.font.accent};
  font-feature-settings: ${p => p.theme.font.programmeAlternates};
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  -webkit-appearance: none;
  overflow: hidden;
  resize: none;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${p => p.theme.color.primary.on}
  };
  ::-ms-input-placeholder {
    color: ${p => p.theme.color.primary.on}
  };
  &:focus::placeholder {
    color: ${p => p.theme.color.primary.onVariant}
  };
  padding: ${p => `0 ${p.theme.space.hair} 0 ${p.theme.space.half}`};
`;
