import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {useDeviceType} from '@/hooks';
import {buttonHover} from '@/style';

const IconButton = ({useBackgroundColor, ...props}) => {
  const size = useDeviceType({mobile: '1.5rem', desktop: '2.5rem'});
  return <IconButton.Button useBackgroundColor={useBackgroundColor} size={size} {...props} />;
};

IconButton.propTypes = {
  useBackgroundColor: PropTypes.bool,
};

IconButton.defaultProps = {
  useBackgroundColor: false,
};

export default React.memo(IconButton);

IconButton.Button = styled.button.attrs({type: 'button'})`
  width: ${p => p.size};
  height: ${p => p.size};
  cursor: pointer;
  color: inherit;
  background: inherit;
  font-weight: inherit;
  border: 1px solid ${p => p.useBackgroundColor ? p.theme.color.background.on : 'white'};
  border-radius: 50%;

  svg {
    display: inline;
    width: ${p => p.theme.space.half};
    height: ${p => p.theme.space.half};
    overflow: visible;
    margin: auto;
    fill: ${p => p.useBackgroundColor ? p.theme.color.background.on : 'white'};
  }

  &:hover {
    ${p => buttonHover(p.useBackgroundColor ? p.theme.color.background.on : 'white')}
  }
`;

