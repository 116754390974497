import React from 'react';
import PropTypes from 'prop-types';
import {useFormState, Controller} from 'react-hook-form';

import {FieldError} from './Field.shared';

const HiddenField = ({
  children,
  control,
  defaultValue,
  disabled,
  name,
  rules,
  showErrors,
}) => {
  const {errors} = useFormState({control});
  const fieldError = errors[name];

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({field, fieldState}) => children({
          dataTestId: `${name}-input`,
          disabled,
          value: field.value,
          hasError: fieldState.invalid,
          onChange: field.onChange,
        })}
        rules={rules}
      />
      {fieldError && showErrors && (
        <FieldError>{fieldError.message}</FieldError>
      )}
    </React.Fragment>
  );
};

export default React.memo(HiddenField);

HiddenField.propTypes = {
  children: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  showErrors: PropTypes.bool,
};

HiddenField.defaultProps = {
  defaultValue: '',
  disabled: false,
  rules: {},
  showErrors: true,
};
