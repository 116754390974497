import {useEffect, useCallback} from 'react';

export default ({element, onClickOutside}) => {
  const onClick = useCallback((event) => {
    if (element.current === null) return;
    if (element.current.contains(event.target)) return;
    if (event.composedPath().some(el => Boolean(el.dataset?.ignoreOnClickOutside))) return;
    onClickOutside(event);
  }, [onClickOutside, element]);

  useEffect(() => {
    document.addEventListener('click', onClick, {capture: true});
    document.addEventListener('touchend', onClick);
    return () => {
      document.removeEventListener('click', onClick, {capture: true});
      document.removeEventListener('touchend', onClick);
    };
  }, [onClick]);
};
