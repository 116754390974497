export const COVER_ART_COLOR_EXTRACTED = 'songPage/COVER_ART_COLOR_EXTRACTED';
export const coverArtColorExtracted = (songId, coverArtColors) => ({
  type: COVER_ART_COLOR_EXTRACTED,
  songId,
  coverArtColors,
});

export const COVER_ART_COLOR_EXTRACTION_FAILED = 'songPage/COVER_ART_COLOR_EXTRACTION_FAILED';
export const coverArtColorExtractionFailed = songId => ({
  type: COVER_ART_COLOR_EXTRACTION_FAILED,
  songId,
});
