import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StickyNavSectionLink = ({section, label, onClick, active}) => {
  const href = `#${section}`;
  const handleClick = useCallback((evt) => {
    evt.preventDefault();
    if (onClick) onClick();
    document.querySelector(href)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [href, onClick]);

  return (
    <StickyNavSectionLink.StyledLink href={href} onClick={handleClick} active={active}>
      {label}
    </StickyNavSectionLink.StyledLink>
  );
};

StickyNavSectionLink.propTypes = {
  section: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};
StickyNavSectionLink.defaultProps = {
  active: false,
};

StickyNavSectionLink.StyledLink = styled.a`
  font-size: ${p => p.theme.fontSize[p.fontSize]};
  font-weight: ${p => p.theme.fontWeight[p.fontWeight]};
  color: inherit;
  border: 1px solid ${p => p.active ? p.theme.color.background.on : 'transparent'};

  svg {
    fill: ${p => p.theme.color.background.on};
  }
`;


export default StickyNavSectionLink;
