import {useRef, useCallback} from 'react';

const useScrollLock = () => {
  const offset = useRef(0);

  const handleLock = useCallback(() => {
    offset.current = window.pageYOffset;
    document.body.classList.add('noscroll-fixed');
  }, []);

  const handleUnlock = useCallback(() => {
    document.body.classList.remove('noscroll-fixed');
    window.scrollTo(0, offset.current);
  }, []);

  return [handleLock, handleUnlock];
};

export default useScrollLock;
