import {produce} from 'immer';
import {
  INBOX_OPENED,
  INBOX_MARKED_READ,
  MESSAGE_OPENED,
  UNREAD_COUNTS_LOADED,
} from '@/actions';
import {capitalize} from '@/util';

export default produce((draft, action) => {
  switch (action.type) {
  case INBOX_OPENED: {
    const currentUser = draft.users[action.userId];
    if (action.inbox !== 'messagesInbox') {
      currentUser[`unread${capitalize(action.inbox)}Count`] = 0;
    }
    break;
  }
  case INBOX_MARKED_READ: {
    const currentUser = draft.users[action.userId];
    if (action.inbox !== 'messagesInbox') {
      currentUser[`unread${capitalize(action.inbox)}Count`] = 0;
    }
    break;
  }
  case MESSAGE_OPENED: {
    const currentUser = draft.users[action.userId];
    currentUser.unreadMessagesInboxCount = Math.max(currentUser.unreadMessagesInboxCount - action.messagesRead, 0);
    break;
  }
  case UNREAD_COUNTS_LOADED: {
    const currentUser = draft.users[action.userId];
    Object.keys(action.unreadCounts).forEach(inbox => currentUser[`unread${capitalize(inbox)}Count`] = action.unreadCounts[inbox]);
    break;
  }
  default:
    return draft;
  }
});
