export * from './stickyNav';
export * from './toast';

export const INBOX_OPENED = 'shared/INBOX_OPENED';
export const inboxOpened = ({inbox, userId}) => ({type: INBOX_OPENED, inbox, userId});

export const INBOX_MARKED_READ = 'shared/INBOX_MARKED_READ';
export const inboxMarkedRead = ({inbox, userId}) => ({type: INBOX_MARKED_READ, inbox, userId});

export const MESSAGE_OPENED = 'shared/MESSAGE_OPENED';
export const messageOpened = ({messagesRead, userId}) => ({type: MESSAGE_OPENED, messagesRead, userId});

export const NEW_INBOX_EVENT = 'shared/NEW_INBOX_EVENT';
export const newInboxEvent = ({userId}) => ({type: NEW_INBOX_EVENT, userId});

export const UNREAD_COUNTS_LOADED = 'shared/UNREAD_COUNTS_UPDATE_LOADED';
export const unreadCountsLoaded = ({unreadCounts, userId}) => ({type: UNREAD_COUNTS_LOADED, unreadCounts, userId});

export const HEADER_ITEM_OPENED = 'shared/HEADER_ITEM_OPENED';
export const headerItemOpened = item => ({type: HEADER_ITEM_OPENED, item});

export const HEADER_ITEM_CLOSED = 'shared/HEADER_ITEM_CLOSED';
export const headerItemClosed = item => ({type: HEADER_ITEM_CLOSED, item});

export const USER_LOGGED_OUT = 'shared/USER_LOGGED_OUT';
export const userLoggedOut = () => ({type: USER_LOGGED_OUT});

export const USER_LOGGED_OUT_FAILED = 'shared/USER_LOGGED_OUT_FAILED';
export const userLoggedOutFailed = () => ({type: USER_LOGGED_OUT_FAILED});

export const IMPERSONATED_USER = 'shared/IMPERSONATED_USER';
export const impersonatedUser = login => ({type: IMPERSONATED_USER, login});

export const IMPERSONATED_USER_FAILED = 'shared/IMPERSONATED_USER_FAILED';
export const impersonatedUserFailed = () => ({type: IMPERSONATED_USER_FAILED});

export const REMOVE_ASSET_REQUESTED = 'shared/REMOVE_ASSET_REQUESTED';
export const removeAssetRequested = url => ({type: REMOVE_ASSET_REQUESTED, url});

export const REMOVE_ASSET_SUCCEEDED = 'shared/REMOVE_ASSET_SUCCEEDED';
export const removeAssetSucceeded = () => ({type: REMOVE_ASSET_SUCCEEDED});

export const REMOVE_ASSET_FAILED = 'shared/REMOVE_ASSET_FAILED';
export const removeAssetFailed = () => ({type: REMOVE_ASSET_FAILED});

export const MIXPANEL_EVENT_TRIGGERED = 'shared/MIXPANEL_EVENT_TRIGGERED';
export const mixpanelEventTriggered = (eventName, eventOptions = {}, callback) => ({type: MIXPANEL_EVENT_TRIGGERED, eventName, eventOptions, callback});

export const AD_REQUESTED = 'shared/AD_REQUESTED';
export const adRequested = (name, instance) => ({type: AD_REQUESTED, name, instance});

export const AD_REFRESH_REQUESTED = 'shared/AD_REFRESH_REQUESTED';
export const adRefreshRequested = (name, instance) => ({type: AD_REFRESH_REQUESTED, name, instance});

export const AD_IMPRESSION_VIEWABLE = 'shared/AD_IMPRESSION_VIEWABLE';
export const adImpressionViewable = (name, instance) => ({type: AD_IMPRESSION_VIEWABLE, name, instance});

export const AD_EMPTY = 'shared/AD_EMPTY';
export const adEmpty = (name, instance) => ({type: AD_EMPTY, name, instance});

export const AD_FILLED = 'shared/AD_FILLED';
export const adFilled = (name, instance, metadata) => ({type: AD_FILLED, name, instance, metadata});

export const AD_CUSTOM_LOADED = 'shared/AD_CUSTOM_LOADED';
export const adCustomLoaded = (name, instance, custom) => ({type: AD_CUSTOM_LOADED, name, instance, custom});

export const AD_CREATIVE_LOADED = 'shared/AD_CREATIVE_LOADED';
export const adCreativeLoaded = (name, instance, creativeSize) => ({type: AD_CREATIVE_LOADED, name, instance, creativeSize});

export const AD_CREATIVE_VIEWABILITY_COMPLETE = 'shared/AD_CREATIVE_VIEWABILITY_COMPLETE';
export const adCreativeViewabilityComplete = (name, instance) => ({type: AD_CREATIVE_VIEWABILITY_COMPLETE, name, instance});

export const AD_SLOT_CONTAINER_HEIGHT_SET = 'shared/AD_SLOT_CONTAINER_HEIGHT_SET';
export const adSlotContainerHeightSet = (name, instance, height) => ({type: AD_SLOT_CONTAINER_HEIGHT_SET, name, instance, height});

export const MODAL_TOGGLED = 'shared/MODAL_TOGGLED';
export const modalToggled = visible => ({type: MODAL_TOGGLED, visible});

export const AUTH_MODAL_OPENED = 'shared/AUTH_MODAL_OPENED';
export const authModalOpened = ({reason} = {}) => ({type: AUTH_MODAL_OPENED, reason});

export const AUTH_MODAL_CLOSED = 'shared/AUTH_MODAL_CLOSED';
export const authModalClosed = () => ({type: AUTH_MODAL_CLOSED});
