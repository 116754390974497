export const ANNOTATION_EDITS_VISIBLE = 'songPage/ANNOTATION_EDITS_VISIBLE';
export const annotationEditsVisible = annotationId => ({
  type: ANNOTATION_EDITS_VISIBLE,
  annotationId,
});

export const ANNOTATION_EDITS_CLOSED = 'songPage/ANNOTATION_EDITS_CLOSED';
export const annotationEditsClosed = annotationId => ({
  type: ANNOTATION_EDITS_CLOSED,
  annotationId,
});

export const ANNOTATION_EDITS_FETCHED = 'songPage/ANNOTATION_EDITS_FETCHED';
export const annotationEditsFetched = (annotationId, {entities, result}) => ({
  type: ANNOTATION_EDITS_FETCHED,
  annotationId,
  entities,
  result,
});

export const ANNOTATION_EDITS_FETCH_FAILED = 'songPage/ANNOTATION_EDITS_FETCH_FAILED';
export const annotationEditsFetchFailed = annotationId => ({
  type: ANNOTATION_EDITS_FETCH_FAILED,
  annotationId,
});
