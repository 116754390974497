import PropTypes from 'prop-types';
import styled from 'styled-components';

import {hexWithOpacity} from '@/style';

const TextInput = styled.input.attrs(({dataTestId}) => ({
  'data-testid': dataTestId,
}))`
  appearance: none;
  border-radius: 0;
  margin: 0;
  background: inherit;
  border: 1px solid ${p => p.hasError ? p.theme.color.accent.main : p.theme.color.background.on};
  border-radius: 0;
  padding: ${p => p.theme.space[p.paddingKey]};
  color: ${p => p.theme.color.background.on};

  font-size: ${p => p.theme.fontSize.reading};
  font-family: ${p => p.theme.font.alternate};
  font-weight: 100;

  overflow-y: auto;
  resize: vertical;

  &:disabled {
    opacity: .3;
    background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2011%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22${p => encodeURIComponent(p.theme.color.background.on)}%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1.642%206.864H.369V14.5h10.182V6.864H9.278V4.318a3.818%203.818%200%200%200-7.636%200v2.546zm1.272%200h5.091V4.318a2.546%202.546%200%200%200-5.09%200v2.546z%22%2F%3E%3C%2Fsvg%3E");
    background-size: ${p => p.theme.fontSize.xSmallReading} auto;
    background-position: calc(100% - ${p => p.theme.space.half} + 1px) center;
    background-repeat: no-repeat;
    padding-right: calc(${p => p.theme.space[p.paddingKey]} * 2 + ${p => p.theme.fontSize.xSmallReading});
  }

  ${p => p.fullWidth && `
    display: block;
    width: 100%;
  `}

  &::placeholder {
    color: ${p => hexWithOpacity(p.theme.color.background.on, 0.5)};
  }
`;

TextInput.propTypes = {
  as: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
};

TextInput.defaultProps = {
  disabled: false,
  paddingKey: 'small',
  type: 'text',
};

export default TextInput;
