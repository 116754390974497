import {css} from 'styled-components';
import {isBlack} from '@/util/style';

export const breakWord = `
  word-wrap: break-word;
  word-break: break-word;
`;

export const textInputFocusState = color => `
  box-shadow: ${color} 0 0 .3em inset;
  outline: 0;
`;

export const hexWithOpacity = (hex, opacity) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_m, r, g, b) => `${r}${r}${g}${g}${b}${b}`);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const textUnderlineCrop = color => `
  text-shadow:  .03em 0 ${color},
                -.03em 0 ${color},
                0 .03em ${color},
                0 -.03em ${color},
                .06em 0 ${color},
                -.06em 0 ${color},
                .09em 0 ${color},
                -.09em 0 ${color},
                .12em 0 ${color},
                -.12em 0 ${color},
                .15em 0 ${color},
                -.15em 0 ${color};
`;

const textUnderlineLine = color => `
  background-image: linear-gradient(${color}, ${color});
  background-size: 1px 1px;
  background-repeat: repeat-x;
  background-position: 0% 95%;
`;

const textUnderlineSelection = color => `
  &::selection {
    ${textUnderlineCrop(color)}

    background: ${color};
  }

  &::-moz-selection {
    ${textUnderlineCrop(color)}

    background: ${color};
  }
`;

export const textUnderline = (color, backgroundColor, selection) => `
  ${textUnderlineCrop(backgroundColor)};
  ${textUnderlineLine(color)}
  ${textUnderlineSelection(selection)}

  color: ${color};
  text-decoration: none;

  *,
  *:after,
  &:after,
  *:before,
  &:before {
    text-shadow: none;
  }

  &:visited {
    color: ${color};
  }
`;

export const overflowWithEllipsis = `
  ${breakWord}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const overflowWithLineClamp = (numberOfLines = 2, lineHeight = 'reading') => p => `
  display: -webkit-box;
  -webkit-line-clamp: ${numberOfLines};
  -webkit-box-orient: vertical;
  max-height: ${numberOfLines * p.theme.lineHeight[lineHeight]}em;
  overflow: hidden;
`;

export const topTrianglePseudoElement = ({top = 0, right = 0, bottom = 0, left = 0}, color) => `
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: ${top};
    right: ${right};
    bottom: ${bottom};
    left: ${left};
    margin-left: calc(30px / -2);
    border-style: solid;
    border-width: 0 25px 20px 25px;
    border-color: transparent;
    border-bottom-color: ${color};
  }
`;

export const leftTrianglePseudoElement = ({top = 0, right = 0, bottom = 0, left = 0}, color) => `
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: ${top};
    right: ${right};
    bottom: ${bottom};
    left: ${left};
    margin-top: calc(30px / -2);
    border-style: solid;
    border-width: 25px 20px 25px 0;
    border-color: transparent;
    border-right-color: ${color};
  }
`;

const OPPOSITE_DIRECTION_MAP = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

/**
 *       [direction]
 *
 *           /\        ─┐
 *          /  \        │
 *         /    \       ├─ height
 *        /      \      │
 *       /        \     │
 *      /__________\   ─┘
 *
 *     └──────┬─────┘
 *          width
 */
export const triangle = ({direction, color = 'currentColor', width, height, size}) => {
  if (!Object.keys(OPPOSITE_DIRECTION_MAP).includes(direction)) {
    throw new Error(`'direction' must be one of: ${Object.keys(OPPOSITE_DIRECTION_MAP).join(',')}`);
  }

  if (!(width && height) && !size) {
    throw new Error('either \'size\' or both \'width\' and \'height\' properties required');
  }

  const perpendicularBorders = `calc(${width || size} / 2) solid transparent`;
  const isDirectionVertical = direction === 'top' || direction === 'bottom';

  return `
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-${OPPOSITE_DIRECTION_MAP[direction]}: ${height || size} solid ${color};

    ${isDirectionVertical ? `
      border-left:   ${perpendicularBorders};
      border-right:  ${perpendicularBorders};
    ` : `
      border-bottom: ${perpendicularBorders};
      border-top:    ${perpendicularBorders};
    `}
  `;
};

const BORDERED_TRIANGLE_TRANSFORM_MAP = {
  top: 'translateX(-50%) translateY(calc(-50% - 1px)) rotate(-45deg)',
  right: 'translateX(calc(50% + 1px)) translateY(-50%) rotate(45deg)',
  bottom: 'translateX(-50%) translateY(calc(50% + 1px)) rotate(135deg)',
  left: 'translateX(calc(-50% - 1px)) translateY(-50%) rotate(-135deg)',
};

export const borderedTriangle = ({direction, color, borderColor, size, borderSize = '1px', position = '0px'}) => {
  const isDirectionVertical = direction === 'top' || direction === 'bottom';

  return css`
    content: '';
    position: absolute;
    width: ${size};
    height: ${size};

    background-color: ${color};
    border-top: ${borderSize} solid ${borderColor};
    border-right: ${borderSize} solid ${borderColor};

    ${direction}: 0;
    ${isDirectionVertical ? 'left' : 'top'}: calc(50% + ${position});
    transform: ${BORDERED_TRIANGLE_TRANSFORM_MAP[direction]};
  `;
};

export const blockquote = p => `
  margin: ${p.theme.space.full} 0 0 ${p.theme.space.half};
  padding-left: ${p.theme.fontSize.reading};
  position: relative;
  display: block;

  &:before {
    content: '\\201C';
    position: absolute;
    top: -.1em;
    left: -0.3em;
    font: bold ${p.theme.fontSize.headline}/1 "Times New Roman";
    color: inherit;
    opacity: .75;
  }
`;

export const buttonHover = textColor => isBlack(textColor) ? `
  background-color: #000;
  color: #fff;
  span {
    color: #fff;
  }
  svg {
    fill: #fff;
  }
` : `
  background-color: #fff;
  color: #000;
  mix-blend-mode: screen;
  span {
    color: #000;
  }
  svg {
    fill: #000;
  }
`;


export const fullLinePadding = p =>
  `((${p.theme.lineHeight.reading} * 1em) - ${p.theme.fontSize.largeReading}) / 2`;

export const highlightSize = p =>
  `(${fullLinePadding(p)}) - (${p.theme.space.hair} / 2)`;

export const animationEaseOutQuadratic = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';

export const clamped = lines => `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
`;
