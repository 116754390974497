import React, {useCallback, useLayoutEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from './TextInput';

const TextareaWithPrefix = ({onChange, prefix, placeholder, rows, defaultValue, ...props}) => {
  const editableRef = useRef();

  const handleOnInput = useCallback((e) => {
    if (!e.currentTarget.textContent.trim()) {
      e.currentTarget.textContent = e.currentTarget.textContent.trim();
    }
    onChange(e.currentTarget.textContent);
  }, [onChange]);

  const handleOnPaste = useCallback((e) => {
    e.preventDefault();

    const paste = e.clipboardData.getData('text/plain').trim();
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    selection.collapseToEnd();

    handleOnInput(e);
  }, [handleOnInput]);

  useLayoutEffect(() => {
    try {
      editableRef.current.contentEditable = 'plaintext-only';
    } catch {
      return;
    }
  }, [editableRef]);

  return (
    <TextareaWithPrefix.Container
      as="div"
      rows={rows}
      onClick={() => editableRef.current?.focus()}
      {...props}
    >
      {prefix && <TextareaWithPrefix.Prefix dangerouslySetInnerHTML={{__html: `${prefix} `}} />}
      <TextareaWithPrefix.ContentEditable
        ref={editableRef}
        onInput={handleOnInput}
        onPaste={handleOnPaste}
        contentEditable
        data-testid="textarea-with-prefix"
      >
        {defaultValue}
      </TextareaWithPrefix.ContentEditable>
      {placeholder && <TextareaWithPrefix.Placeholder>{placeholder}</TextareaWithPrefix.Placeholder>}
    </TextareaWithPrefix.Container>
  );
};

TextareaWithPrefix.propTypes = {
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  defaultValue: PropTypes.string,
};

TextareaWithPrefix.defaultProps = {
  rows: 6,
};

export default React.memo(TextareaWithPrefix);

TextareaWithPrefix.Container = styled(TextInput)`
  line-height: ${p => p.theme.lineHeight.normal};
  min-height: calc(${p => p.theme.lineHeight.normal}rem * ${p => p.rows});
`;

TextareaWithPrefix.Prefix = styled.span`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

TextareaWithPrefix.ContentEditable = styled.span`
  white-space: pre-wrap;
  &:empty {
    display: inline-block;
  }
  &:not(:empty) {
    outline: none;
  }
`;

TextareaWithPrefix.Placeholder = styled.span`
  opacity: 0.5;
  ${TextareaWithPrefix.ContentEditable}:not(:empty) + & {
    display: none;
  }
`;
