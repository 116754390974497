import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import Cookie from 'js-cookie';
import DfpAd from '@/components/DfpAd';
import {zIndex} from '@/style';
import {IntersectionObserver, ResizeObserver} from '@/util';
import {AppConfig} from '@/config';
import {useAd, useTheme} from '@/hooks';
import {stickyLeaderboardHeightSet} from '@/actions';

const fullHeightPercentage = .25;
const collapsedHeightPercentage = .1;
const percentageToDetectAsCollapsed = (collapsedHeightPercentage / fullHeightPercentage) + 0.01;
const minViewportHeightToStickPx = 600;

const seenCookieName = key => `marquee_seen_${key}`;

const LeaderboardOrMarquee = ({name, bottomMargin = false, className}) => {
  const element = useRef(null);
  const [bodyWidth, setBodyWidth] = useState(0);
  const [marqueePermanentlyCollapsed, setMarqueePermanentlyCollapsed] = useState(false);
  const [marqueeLoaded, setMarqueeLoaded] = useState(false);
  const [marqueeReady, setMarqueeReady] = useState(false);

  const {state, creativeState, metadata: {width, height, creativeId}} = useAd(name);
  const marquee = state === 'filled' &&
    width === 4 &&
    height === 1;
  const leaderboard = state === 'filled' && !marquee;
  const stickyLeaderboard = leaderboard &&
    width ===  970 &&
    height === 250;

  const [viewportMeetsThreshold, setViewportMeetsThreshold] = useState(false);
  const leaderboardCanStick = stickyLeaderboard &&
    viewportMeetsThreshold &&
    creativeState === 'loaded';

  useEffect(() => {
    setViewportMeetsThreshold(window.innerHeight >= minViewportHeightToStickPx);
  }, []);

  useEffect(() => {
    if (marquee) {
      const resizeObserver = new ResizeObserver(([entry]) => {
        const {width: newBodyWidth} = entry.contentRect;
        if (bodyWidth !== newBodyWidth) {
          setBodyWidth(entry.contentRect.width);
        }
      },
      {
        shim: {
          observe: elem => setBodyWidth(elem.clientWidth),
        },
      });

      resizeObserver.observe(document.body);

      return () => resizeObserver.disconnect();
    }
  }, [marquee, bodyWidth]);

  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    if (marquee && marqueeLoaded) {
      dispatch(stickyLeaderboardHeightSet(`${collapsedHeightPercentage * bodyWidth}px`));
    } else if (leaderboardCanStick) {
      dispatch(stickyLeaderboardHeightSet(`${theme.space.xLarge} * 2 + ${height}px`));
    } else {
      dispatch(stickyLeaderboardHeightSet('0px'));
    }
  }, [marquee, marqueeLoaded, stickyLeaderboard, leaderboardCanStick, theme, height, dispatch, bodyWidth]);

  const postToMarquee = useCallback((message) => {
    element.current.querySelector('.celtra-ad-inline-host iframe').contentWindow.postMessage(message, '*');
  }, []);

  useEffect(() => {
    if (marquee) {
      const handler = (message) => {
        if (message.origin === `https://${AppConfig.canonicalDomain}`) {
          if (message.data === 'marqueeLoaded') {
            setMarqueeLoaded(true);
          } else if (message.data === 'marqueeReady') {
            setMarqueeReady(true);
          }
        }
      };
      window.addEventListener('message', handler);

      return () => window.removeEventListener('message', handler);
    }
  }, [marquee]);

  useEffect(() => {
    if (marqueeLoaded) {
      const seen = Boolean(Cookie.get(seenCookieName(creativeId)));
      postToMarquee({type: 'userData', seen});

      if (seen) {
        setMarqueePermanentlyCollapsed(true);
      } else {
        Cookie.set(seenCookieName(creativeId), 1, {expires: 1});

        const collapse = debounce(() => {
          setMarqueePermanentlyCollapsed(true);
          postToMarquee({type: 'permanentlyCollapse'});
        }, 1000);
        const observer = new IntersectionObserver(([entry]) => {
          if (!entry.isIntersecting) {
            collapse();
          } else {
            collapse.cancel();
          }
        }, {
          threshold: percentageToDetectAsCollapsed,
        });
        observer.observe(element.current);

        return () => {
          observer.disconnect();
          collapse.cancel();
        };
      }
    }
  }, [marqueeLoaded, postToMarquee, creativeId]);

  const isAnnotationOpen = useSelector(({songPage}) => Boolean(get(songPage, 'activeFragment')));
  useEffect(() => {
    if (marqueeLoaded && isAnnotationOpen) {
      setMarqueePermanentlyCollapsed(true);
      postToMarquee({type: 'permanentlyCollapse'});
    }
  }, [marqueeLoaded, isAnnotationOpen, postToMarquee]);

  return (
    <LeaderboardOrMarquee.Sticky
      className={className}
      bodyWidth={bodyWidth}
      {...{marquee, marqueeReady, marqueePermanentlyCollapsed, leaderboardCanStick}}
    >
      <LeaderboardOrMarquee.Container
        ref={element}
        {...{leaderboard, marquee, bottomMargin}}
      >
        <DfpAd name={name} lazy={false} refreshIntervalSeconds={null} />
      </LeaderboardOrMarquee.Container>
    </LeaderboardOrMarquee.Sticky>
  );
};

LeaderboardOrMarquee.propTypes = {
  name: PropTypes.string.isRequired,
  bottomMargin: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(LeaderboardOrMarquee);

LeaderboardOrMarquee.Sticky = styled.div`
  width: 100%;

  ${p => p.leaderboardCanStick && `
    position: sticky;
    top: 0;
    ${zIndex('stickyLeaderboardOrMarquee')};
  `}

  ${p => p.marquee ? `
    ${zIndex('stickyLeaderboardOrMarquee')};
    position: sticky;
    top: ${(collapsedHeightPercentage - fullHeightPercentage) * p.bodyWidth}px;

    ${!p.marqueeReady ? `
      height: 0;
      overflow: hidden;
    ` : ''}

    ${p.marqueePermanentlyCollapsed ? `
      top: 0;
      min-height: ${p.bodyWidth * collapsedHeightPercentage}px !important;

      .celtra-ad-inline-host {
        min-height: ${p.bodyWidth * collapsedHeightPercentage}px !important;
        overflow: hidden;
      }
    ` : ''}
  ` : ''}
`;

LeaderboardOrMarquee.Container = styled.div`
  ${p => p.leaderboard && `
    margin: ${p.theme.space.xLarge} auto ${p.bottomMargin ? '' : '0'};
    width: fit-content;
    position: relative;
  `}

  ${p => p.marquee && 'width: 100%;'}
`;
