import React, {useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {UserAvatar, TextLabel, LocalOverlay, PlaceholderSpinner, PageHeaderDropdown} from '@/components';
import {can, numberWithCommas} from '@/util';
import {useOnClickOutside, useHeaderItem, useCurrentUser} from '@/hooks';
import {userLoggedOut, impersonatedUser, removeAssetRequested} from '@/actions';
import trim from 'lodash/trim';
import isFunction from 'lodash/isFunction';

const PageHeaderMenu = ({avatarBorder, maxViewportToHideIq}) => {
  const {isOpen, open, close} = useHeaderItem('menu');
  const {currentUser, loading, items} = useRedux();

  const element = useRef(null);
  const onClickOutside = useCallback(() => {
    if (isOpen) {
      close();
    }
  }, [isOpen, close]);
  useOnClickOutside({element, onClickOutside});

  return (
    <div ref={element}>
      <PageHeaderMenu.Container onClick={isOpen ? close : open}>
        <UserAvatar id={currentUser.id} border={avatarBorder} size={24} />
        <PageHeaderMenu.Iq maxViewportToHideIq={maxViewportToHideIq}>
          <TextLabel>{numberWithCommas(currentUser.iq, {round: true})} IQ</TextLabel>
        </PageHeaderMenu.Iq>
      </PageHeaderMenu.Container>
      <PageHeaderDropdown width="narrow" isOpen={isOpen}>
        <PageHeaderMenu.Title>
          <TextLabel size="smallReading">Account</TextLabel>
        </PageHeaderMenu.Title>
        {Object.entries(items).map(
          ([text, action]) => isFunction(action) ?
            <PageHeaderMenu.Item onClick={action} key={text}>{text}</PageHeaderMenu.Item> :
            <PageHeaderMenu.Item as="a" href={action} key={text}>{text}</PageHeaderMenu.Item>
        )}
        {loading &&
          <LocalOverlay>
            <PlaceholderSpinner />
          </LocalOverlay>
        }
      </PageHeaderDropdown>
    </div>
  );
};

export default React.memo(PageHeaderMenu);

PageHeaderMenu.propTypes = {
  avatarBorder: PropTypes.bool,
  maxViewportToHideIq: PropTypes.string,
};

PageHeaderMenu.defaultProps = {
  avatarBorder: false,
  maxViewportToHideIq: '1200px',
};

const useRedux = () => {
  const currentUser = useCurrentUser();
  const loading = useSelector(({userActions}) => Boolean(
    userActions.logoutLoading ||
    userActions.impersonateLoading ||
    userActions.removeAssetLoading
  ));

  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(userLoggedOut()), [dispatch]);
  const impersonate = useCallback(login => dispatch(impersonatedUser(login)), [dispatch]);
  const removeAsset = useCallback(url => dispatch(removeAssetRequested(url)), [dispatch]);

  const items = {
    'View Profile': currentUser.url,
    'Report a Problem': '/feedback/new',
    'Sign Out': logout,
  };

  if (can('view_activity_stream_firehose', currentUser)) items['Firehose'] = '/firehose';
  if (can('manage_messages', currentUser)) items['MsgSnoop'] = '/messages/all';
  if (can('impersonate', currentUser)) {
    items['Impersonate'] = () => {
      const toImpersonate = prompt('Enter the login of the user you want to sign in as');
      if (trim(toImpersonate) === '') return;
      impersonate(toImpersonate);
    };
  }
  if (can('remove_asset', currentUser)) {
    items['Remove Asset'] = () => {
      const url = prompt('Enter the url to remove');
      if (trim(url) === '') return;
      removeAsset(url);
    };
  }
  if (can('view_rate_limits', currentUser)) items['View Rate Limits'] = '/rate_limits';
  if (can('view_penalties', currentUser)) items['View Penalties'] = '/penalties';
  if (can('search_user_aliases', currentUser)) {
    items['Search User Aliases'] = () => {
      const alias = prompt('Find all users who\'ve ever had this login');
      if (trim(alias) === '') return;
      window.location.assign(`/users/aliases?alias=${encodeURIComponent(alias)}`);
    };
  }
  if (can('manage_incoming_emails', currentUser)) items['Manage Incoming Emails'] = '/incoming_emails/manage';
  if (can('view_probably_spam_songs', currentUser)) items['View Probably Spam Songs'] = '/songs/probably_spam';
  if (can('view_chartbeat', currentUser)) {
    items['View Chartbeat'] = '/chartbeat';
    items['View Chartbeat Top Pages'] = '/chartbeat?bigboard=1';
    items['View iOS Chartbeat'] = '/chartbeat?ios=1';
  }

  return {
    currentUser,
    loading,
    items,
  };
};

PageHeaderMenu.Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${p => p.theme.header.height};
`;

PageHeaderMenu.Iq = styled.div`
  margin-left: ${p => p.theme.space.half};

  span {
    display: block;
  }

  @media (max-width: ${p => p.maxViewportToHideIq}) {
    display: none;
  }
`;

PageHeaderMenu.Title = styled.div`
  padding: ${p => p.theme.space.small} ${p => p.theme.space.full};
  line-height: 1;
`;

PageHeaderMenu.Item = styled(PageHeaderMenu.Title)`
  cursor: pointer;
  display: block;
  color: ${p => p.theme.color.background.on};
  font-weight: 100;
  font-size: ${p => p.theme.fontSize.smallReading};
  border-top: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
`;
