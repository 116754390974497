import PropTypes from 'prop-types';
import styled from 'styled-components';

const Checkbox = styled.input.attrs(({dataTestId}) => ({
  'data-testid': dataTestId,
}))`
  appearance: none;
  border: 1px solid ${p => p.hasError ? p.theme.color.accent.main : p.theme.color.background.on};
  height: 1.25rem;
  width: 1.25rem;

  &:checked, &[value="true"] {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='${p => encodeURIComponent(p.theme.color.background.on)}' d='m15.5 4.9-7.9 10-5-5L4 8.3l3.4 3.4L14 3.6 15.5 5Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  &:disabled {
    opacity: .3;
  }
`;

Checkbox.propTypes = {
  as: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  type: 'checkbox',
};

export default Checkbox;
