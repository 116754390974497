import React, {useEffect, useRef} from 'react';
import {PlaceholderSpinnerIframe} from '@/components';
import PropTypes from 'prop-types';
import {AppConfig} from '@/config';

const PageHeaderSearchResults = ({query}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current.getIframe();
    const sendQuery = () => {
      iframe.contentWindow.postMessage({type: 'standalone-search-query', query}, `https://${AppConfig.canonicalDomain}`);
    };

    if (
      iframe.contentWindow.location.pathname === '/search/embed' &&
        iframe.contentDocument.readyState === 'complete'
    ) {
      sendQuery();
    } else {
      iframe.addEventListener('load', sendQuery);
      return () => iframe.removeEventListener('load', sendQuery);
    }
  }, [query]);

  return (
    <PlaceholderSpinnerIframe src="/search/embed" ref={iframeRef} />
  );
};

PageHeaderSearchResults.propTypes = {
  query: PropTypes.string.isRequired,
};

export default React.memo(PageHeaderSearchResults);
