import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {navigatedToFragment} from '@/songPage/actions';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onPopState = ({state}) => {
      if (state) {
        const {fragment, baseAnnotation} = state;
        dispatch(navigatedToFragment({fragment, baseAnnotation}));
      }
    };

    history.scrollRestoration = 'manual';

    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
  }, [dispatch]);
};
