import {createEntityActions} from '@/songPage/actions/entities';

export const userEntity = createEntityActions('users');

export const USER_TOOLTIP_DISPLAYED = 'songPage/USER_TOOLTIP_DISPLAYED';
export const userTooltipDisplayed = userId => ({
  type: USER_TOOLTIP_DISPLAYED,
  userId,
});

export const USER_FOLLOW = 'songPage/USER_FOLLOW';
export const userFollow = (userId, onDone) => ({
  type: USER_FOLLOW,
  userId,
  onDone,
});

export const USER_UNFOLLOW = 'songPage/USER_UNFOLLOW';
export const userUnfollow = (userId, onDone) => ({
  type: USER_UNFOLLOW,
  userId,
  onDone,
});

export const USER_FOLLOW_SONG_UPDATE = 'songPage/USER_FOLLOW_SONG_UPDATE';
export const userFollowSongUpdate = (songId, following) => ({
  type: USER_FOLLOW_SONG_UPDATE,
  songId,
  following,
});
