import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {ThemeProvider} from 'styled-components';

import {authModalClosed} from '@/actions';
import {AuthenticationForm, Modal} from '@/components';
import {brandColorBackground} from '@/style/songPageTheme';

const AuthenticationModal = () => {
  const dispatch = useDispatch();
  const {showing, reason} = useSelector(state => state.authModal);
  const onClose = useCallback(() => dispatch(authModalClosed()), [dispatch]);

  return (
    <Modal
      show={showing}
      onClose={onClose}
      bodyWidth="22.25rem"
    >
      <ThemeProvider theme={brandColorBackground}>
        <AuthenticationModal.Container>
          <AuthenticationForm onClose={onClose} reason={reason} />
        </AuthenticationModal.Container>
      </ThemeProvider>
    </Modal>
  );
};

AuthenticationModal.Container = styled.div`
  color: ${p => p.theme.color.background.on};
  background-color: ${p => p.theme.color.background.main};
  padding: ${p => p.theme.space.xLarge} ${p => p.theme.space[p.paddingHorizontalKey]};
  height: 100%;
`;

export default AuthenticationModal;
