export const SONG_METADATA_UPDATE_SUBMITTED = 'songMetadata/SONG_METADATA_UPDATE_SUBMITTED';
export const songMetadataUpdateSubmitted = ({submittedFromModal, ...data}, callbacks) => ({
  type: SONG_METADATA_UPDATE_SUBMITTED,
  payload: data,
  meta: callbacks,
  submittedFromModal,
});

export const SONG_METADATA_OPTIMISTIC_SAVE = 'songMetadata/SONG_METADATA_OPTIMISTIC_SAVE';
export const songMetadataOptimisticSave = (songId, data) => ({
  type: SONG_METADATA_OPTIMISTIC_SAVE,
  songId,
  data,
});

export const SONG_METADATA_UPDATED = 'songMetadata/SONG_METADATA_UPDATED';
export const songMetadataUpdated = (songId, data) => ({
  type: SONG_METADATA_UPDATED,
  songId,
  data,
});

export const SONG_METADATA_UPDATE_FAILED = 'songMetadata/SONG_METADATA_UPDATE_FAILED';
export const songMetadataUpdateFailed = (songId, data) => ({
  type: SONG_METADATA_UPDATE_FAILED,
  songId,
  data,
});

export const SONG_METADATA_OPTIMISTIC_REVERT = 'songMetadata/SONG_METADATA_OPTIMISTIC_REVERT';
export const songMetadataOptimisticRevert = songId => ({
  type: SONG_METADATA_OPTIMISTIC_REVERT,
  songId,
});

export const SONG_EDIT_METADATA_MODAL_TOGGLED = 'songMetadata/SONG_EDIT_METADATA_MODAL_TOGGLED';
export const songEditMetadataModalToggled = visible => ({
  type: SONG_EDIT_METADATA_MODAL_TOGGLED,
  visible,
});
