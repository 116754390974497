import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import once from 'lodash/once';

import {AppConfig} from '@/config';
import {IntersectionObserver} from '@/util';
import {rewriteFilepickerImageUrls} from '@/util/filepicker';

const getObserver = once(() =>
  new IntersectionObserver(
    (entries, observer) => entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.dataset.visible = true;
        observer.unobserve(entry.target);
      }
    }),
    {
      rootMargin: '0px 0px 50% 0px',
      shim: {
        observe: elem => elem.dataset.visible = true,
      },
    }
  )
);

const SizedImage = ({width, height, aspectRatio, src, alt, fitHeightAndRepeat = false, showPlaceholder = true, lazy = true, imageElement = false, className}) => {
  const [sizedSrc, setSizedSrc] = useState(null);
  const element = useRef(null);
  const containerDimensions = calculateDimensions({width, height, aspectRatio, src});

  useEffect(() => {
    const resizeDimensions = calculateDimensions({width, height, aspectRatio, src});
    if (!resizeDimensions.width) {
      resizeDimensions.width = element.current.getBoundingClientRect().width;
      resizeDimensions.height = resizeDimensions.width / resizeDimensions.aspectRatio;
    }
    setSizedSrc(resize(rewriteFilepickerImageUrls(src), resizeDimensions, fitHeightAndRepeat));
  }, [src, fitHeightAndRepeat, width, height, aspectRatio]);

  useEffect(() => {
    const elem = element.current;
    if (lazy) {
      const observer = getObserver();
      observer.observe(elem);
      return () => observer.unobserve(elem);
    } else {
      elem.dataset.visible = true;
    }
  }, [lazy]);

  if (imageElement) {
    return (
      <SizedImage.Image
        ref={element}
        src={sizedSrc}
        dimensions={containerDimensions}
        showPlaceholder={showPlaceholder}
        alt={alt}
        className={className}
      />
    );
  } else {
    return (
      <SizedImage.Container
        ref={element}
        image={sizedSrc}
        dimensions={containerDimensions}
        fitHeightAndRepeat={fitHeightAndRepeat}
        showPlaceholder={showPlaceholder}
        role="img"
        aria-label={alt}
        className={className}
      >
        <noscript>
          <SizedImage.NoScript src={rewriteFilepickerImageUrls(src)} alt={alt} />
        </noscript>
      </SizedImage.Container>
    );
  }
};

SizedImage.propTypes = {
  lazy: PropTypes.bool,
  src: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  fitHeightAndRepeat: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  alt: PropTypes.string,
};

const TRANSFORM_DOMAINS = ['images.genius.com', 'images.rapgenius.com'];
const DIMENSION_REGEX = /\.(\d+).(\d+).\d+\.\w*$/;

const resize = (originalUrl, containerDimensions, fitHeightAndRepeat) => {
  if (!originalUrl) return '';

  const parsedUrl = new URL(originalUrl);
  if (!TRANSFORM_DOMAINS.includes(parsedUrl.host)) return originalUrl;
  const tooNarrowToFillContainer = aspectRatioFromSrc(originalUrl) < containerDimensions.aspectRatio;
  const imageWidth = fitHeightAndRepeat && tooNarrowToFillContainer ?
    aspectRatioFromSrc(originalUrl) * containerDimensions.height :
    containerDimensions.width;
  const imageHeight = containerDimensions.height;
  return `${AppConfig.siziesBaseUrl}/${Math.round(imageWidth * window.devicePixelRatio)}x${Math.round(imageHeight * window.devicePixelRatio)}/${encodeURIComponent(originalUrl)}`;
};

const aspectRatioFromSrc = (src) => {
  const [match, width, height] = src.match(DIMENSION_REGEX) || [];
  if (match) {
    return Number(width) / Number(height);
  } else {
    return 16 / 9;
  }
};

const calculateAspectRatio = ({width, height, aspectRatio, src}) => {
  if (width && height) {
    return width / height;
  } else if (aspectRatio) {
    return aspectRatio;
  } else {
    return aspectRatioFromSrc(src);
  }
};

const calculateDimensions = ({width, height, aspectRatio, src}) => {
  const dimensions = {
    aspectRatio: calculateAspectRatio({width, height, aspectRatio, src}),
    width,
    height,
  };
  if (width && !height) {
    dimensions.height = width / dimensions.aspectRatio;
  } else if (!width && height) {
    dimensions.width = height * dimensions.aspectRatio;
  }
  return dimensions;
};

export default React.memo(SizedImage);

SizedImage.Container = styled.div`
  position: relative;
  background-position: center;
  ${p => p.dimensions.width && `width: ${p.dimensions.width}px;`}
  ${p => p.dimensions.height && `height: ${p.dimensions.height}px;`}
  ${p => !p.dimensions.width && !p.dimensions.height && `padding-bottom: ${100 / p.dimensions.aspectRatio}%;`}
  ${p => p.showPlaceholder && `background-color: ${p.theme.color.background.variant};`}
  ${p => p.fitHeightAndRepeat ? `
    background-repeat: repeat-x;
    background-size: auto calc(100% + 1px);
  ` : `
    background-size: cover;
  `}

  ${p => p.image ? `
    &[data-visible="true"] {
      background-image: url("${p.image}");
    }
  ` : ''}
`;

SizedImage.Image = styled.img`
  ${p => p.showPlaceholder ?
    `background: ${p.theme.color.background.variant};` :
    `&:not([src]) {
      visibility: hidden;
    }`}
`;

SizedImage.NoScript = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
