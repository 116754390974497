import {post, get, del} from './client';
import {normalize, voteableSchema, votersSchema} from '@/normalize';

export const submitVote = async (type, id, vote)  => {
  const response = await post('/votes', {
    direction: vote || 'middle',
    [`${type}_id`]: id,
  });
  return normalize(response, voteableSchema);
};

export const loadVoters = async (type, id) => {
  const response = await get('/voters', {
    [`${type}_id`]: id,
  });

  return normalize(response, votersSchema);
};

export const clearVotes = async annotationId => await del('/votes', {annotation_id: annotationId});
