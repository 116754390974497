import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {PageHeaderLogo, TextLabel} from '@/components';
import {headerTheme, surfaceTheme} from '@/style/theme';
import {zIndex} from '@/style';
import PageHeaderSearch from './PageHeaderSearch';
import PageHeaderAuthLinks from './PageHeaderAuthLinks';
import PageHeaderLoggedIn from './PageHeaderLoggedIn';
import PageFooter from './PageFooter';
import Cloudflare from './Cloudflare';
import SocialLinks from './SocialLinks';
import HamburgerToggle from './HamburgerToggle';
import {useSubnavigationItems} from '@/hooks';

const PageHeader = ({showFooterDropdown}) => {
  const {t} = useTranslation();
  const {loggedIn, links} = useSubnavigationItems();

  const [footerOpen, setFooterOpen] = useState();
  const toggleFooter = useCallback(() => setFooterOpen(open => !open), []);

  return (
    <ThemeProvider theme={headerTheme}>
      <>
        <PageHeader.Container>
          <PageHeader.Left>
            {showFooterDropdown && <HamburgerToggle isOpen={footerOpen} toggle={toggleFooter} />}
            <PageHeaderSearch />
          </PageHeader.Left>

          <PageHeader.Logo />

          <PageHeader.Right>
            {loggedIn ?
              <PageHeaderLoggedIn /> :
              <PageHeaderAuthLinks />
            }
          </PageHeader.Right>
        </PageHeader.Container>

        <ThemeProvider theme={surfaceTheme}>
          <PageHeader.Subnavigation>
            {links.map(({label, action, useLabelTranslation = true}) =>
              <PageHeader.Link key={label} href={action} target={action.startsWith('http') ? '_blank' : null} rel="noopener">
                <TextLabel size="smallReading">
                  {useLabelTranslation ? t(`page_header.section.${label}`) : label}
                </TextLabel>
              </PageHeader.Link>
            )}
            {!loggedIn &&
              <PageHeader.SocialLinks>
                <SocialLinks size={14} ctx="header" />
              </PageHeader.SocialLinks>
            }
          </PageHeader.Subnavigation>
        </ThemeProvider>

        <Cloudflare />

        {footerOpen &&
          <ThemeProvider theme={surfaceTheme}>
            <PageHeader.DropdownFooterContainer>
              <PageFooter />
            </PageHeader.DropdownFooterContainer>
          </ThemeProvider>
        }
      </>
    </ThemeProvider>
  );
};

PageHeader.propTypes = {
  showFooterDropdown: PropTypes.bool,
};

PageHeader.defaultProps = {
  showFooterDropdown: false,
};

export default React.memo(PageHeader);

PageHeader.Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${p => p.theme.header.height};
  background: ${p => p.theme.color.background.main};
`;

PageHeader.DropdownFooterContainer = styled.div`
  position: fixed;
  top: ${p => p.theme.header.height};
  bottom: 0;
  width: 100%;
  background-color: ${p => p.theme.color.background.main};
  ${zIndex('headerDropdown')}
`;

PageHeader.Left = styled.div`
  display: flex;
  align-items: center;
`;

PageHeader.Right = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${p => p.theme.space.full};
`;

PageHeader.Link = styled.a`
  height: ${p => p.theme.fontSize.smallReading};
  padding: 0 ${p => p.theme.space.xxLarge};
  line-height: 1;
  color: ${p => p.theme.color.background.on};

  span {
    white-space: nowrap;
    position: relative;
    top: -1px;
  }
`;

PageHeader.SocialLinks = styled(PageHeader.Link).attrs({as: 'div'})`
  @media screen and (max-width: 70rem) {
    display: none;
  }
`;

PageHeader.Subnavigation = styled.div`
  height: ${p => p.theme.header.height};
  background: ${p => p.theme.color.background.main};
  display: flex;
  justify-content: center;
  align-items: center;

  ${PageHeader.Link} + ${PageHeader.Link} {
    border-left: 1px solid ${p => p.theme.color.background.on};
  }
`;

PageHeader.Logo = styled(props => <PageHeaderLogo {...props} />)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 0;

  svg {
    transform: translateY(-50%);
    height: 19px;
  }
`;
