import React, {useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {signIn} from '@/api';
import {useMixpanelEvent} from '@/hooks';
import {Button, Link, TextInput} from '@/components';
import {getFormattedErrorsByField} from '@/util/forms';
import {HiddenInput} from '@/songPage/components/controls';

const SignInForm = () => {
  const methods = useForm();
  const {register, handleSubmit, setError} = methods;
  const {t} = useTranslation();
  const trackClickSignIn = useMixpanelEvent('auth:sign_in_clicked');
  const trackSuccessfulSignIn = useMixpanelEvent('auth:sign_in_successful');

  const [loading, setLoading] = useState(false);
  const [loginRequires2fa, setLoginRequires2fa] = useState(false);
  const [secondFactorType, setSecondFactorType] = useState();

  const onSubmit = async (user) => {
    trackClickSignIn();
    setLoading(true);
    signIn(user).
      then(() => {
        trackSuccessfulSignIn();
        window.location.reload();
      }).
      catch((response_error) => {
        setLoading(false);

        setSecondFactorType(response_error.response?.allowed_2fa_types?.[0]);
        if (response_error.response?.validation_errors?.second_factor_credential) {
          setLoginRequires2fa(true);
        }

        getFormattedErrorsByField(response_error.validation_errors).forEach(([field, error]) => {
          setError(field, {type: response_error.response?.error_type, message: error});
        });
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            id="login"
            label={t('auth_form.form_label_username')}
            {...register('login', {
              required: t('auth_form.username_required'),
            })}
          />
          <TextInput
            id="password"
            label={t('auth_form.form_label_password')}
            {...register('password', {
              required: t('auth_form.password_required'),
            })}
            type="password"
          />
          {loginRequires2fa && (
            <>
              <HiddenInput
                {...register('second_factor_type', {value: secondFactorType})}
              />
              <TextInput
                id="second_factor_credential"
                label={t('auth_form.form_label_second_factor_credential')}
                {...register('second_factor_credential', {
                  required: t('auth_form.second_factor_credential_required'),
                })}
              />
            </>
          )}
          <SignInForm.SubmitButton type="submit" isLoading={loading} disabled={loading}>
            {t('auth_form.sign_in')}
          </SignInForm.SubmitButton>
        </form>
      </FormProvider>
      <SignInForm.HelperText>
        <Link href="/password_resets/new">
          {t('auth_form.forgot_password')}
        </Link>
      </SignInForm.HelperText>
    </>
  );
};

export default React.memo(SignInForm);

SignInForm.HelperText = styled.p`
  font-family: ${p => p.theme.font.alternate};
  font-weight: normal;
  margin-top: ${p => p.theme.space.half};
  text-align: center;
  font-size: ${p => p.theme.fontSize.smallReading};
`;

SignInForm.SubmitButton = styled(props => <Button {...props} />)`
  display: block;
  margin: ${p => p.theme.space.small} auto 0;
`;
