import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {useIsMobile} from '@/hooks';
import {PageHeaderInbox, PageHeaderMenu} from '@/components';
import {PageHeaderDropdownMenu, InboxUnreadCount} from '@/components/styleAnchors';
import {AppConfig} from '@/config';
import Pin from '@/svg/pin.svg';
import LightningAlt from '@/svg/lightningAlt.svg';
import Bell from '@/svg/bell.svg';
import Envelope from '@/svg/envelope.svg';
import Iq from '@/svg/iq.svg';

const StickyNavLoggedIn = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const maxViewportToHideLabel = AppConfig.artistPromoPortalLaunched ? '1593px' : '1356px';

  return (
    <StickyNavLoggedIn.Container>
      <StickyNavLoggedIn.Item>
        <PageHeaderInbox
          name="groupsInbox"
          label={t('header.groups_inbox')}
          icon={Pin}
          maxViewportToHideLabel={maxViewportToHideLabel}
        />
      </StickyNavLoggedIn.Item>
      <StickyNavLoggedIn.Item>
        <PageHeaderInbox
          name="newsfeedInbox"
          label={t('header.newsfeed_inbox')}
          icon={LightningAlt}
          abbreviateAfter={20}
          maxViewportToHideLabel={maxViewportToHideLabel}
        />
      </StickyNavLoggedIn.Item>
      <StickyNavLoggedIn.Item>
        <PageHeaderInbox
          name="mainActivityInbox"
          label={t('header.main_activity_inbox')}
          icon={Bell}
          syncCountToTitle={true}
          maxViewportToHideLabel={maxViewportToHideLabel}
        />
      </StickyNavLoggedIn.Item>
      <StickyNavLoggedIn.Item>
        <PageHeaderInbox
          name="messagesInbox"
          label={t('header.messages_inbox')}
          icon={Envelope}
          maxViewportToHideLabel={maxViewportToHideLabel}
        />
      </StickyNavLoggedIn.Item>
      <StickyNavLoggedIn.Item>
        <PageHeaderInbox
          name="iqInbox"
          label={t('header.iq_inbox')}
          icon={Iq}
          maxViewportToHideLabel={maxViewportToHideLabel}
        />
      </StickyNavLoggedIn.Item>
      <StickyNavLoggedIn.AvatarMenu isMobile={isMobile}>
        <PageHeaderMenu
          avatarBorder
          maxViewportToHideIq={maxViewportToHideLabel}
        />
      </StickyNavLoggedIn.AvatarMenu>
    </StickyNavLoggedIn.Container>
  );
};

export default React.memo(StickyNavLoggedIn);

StickyNavLoggedIn.Item = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }

  ${InboxUnreadCount} {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${p => p.theme.color.background.on};
    height: 16px;
    min-width: 16px;
    top: 6px;
    left: 16px;
  }

  ${PageHeaderDropdownMenu} {
    width: 100%;
    top: ${p => `calc(${p.theme.stickyNav.height.desktop} + ${p.theme.space.full})`};
    max-height: ${p => `calc(100vh - ${p.theme.stickyNav.height.desktop} - ${p.theme.space.xxxLarge})`};
    min-width: 550px;
    right: 0;
  }
`;

StickyNavLoggedIn.AvatarMenu = styled.div`
  ${p => !p.isMobile && 'position: relative;'}

  ${PageHeaderDropdownMenu} {
    left: 0;

    ${p => p.isMobile ? `
      position: absolute;
      width: 100%;
      top: ${p.theme.stickyNav.height.mobile};
      right: unset;
      bottom: unset;
    ` : `
      top: calc(${p.theme.stickyNav.height.desktop} + ${p.theme.space.full});
      max-height: calc(100vh - ${p.theme.stickyNav.height.desktop} - ${p.theme.space.xxxLarge});
    `}
  }
`;

StickyNavLoggedIn.Container = styled.div`
  display: flex;
  align-items: center;

  ${StickyNavLoggedIn.Item} + ${StickyNavLoggedIn.Item},
  ${StickyNavLoggedIn.Item} + ${StickyNavLoggedIn.AvatarMenu} {
    margin-left: ${p => p.theme.space.full};
  }
`;
