import memoize from 'lodash/memoize';

export const getCloudflareMetadata = memoize(() => {
  if (typeof document === 'undefined') return {};
  return Array.from(document.querySelectorAll('meta[itemprop^="cf-"]')).
    reduce((acc, metaTag) => {
      const prop = metaTag.getAttribute('itemprop').slice(3);
      const content = metaTag.content;
      if (content !== 'XX') {
        acc[prop] = content;
      }
      return acc;
    }, {});
});
