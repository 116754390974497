import {produce} from 'immer';

import {
  TOAST_ADDED,
  TOAST_REMOVED,
} from '@/actions';

export default produce((draft, action) => {
  switch (action.type) {
  case TOAST_ADDED:
    draft.current = {id: Date.now(), ...action.payload};
    break;
  case TOAST_REMOVED:
    draft.current = null;
    break;
  default:
    return draft;
  }
});
