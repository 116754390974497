import React from 'react';
import styled from 'styled-components';

import {Link} from '@/components';

const TextEditorFormatting = () => (
  <TextEditorFormatting.Container>
    <p>
      Genius uses <Link newTab href="/Genius-formatting-and-markdown-guide-annotated">Markdown</Link> for
      formatting text.
    </p>
    <p>
      <strong>Add simple formatting</strong>
      <code>*Italicize*<br />**Bold**<br />&gt; Blockquote</code>
    </p>
    <p>
      <strong>Add an image, video, or tweet by URL</strong>
      <code>https://genius.com/images/winking_sgnarly.gif</code>
    </p>
    <p>
      <strong>Add a link</strong>
      <code>[Check out my fave website](http://genius.com)</code>
    </p>
  </TextEditorFormatting.Container>
);

export default React.memo(TextEditorFormatting);

TextEditorFormatting.Container = styled.div`
  display: flex;
  gap: ${p => p.theme.fontSize.smallReading};
  flex-direction: column;

  code {
    display: block;
    word-break: break-all;
  }
`;
