import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';
import Circle from '@/svg/circle.svg';

const PlaceholderSpinner = ({className, inline, size}) => (
  <PlaceholderSpinner.Container className={className} inline={inline} size={size}>
    <Circle />
  </PlaceholderSpinner.Container>
);

const strokeRotation = keyframes`
  0% {
    stroke-dasharray: 150%;
    stroke-dashoffset: 0%;
  }

  50% { stroke-dasharray: 300%; }

  100% {
    stroke-dasharray: 150%;
    stroke-dashoffset: 600%;
  }
`;

PlaceholderSpinner.Container = styled.div`
  svg {
    display: block;
    fill: none;
    height: ${p => p.theme.fontSize.headline};
    margin: auto;
    stroke: ${p => p.theme.color.background.onVariant};
    stroke-width: .05rem;
    width: ${p => p.theme.fontSize.headline};

    circle {
      animation: ${strokeRotation} 2s ease-out infinite;
      background-color: inherit;
    }

    ${p => p.inline && `
      display: inline;
      height: ${p.theme.readingSize};
      position: relative;
      stroke-width: .15em;
      top: 1px;
      width: ${p.theme.readingSize};
    `}

    ${p => p.size && `
      height: ${p.size};
      width: ${p.size};
    `}
  }
`;

PlaceholderSpinner.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
  size: PropTypes.string,
};

export default React.memo(PlaceholderSpinner);
