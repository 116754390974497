import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {headerItemOpened, headerItemClosed} from '@/actions';

export default (name) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.openHeaderItem === name);
  const open = useCallback(() => dispatch(headerItemOpened(name)), [dispatch, name]);
  const close = useCallback(() => dispatch(headerItemClosed(name)), [dispatch, name]);

  return {isOpen, open, close};
};
