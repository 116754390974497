import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Toast from '@/components/Toast';
import {zIndex} from '@/style';
import {toastRemoved} from '@/actions';
import styled, {ThemeProvider} from 'styled-components';
import {songPageHeader} from '@/style/songPageTheme';
import {useIsMobile} from '@/hooks';

const ToastManager = ({timeout}) => {
  const dispatch = useDispatch();
  const currentToast = useSelector(state => state.toast.current, shallowEqual);
  const isMobile = useIsMobile();

  useEffect(() => {
    let interval;
    if (currentToast) {
      interval = setInterval(() => {
        currentToast && dispatch(toastRemoved());
      }, timeout);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [timeout, currentToast, dispatch]);

  return (
    <ThemeProvider theme={songPageHeader}>
      {currentToast && (
        <ToastManager.Container isMobile={isMobile}>
          <Toast
            key={currentToast.id}
            id={currentToast.id}
            status={currentToast.status}
            message={currentToast.message}
            onClose={() => dispatch(toastRemoved())}
            timeout={timeout}
          />
        </ToastManager.Container>
      )}
    </ThemeProvider>
  );
};

ToastManager.propTypes = {
  timeout: PropTypes.number,
};

ToastManager.defaultProps = {
  timeout: 5000,
};

ToastManager.Container = styled.div`
  ${p => p.isMobile ? `
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    padding: ${p.theme.space.small};
  ` : `
    position: absolute;
    top: 4rem;
    right: 5rem;
  `}
  ${zIndex('toast')};
`;

export default ToastManager;
