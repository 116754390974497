import {useMemo} from 'react';

const subscribers = {};
const useGlobalEvent = (eventName) => {
  subscribers[eventName] = subscribers[eventName] || [];
  return useMemo(() => ({
    subscribe: callback => subscribers[eventName].push(callback),
    unsubscribe: callback => subscribers[eventName] = subscribers[eventName].filter(i => i !== callback),
    callSubscribers: () => subscribers[eventName].forEach(callback => callback()),
  }), [eventName]);
};

export default useGlobalEvent;
