import {useCallback, useMemo} from 'react';

import get from 'lodash/get';
import once from 'lodash/once';

import {AppConfig} from '@/config';
import {waitUntil} from '@/util';

const defaultPickerOptions = {
  globalDropZone: true,
  onOpen: (pickerInstance) => {
    const {rootId} = pickerInstance.config;
    waitUntil(() => document.getElementById(rootId)).
      then(() => {
        document.getElementById(rootId).dataset.ignoreOnClickOutside = true;
      });
  },
  maxFiles: 1,
  transformations: {
    circle: false,
    crop: true,
    rotate: true,
  },
};

const defaultAccept = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
const defaultSources = ['local_file_system', 'imagesearch', 'url'];

const getFileStack = once(async () => {
  const filestack = await import(
    /* webpackChunkName: "filestack" */
    'filestack-js'
  );
  const options = {
    security: {
      policy: AppConfig.filepickerPolicy,
      signature: AppConfig.filepickerSignature,
    },
  };
  return filestack.init(AppConfig.filepickerApiKey, options);
});

export default (
  onSuccess,
  onError,
  options,
) => {
  const onUploadDone = useCallback(({filesUploaded, filesFailed}) => {
    if (filesUploaded.length > 0) {
      const key = filesUploaded[0].key;
      const source = filesUploaded[0].source;
      const url = AppConfig.filepickerCdnDomain ?
        `https://${AppConfig.filepickerCdnDomain}/${encodeURIComponent(key)}` :
        `https://${AppConfig.filepickerS3ImageBucket}.s3.amazonaws.com/${encodeURIComponent(key)}`;
      onSuccess(url, source);
    } else if (filesFailed.length > 0) {
      onError(filesFailed);
    }
  }, [onSuccess, onError]);

  const pickerOptions = useMemo(() => ({
    ...defaultPickerOptions,
    accept: get(options, 'accept', defaultAccept),
    fromSources: get(options, 'sources', defaultSources),
    onUploadDone,
  }), [options, onUploadDone]);

  const openFilePicker = useCallback(() => {
    getFileStack().then((client) => {
      client.picker(pickerOptions).open();
    });
  }, [pickerOptions]);

  return {
    openFilePicker,
  };
};
