export const PYONG_CLICKED = 'songPage/PYONG_CLICKED';
export const pyongClicked = ({pyongableType, pyongableId}, callbacks) => ({
  type: PYONG_CLICKED,
  payload: {
    pyongableType,
    pyongableId,
  },
  meta: callbacks,
});

export const PYONG_FINALIZED = 'songPage/PYONG_FINALIZED';
export const pyongFinalized = ({pyongableType, pyongableId, pyongNote = null}, callbacks) => ({
  type: PYONG_FINALIZED,
  payload: {
    pyongableType,
    pyongableId,
    pyongNote,
  },
  meta: callbacks,
});

export const PYONG_INCREASE_COUNTER = 'songPage/PYONG_INCREASE_COUNTER';
export const pyongIncreaseCounter = ({pyongableType, pyongableId}) => ({
  type: PYONG_INCREASE_COUNTER,
  payload: {
    pyongableType,
    pyongableId,
  },
});
