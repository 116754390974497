import {useEntity} from '@/hooks';

const useSidebarBioTracker = (songId) => {
  const song = useEntity('songs', songId);
  const {
    descriptionAnnotation: descriptionAnnotationId,
  } = song || {};

  const {state: annotationState} = useEntity('annotations', descriptionAnnotationId);
  return annotationState !== 'needs_exegesis';
};

export default useSidebarBioTracker;
