import React, {useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {dispose, AdsContext} from '@/util/ads';
import {IntersectionObserver, camelizeKeys, waitUntil, elementInViewport} from '@/util';
import fromEntries from 'object.fromentries';
import {iframeResizer} from 'iframe-resizer';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import noop from 'lodash/noop';
import {adRequested, adCustomLoaded, adCreativeLoaded, adCreativeViewabilityComplete, adSlotContainerHeightSet} from '@/actions';
import {useAd, useRefreshAd, useDistanceAbove} from '@/hooks';

const MIN_TIME_FOR_IMPRESSION_MS = 3000;

const getCustomValues = (iframe) => {
  let customValues;

  try {
    customValues = new URLSearchParams(iframe.contentWindow.customValues);
  } catch {
    customValues = new URLSearchParams();
  }

  return camelizeKeys(fromEntries(customValues.entries()));
};

const setupIframeResizer = (iframe) => {
  iframeResizer({
    checkOrigin: false,
    heightCalculationMethod: 'taggedElement',
  }, iframe);
};

const DfpAd = ({name, instance = name, placeholderSize, resizeIframe, lazy, lazyMargin, refreshIntervalSeconds, hasFixedHeight, maxCreativeHeight, keepPlaceholder}) => {
  const dispatch = useDispatch();
  const showAds = useSelector(state => state.session.showAds);
  const {state: adState, metadata, containerHeight: adContainerHeight} = useAd(name, instance);
  const containerHeight = adContainerHeight || maxCreativeHeight || placeholderSize.height;
  const distanceAboveStickyAd = useDistanceAbove('stickyAd');

  const renderedAds = useContext(AdsContext);
  if (!lazy) {
    renderedAds[name] = true;
  }

  const containerElement = useRef(null);
  const adElement = useRef(null);
  const id = `div-gpt-ad-${name}-${instance}-1`;

  useEffect(() => {
    if (showAds && adState === 'initial') {
      if (lazy) {
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            dispatch(adRequested(name, instance));
          }
        }, {
          rootMargin: `0px 0px ${lazyMargin} 0px`,
          shim: {
            observe: () => dispatch(adRequested(name, instance)),
          },
        });
        observer.observe(containerElement.current);

        return () => observer.disconnect();
      } else {
        dispatch(adRequested(name, instance));
      }
    }
  }, [adState, dispatch, instance, lazy, lazyMargin, name, showAds]);

  useEffect(() => {
    if (showAds && adState === 'filled') {
      const iframe = adElement.current && adElement.current.querySelector('iframe');
      const isSpecialSize = metadata.width === 1 || metadata.height === 1;
      const isExpanded = metadata.width === window.innerWidth;

      if (adElement.current) {
        waitUntil(() => adElement.current.getBoundingClientRect().height > 1).
          then(() => {
            const {width, height} = adElement.current.getBoundingClientRect();
            dispatch(adCreativeLoaded(name, instance, {width, height}));
            if (hasFixedHeight && (isSpecialSize || isExpanded || !elementInViewport(adElement.current))) {
              dispatch(adSlotContainerHeightSet(name, instance, height));
            }
            setTimeout(() => {
              dispatch(adCreativeViewabilityComplete(name, instance));
            }, MIN_TIME_FOR_IMPRESSION_MS);
          }).
          catch(noop);
      }

      if (iframe && resizeIframe) {
        setupIframeResizer(iframe);
      }

      if (adElement.current && isSpecialSize) {
        adElement.current.style.width = '100%';

        if (iframe) {
          iframe.style.width = '100%';
        }
      }

      dispatch(adCustomLoaded(name, instance, getCustomValues(iframe)));
    }
  }, [showAds, adState, name, instance, metadata, hasFixedHeight, maxCreativeHeight, dispatch, resizeIframe]);

  useRefreshAd({
    name,
    instance,
    refreshIntervalSeconds,
    ref: containerElement,
  });

  useEffect(() => () => dispose(name, instance), [name, instance]);

  if (!showAds) {
    return null;
  }

  switch (adState) {
  case 'initial':
  case 'requested':
    return (
      <DfpAd.Container
        ref={containerElement}
        $height={hasFixedHeight ? containerHeight : placeholderSize.height}
      >
        <div id={id} ref={adElement} dangerouslySetInnerHTML={{__html: ''}} />
        <DfpAd.Placeholder
          $width={placeholderSize.width}
          $height={placeholderSize.height}
        />
      </DfpAd.Container>
    );
  case 'filled':
    return (
      <DfpAd.Container
        ref={containerElement}
        $height={hasFixedHeight && containerHeight}
        $hasFixedHeight={hasFixedHeight}
        $distanceAbove={distanceAboveStickyAd}
      >
        <div id={id} ref={adElement} dangerouslySetInnerHTML={{__html: ''}} />
      </DfpAd.Container>
    );
  case 'empty':
    return keepPlaceholder ? <DfpAd.FixedPlaceholder
      $width={placeholderSize.width}
      $height={placeholderSize.height}
    /> : null;
  }
};

DfpAd.propTypes = {
  instance: PropTypes.string,
  lazy: PropTypes.bool,
  lazyMargin: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholderSize: PropTypes.object,
  resizeIframe: PropTypes.bool,
  refreshIntervalSeconds: PropTypes.number,
  hasFixedHeight: PropTypes.bool,
  maxCreativeHeight: PropTypes.number,
  keepPlaceholder: PropTypes.bool,
};

DfpAd.defaultProps = {
  lazy: true,
  lazyMargin: '50%',
  placeholderSize: {},
  resizeIframe: false,
  refreshIntervalSeconds: 15,
  hasFixedHeight: false,
  keepPlaceholder: false,
};

const comparePlaceholderSize = (prevProps, nextProps) => {
  const {placeholderSize: prevPlaceholderSize, ...restPrevProps} = prevProps;
  const {placeholderSize: nextPlaceholderSize, ...restNextProps} = nextProps;
  if (!shallowEqual(prevPlaceholderSize, nextPlaceholderSize)) {
    return false;
  }

  return shallowEqual(restPrevProps, restNextProps);
};

export default React.memo(DfpAd, comparePlaceholderSize);

DfpAd.Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${p => p.$height ? `height: ${p.$height}px;` : ''}
  line-height: 0;

  ${p => p.$hasFixedHeight && `
    & > div {
      position: sticky;
      top: calc(${p.$distanceAbove});
    }
  `}
`;

DfpAd.Placeholder = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${p => p.$width ? `width: ${p.$width}px;` : ''}
  ${p => p.$height ? `height: ${p.$height}px;` : ''}
  background: ${p => p.theme.color.background.variant};
`;

DfpAd.FixedPlaceholder = styled.div`
  ${p => p.$width ? `width: ${p.$width}px;` : ''}
  ${p => p.$height ? `height: ${p.$height}px;` : ''}
  background: ${p => p.theme.color.background.variant};
`;
