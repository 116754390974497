import React, {useCallback, useState, useMemo} from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {useIsMobile, useMixpanelEvent, useToast} from '@/hooks';
import {SmallButton, LinkButton} from '@/components';
import {useFilestack} from '@/songPage/hooks';
import {Textarea, TextEditorFormatting} from '@/songPage/components/controls';

const TextEditor = React.forwardRef(({
  toolbar = true,
  tips,
  value,
  onChange,
  onBlur,
  hasError,
  rows = 5,
  hasBorderedContainer = false,
  showDivider = true,
  filestackOptions,
  ...props
}, ref) => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const [activeHelperText, setActiveHelperText] = useState();

  const toggleTips = useCallback(() => setActiveHelperText(state => state === 'tips' ? null : 'tips'), []);
  const toggleFormatting = useCallback(() => setActiveHelperText(state => state === 'formatting' ? null : 'formatting'), []);

  const trackFileUpload = useMixpanelEvent('text_editor:file_upload');
  const handleFilestackSuccess = useCallback((url, source) => {
    const markdown = `![Image description](${url})`;
    onChange(value ? `${value}\n\n${markdown}` : markdown);
    trackFileUpload({source});
  }, [value, onChange, trackFileUpload]);

  const handleFilestackError = useToast('error', 'Image upload failed');

  const {openFilePicker} = useFilestack(handleFilestackSuccess, handleFilestackError, filestackOptions);

  const HelperTextComponents = useMemo(() => ({
    formatting: TextEditorFormatting,
    ...(tips && {tips: tips}),
  }), [tips]);
  const HelperTextComponent = HelperTextComponents[activeHelperText];

  return (
    <>
      {toolbar && (
        <TextEditor.Toolbar isMobile={isMobile} showDivider={showDivider}>
          <SmallButton onClick={openFilePicker}>{t('controls.text_editor.image')}</SmallButton>
          <TextEditor.ToolbarGap />
          {tips && <LinkButton onClick={toggleTips} fontSize="smallReading">{t('controls.text_editor.tips')}</LinkButton>}
          <LinkButton onClick={toggleFormatting} fontSize="smallReading">{t('controls.text_editor.formatting')}</LinkButton>
        </TextEditor.Toolbar>
      )}
      {activeHelperText && (
        <TextEditor.HelperText isMobile={isMobile} hasBorderedContainer={hasBorderedContainer}>
          <HelperTextComponent />
        </TextEditor.HelperText>
      )}
      <TextEditor.TextArea
        $isMobile={isMobile}
        $hasBorderedContainer={hasBorderedContainer}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        hasError={hasError}
        rows={rows}
        ref={ref}
        {...props}
      />
    </>
  );
});

TextEditor.displayName = 'ForwardRef(TextEditor)';

export default React.memo(TextEditor);

TextEditor.propTypes = {
  toolbar: PropTypes.bool,
  tips: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
  rows: PropTypes.number,
  hasBorderedContainer: PropTypes.bool,
  showDivider: PropTypes.bool,
  autoResize: PropTypes.bool,
  fullWidth: PropTypes.bool,
  filestackOptions: PropTypes.shape({
    accept: PropTypes.arrayOf(PropTypes.string),
    sources: PropTypes.arrayOf(PropTypes.string),
  }),
};

TextEditor.defaultProps = {
  fullWidth: true,
  autoResize: false,
};

TextEditor.Toolbar = styled.div`
  display: flex;
  ${p => p.isMobile ? `
    background-color: ${p.theme.color.background.main};
    gap: ${p.theme.space.full};
    padding: ${p.theme.space.small} ${p.theme.space.full};
  ` : `
    gap: ${p.theme.space.half};
    padding: ${p.theme.space.half};
  `}

  ${p => p.showDivider ? `
    border-bottom: 1px solid ${p.theme.color.background.blockOnColor};
  ` : `
    padding-left: 0;
    padding-right: 0;
  `}
`;

TextEditor.ToolbarGap = styled.span`
  display: block;
  flex-grow: 1;
`;

TextEditor.TextArea = styled(props => <Textarea {...props}/>)`
  ${p => p.$isMobile ? css`
    background-color: ${p.theme.color.background.main};
    border-bottom: 0;
    border-color: ${p.theme.color.background.blockOnColor};
    padding: ${p.theme.space.full};

    ${TextEditor.Toolbar} + & {
      ${p.$hasBorderedContainer ? 'border: 0' : 'border-top: 0'};
    }
  ` : css`
    &:focus, &:active {
      border-color: ${p.theme.color.background.main};
    }

    ${TextEditor.Toolbar} + & {
      ${p.$hasBorderedContainer ? 'border: 0' : `margin-top: ${p.theme.space.full}`};
    }

    ${TextEditor.HelperText} + & {
      ${p.$hasBorderedContainer && 'border-bottom: 0'};
    }
  `}

  ${p => p.$hasBorderedContainer && `
    border-left: 0;
    border-right: 0;
  `}
`;

TextEditor.HelperText = styled.div`
  font-size: ${p => p.theme.fontSize.smallReading};
  padding: ${p => p.isMobile ? p.theme.space.small : `${p.theme.space.small} 0`};
  ${p => p.isMobile && `background-color: ${p.theme.color.background.main};`};
  ${p => p.hasBorderedContainer && `padding: ${p.theme.space.small}`};
`;
