import {useState, useCallback} from 'react';

// Returns a state variable and one toggle function for each item in input list, states.
// Designed for switching between only one of multiple possible state
// If a toggle function is called and its associated state is already the state, state will be set to defaultState.
export default ({states, defaultState = null}) => {
  const [toggleState, setToggleState] = useState(defaultState);

  const changeToggleState = useCallback(
    state => setToggleState(prevToggleState => prevToggleState !== state ? state : defaultState),
    [defaultState]
  );

  return {
    toggleState,
    toggles: states.map(
      state => () => changeToggleState(state)
    ),
  };
};
