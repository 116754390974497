export const TOAST_ADDED = 'shared/TOAST_ADDED';
export const toastAdded = ({status, message}) => ({
  type: TOAST_ADDED,
  payload: {
    status,
    message,
  },
});

export const TOAST_REMOVED = 'shared/TOAST_REMOVED';
export const toastRemoved = () => ({type: TOAST_REMOVED});
