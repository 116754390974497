import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {SignUpButton} from '@/components';

const StickyNavSignUp = ({className}) => <StickyNavSignUp.Button textButton source="nav" className={className} />;

StickyNavSignUp.propTypes = {
  className: PropTypes.string,
};

export default StickyNavSignUp;

StickyNavSignUp.Button = styled(props => <SignUpButton {...props} />)`
  font-size: ${p => p.theme.fontSize.smallReading};
  font-weight: ${p => p.theme.fontWeight.light};
  line-height: 1;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: ${p => get(p.theme.color, p.color, p.theme.color.background.on)};
`;
