import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {TextLabel} from '@/components';
import DownArrow from '@/svg/downArrow.svg';
import {useSpring, animated} from 'react-spring';

const SquareSelectTitle = ({text = '\u00A0', selecting = false}) => {
  const styleProps = useSpring({transform: selecting ? 'rotateX(180deg)' : 'rotateX(0deg)'});
  return (
    <SquareSelectTitle.Container selecting={selecting}>
      <TextLabel size="smallReading">{text}</TextLabel>
      <SquareSelectTitle.Arrow style={styleProps}>
        <DownArrow />
      </SquareSelectTitle.Arrow>
    </SquareSelectTitle.Container>
  );
};

SquareSelectTitle.propTypes = {
  text: PropTypes.string,
  selecting: PropTypes.bool,
};

export default React.memo(SquareSelectTitle);

SquareSelectTitle.Container = styled.div`
  position: relative;
  padding: ${p => p.theme.space.small} ${p => `calc(${p.theme.space.xLarge} + ${p.theme.space.full})`} ${p => p.theme.space.small} ${p => p.theme.space.full};
  white-space: nowrap;
`;

SquareSelectTitle.Arrow = styled(animated.div)`
  position: absolute;
  top: ${p => p.theme.space.small};
  right: ${p => p.theme.space.small};

  svg {
    position: relative;
    top: 1px;
    fill: ${p => p.theme.color.background.on};
    width: ${p => p.theme.fontSize.smallReading};
    height: ${p => p.theme.fontSize.smallReading};
  }
`;
