import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import once from 'lodash/once';

import {useMixpanelEvent} from '@/hooks';
import {logCloudflareBanner} from '@/librato';

const track = once((mixpanel) => {
  mixpanel();
  logCloudflareBanner();
});

const Cloudflare = () => {
  const [show, setShow] = useState(false);
  const {t} = useTranslation();

  const trackMixpanel = useMixpanelEvent('cloudflare_error.always_online_banner', {source: 'react'});

  useEffect(() => {
    if (document.querySelector('#cf_alert_div')) {
      setShow(true);
      track(trackMixpanel);
    }
  }, [trackMixpanel]);

  return show ? (
    <Cloudflare.Container>
      <Cloudflare.Message>
        {t('cloudflare_always_on_message')}
      </Cloudflare.Message>
    </Cloudflare.Container>
  ) : null;
};

export default React.memo(Cloudflare);

Cloudflare.Container = styled.div`
  background: ${p => p.theme.color.error.main};
  padding: ${p => p.theme.space.full};
  color: ${p => p.theme.color.error.on};
  font-weight: 100;
`;

Cloudflare.Message = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
