import {useMemo} from 'react';

import {AppConfig} from '@/config';

const useStubhubTrackedUrl = (stubhubDeal, trackingSubset, categoryId) => useMemo(() => {
  if (!stubhubDeal) return '';
  if (!categoryId) return '';

  const destination = `https://www.${stubhubDeal}.com/_C-${categoryId}`;

  const path = [
    ['pubref', trackingSubset],
    ['destination', destination],
  ].map(([key, value]) => [key, encodeURIComponent(value)].join(':')).join('/');

  const partnerizeUrl = {
    stubhub: AppConfig.stubhubPartnerizeUrl,
    viagogo: AppConfig.viagogoPartnerizeUrl,
  }[stubhubDeal];

  return [partnerizeUrl, path].join('/');
}, [categoryId, stubhubDeal, trackingSubset]);

export default useStubhubTrackedUrl;
