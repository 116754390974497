import React from 'react';
import {useEntity} from '@/hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SizedImage from '@/components/SizedImage';

const ArtistBadge = ({artistId, avatarSize, nameFontSize, gap, onClick}) => {
  const {
    url,
    name,
    imageUrl,
  } = useEntity('artists', artistId);

  return (
    <ArtistBadge.Container gap={gap}>
      <ArtistBadge.Picture>
        {imageUrl && <SizedImage src={imageUrl} width={avatarSize} height={avatarSize} />}
      </ArtistBadge.Picture>
      <ArtistBadge.ArtistName href={url} size={nameFontSize} onClick={onClick}>
        {name}
      </ArtistBadge.ArtistName>
    </ArtistBadge.Container>
  );
};

export default React.memo(ArtistBadge);

ArtistBadge.propTypes = {
  artistId: PropTypes.number.isRequired,
  avatarSize: PropTypes.number,
  nameFontSize: PropTypes.string,
  gap: PropTypes.string,
  onClick: PropTypes.func,
};

ArtistBadge.defaultProps = {
  avatarSize: 40,
  nameFontSize: 'reading',
  gap: 'full',
};

ArtistBadge.Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${p => p.theme.space[p.gap]};
  font-weight: ${p => p.theme.fontWeight.light}
`;

ArtistBadge.Picture = styled.div`
  & > div {
    border-radius: 100%;
  }
`;

ArtistBadge.ArtistName = styled.a`
  font-size: ${p => p.theme.fontSize[p.size]};
  text-decoration: underline;
  color: ${p => p.theme.color.primary.on};
`;
