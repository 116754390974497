import styled, {keyframes} from 'styled-components';
import {hexWithOpacity} from '@/style';

const fadeIn = keyframes`
  0% { opacity: 0; }

  100% { opacity: 1; }
`;

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} .2s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${p => hexWithOpacity(p.theme.color.background.main, 0.8)};
`;
