import PropTypes from 'prop-types';
import styled from 'styled-components';
import {textInputFocusState} from '@/style';

const SquareInput = styled.input`
  background: ${p => p.theme.color.background.main};
  border: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
  border-radius: 0;
  color: ${p => p.theme.color.background.on};
  font-family: ${p => p.theme.font.accent};
  font-size: inherit;
  font-weight: 100;
  line-height: inherit;
  padding: ${p => p.theme.space.quarter};
  -webkit-appearance: none;
  overflow: hidden;
  resize: none;
  ${p => p.as === 'textarea' && 'height: 3em;'}
  ${p => p.fullWidth && `
    display: block;
    width: 100%;
  `}

  &:focus, &:active { ${p => textInputFocusState(p.theme.color.secondary.main)} }
`;

SquareInput.propTypes = {
  value: PropTypes.string,
  as: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default SquareInput;
