export const QUESTION_ANSWER_SUBMITTED = 'songPage/QUESTION_ANSWER_SUBMITTED';
export const questionAnswerSubmitted = (payload, callbacks) => ({
  type: QUESTION_ANSWER_SUBMITTED,
  payload,
  meta: callbacks,
});

export const SELECT_QA_FORM = 'songPage/SELECT_QA_FORM';
export const selectQaForm = selectedQaForm => ({
  type: SELECT_QA_FORM,
  selectedQaForm,
});
