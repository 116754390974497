import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import {
  FieldContainer,
  FieldLabel,
  FieldError,
  FieldHelpText,
} from './Field.shared';

const SelectField = ({
  children,
  className,
  control,
  defaultValue,
  disabled,
  helpText,
  hideLabel,
  isRequired,
  label,
  name,
  rules,
  showErrors,
  transformForForm,
  transformForInput,
}) => {
  const inputDefaultValue = useMemo(() => transformForInput(defaultValue), [defaultValue, transformForInput]);
  const fieldOnChange = useCallback(field => async (value) => {
    if (transformForForm) {
      const newValue = transformForForm(value);
      field.onChange(newValue);
    } else {
      field.onChange(value);
    }
  }, [transformForForm]);

  const renderChildren = useCallback(({field, fieldState}) => {
    const fieldError = fieldState.error;
    return (
      <FieldContainer className={className}>
        {!hideLabel && <FieldLabel>{label}&nbsp;{isRequired && '*'}</FieldLabel>}
        {children({
          dataTestId: `${name}-input`,
          defaultValue: inputDefaultValue,
          disabled,
          hasError: fieldState.invalid,
          onChange: fieldOnChange(field),
        })}
        {fieldError && showErrors && (
          <FieldError>{fieldError.message}</FieldError>
        )}
        {helpText && (
          <FieldHelpText>{helpText}</FieldHelpText>
        )}
      </FieldContainer>
    );
  }, [className, children, disabled, fieldOnChange, helpText, hideLabel, inputDefaultValue, isRequired, label, name, showErrors]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={renderChildren}
      rules={rules}
    />
  );
};

export default React.memo(SelectField);

SelectField.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  showErrors: PropTypes.bool,
  transformForForm: PropTypes.func,
  transformForInput: PropTypes.func,
  triggerOnChange: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

SelectField.defaultProps = {
  defaultValue: '',
  disabled: false,
  hideLabel: false,
  isRequired: false,
  rules: {},
  showErrors: true,
  transformForInput: value => value,
};
