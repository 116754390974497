import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import X from '@/svg/x.svg';

export const reactSelectStyles = theme => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'inherit',
    borderColor: theme.color.background.on,
    ':hover': {
      borderColor: theme.color.background.on,
    },
    opacity: state.isDisabled ? .3 : 1,
  }),
  input: provided => ({
    ...provided,
    padding: `0 ${theme.space.quarter}`,
    backgroundColor: 'transparent',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    fontFamily: theme.font.alternate,
    color: theme.color.background.on,
    backgroundColor: theme.color.background[state.selectProps.valueBackgroundKey || 'main'],
    border: `1px solid ${theme.color.background.on}`,
  }),
  multiValueRemove: provided => ({
    ...provided,
    marginRight: theme.space.hair,
    width: theme.fontSize.reading,
    flexShrink: 0,
    cursor: 'pointer',
    ':hover': {
      background: 'transparent',
    },
    '& svg': {
      width: '100%',
    },
  }),
  menu: provided => ({
    ...provided,
    margin: `${theme.space.hair} 0`,
  }),
  menuList: provided => ({
    ...provided,
    fontSize: theme.fontSize.reading,
    padding: 0,
  }),
  noOptionsMessage: provided => ({
    ...provided,
    color: theme.color.background.onVariant,
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? 'bold' : 'normal',
    background: state.isFocused ? theme.color.background.variant : theme.color.background.main,
    color: theme.color.background.on,
    cursor: 'pointer',
  }),
  placeholder: provided => ({
    ...provided,
    margin: `0 ${theme.space.half}`,
    color: theme.color.background.onVariant,
  }),
  singleValue: provided => ({
    ...provided,
    padding: theme.space.quarter,
  }),
  clearIndicator: provided => ({
    ...provided,
    cursor: 'pointer',
    padding: 0,
    margin: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: theme.space.quarter,
    lineHeight: 1.5,
  }),
});

export const reactSelectInputStyles = theme => ({
  ...reactSelectStyles(theme),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.color.background.on,
    padding: `0 ${theme.space.quarter}`,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
    margin: `0 ${theme.space.small}`,
  }),
});

export const reactSelectTheme = theme => inputTheme => ({
  ...inputTheme,
  borderRadius: 0,
  colors: {
    ...inputTheme.colors,
    primary25: theme.color.background.variant,
    primary50: theme.color.background.variant,
  },
});


export const ClearIndicator = (props) => {
  const {
    children = <X />,
    getStyles,
    innerProps: {ref, ...restInnerProps},
    selectProps,
  } = props;
  const fieldValue = selectProps.value.value;
  if (typeof fieldValue === 'undefined' || fieldValue === null) {
    return null;
  }

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <ClearIndicator.ChildrenContainer>{children}</ClearIndicator.ChildrenContainer>
    </div>
  );
};

ClearIndicator.propTypes = {
  children: PropTypes.func.isRequired,
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

ClearIndicator.ChildrenContainer = styled.div`
  padding: 0px 5px;
`;
