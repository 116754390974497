import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import GlobalStyle from './GlobalStyle';
import CmpGlobalStyle from './CmpGlobalStyle';
import CloudflareGlobalStyle from './CloudflareGlobalStyle';
import {lightTheme} from './theme';

const StyleWrapper = ({children}) => {
  const cmpEnabled = useSelector(state => state.session.cmpEnabled);
  return (
    <ThemeProvider theme={lightTheme}>
      <>
        <GlobalStyle />
        <CloudflareGlobalStyle />
        {cmpEnabled && <CmpGlobalStyle />}
        {children}
      </>
    </ThemeProvider>
  );
};

StyleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(StyleWrapper);
