export default (targetElement, durationMs) => {
  const offset = parseFloat(window.getComputedStyle(targetElement).scrollMargin);
  const startY = window.scrollY || window.pageYOffset;
  const diff = targetElement.getBoundingClientRect().top  - offset;
  let startTime;
  const step = (timestamp) => {
    if (!startTime) startTime = timestamp;
    const percent = Math.min((timestamp - startTime) / durationMs, 1);
    window.scrollTo({top: startY + (diff * percent), behavior: 'instant'});
    if (percent < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};
