import React, {useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import {signUp} from '@/api';
import {useMixpanelEvent} from '@/hooks';
import bugsnag from '@/bugsnag';
import {Button, TextInput} from '@/components';
import {getFormattedErrorsByField} from '@/util/forms';
import {AppConfig} from '@/config';

const SignUpForm = () => {
  const methods = useForm();
  const {register, handleSubmit, setError} = methods;
  const {t} = useTranslation();
  const trackClickSignUp = useMixpanelEvent('auth:sign_up_clicked');
  const trackSuccessfulSignUp = useMixpanelEvent('auth:sign_up_successful');

  const [loading, setLoading] = useState(false);

  const onSubmit = async (user) => {
    trackClickSignUp();
    setLoading(true);

    let fingerprintVisitorId;

    try {
      const fingerprint = await FingerprintJS.load({
        apiKey: AppConfig.fingerprintApiKey,
        endpoint: 'https://fp.genius.com',
      });
      fingerprintVisitorId = (await fingerprint.get()).visitorId;
    } catch (error) {
      bugsnag.notify(error);
      fingerprintVisitorId = 'errored-react';
    }

    return signUp({
      ...user,
      device_fingerprint: fingerprintVisitorId,
    }).
      then(() => {
        trackSuccessfulSignUp();
        window.location.reload();
      }).
      catch((response) => {
        setLoading(false);
        getFormattedErrorsByField(response.validation_errors).forEach(([field, error]) => {
          setError(field, {type: 'manual', message: error});
        });
      });
  };

  return (
    <>
      <SignUpForm.EmailDivider>
        {t('auth_form.or_sign_up_with_email')}
      </SignUpForm.EmailDivider>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            id="login"
            label={t('auth_form.form_label_username')}
            {...register('login', {
              required: t('auth_form.username_required'),
            })}
          />
          <TextInput
            id="email"
            label={t('auth_form.form_label_email_address')}
            {...register('email', {
              required: t('auth_form.email_required'),
              pattern: {
                value: /\S+@\S+.\S+/,
                message: t('auth_form.email_invalid'),
              },
            })}
          />
          <TextInput
            id="password"
            label={t('auth_form.form_label_password')}
            {...register('password', {
              required: t('auth_form.password_required'),
            })}
            type="password"
          />
          <SignUpForm.HelperText>
            {t('auth_form.terms_of_service_agreement')} <SignUpForm.ExternalLink href="/static/terms" target="_blank">{t('auth_form.terms_of_service')}</SignUpForm.ExternalLink>.
          </SignUpForm.HelperText>
          <SignUpForm.SubmitButton type="submit" isLoading={loading} disabled={loading}>
            {t('auth_form.sign_up')}
          </SignUpForm.SubmitButton>
        </form>
      </FormProvider>
    </>
  );
};

export default React.memo(SignUpForm);

SignUpForm.EmailDivider = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: ${p => p.theme.space.quarter};
  font-family: ${p => p.theme.font.alternate};
  font-weight: normal;
  font-size: ${p => p.theme.fontSize.smallReading};
  line-height: 1;
  white-space: nowrap;

  &:before,
  &:after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: ${p => p.theme.color.background.on};
  }

  &:before {
    margin-right: ${p => p.theme.space.small};
  }

  &:after {
    margin-left: ${p => p.theme.space.small};
  }
`;

SignUpForm.ExternalLink = styled.a`
  color: unset;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
`;

SignUpForm.HelperText = styled.p`
  font-family: ${p => p.theme.font.alternate};
  font-weight: normal;
  margin-top: ${p => p.theme.space.half};
  text-align: center;
  font-size: ${p => p.theme.fontSize.smallReading};
`;


SignUpForm.SubmitButton = styled(props => <Button {...props} />)`
  display: block;
  margin: ${p => p.theme.space.small} auto 0;
`;
