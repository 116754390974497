import styled from 'styled-components';
import {NewField as Field} from '@/songPage/components/controls';
import {FieldLabel, FieldControlWithLabel} from './Field.shared';

const FloatingField = styled(Field).attrs({floatingLabel: true})`
  ${FieldControlWithLabel} {
    display: flex;
    flex-direction: column-reverse;
  }

  ${FieldLabel} {
    transition: all .2s;
    pointer-events: none;
  }

  input:placeholder-shown + ${FieldLabel} {
    cursor: text;
    transform-origin: left bottom;
    transform: translate(${p => p.theme.space.small}, 2.5rem);
  }

  input:focus + ${FieldLabel}, input:not(:placeholder-shown) + ${FieldLabel} {
    transform: translate(0, 0);
  }
`;

export default FloatingField;
