import merge from 'lodash/merge';
import {useEffect, useRef, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {stubhubCategoryRequested} from '@/songPage/actions';
import {useEntity} from '@/hooks';
import {getCloudflareMetadata} from '@/util/cloudflare';

import useStubhubTrackedUrl from './useStubhubTrackedUrl';

const PRIMARY_TAG_TO_CATEGORY = {
  413: {
    id: 260347,
    name: 'country',
  },
  16: {
    id: 260598,
    name: 'pop',
  },
  1434: {
    id: 260478,
    name: 'rap',
  },
  567: {
    id: 259827,
    name: 'rock',
  },
  352: {
    id: 1027,
    name: 'R&B',
  },
};

const useStubhubCategoryProps = (songId) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const song = useEntity('songs', songId);

  const [stubhubCategory, setStubhubCategory] = useState(null);
  const alreadyCalled = useRef(false);

  const category = PRIMARY_TAG_TO_CATEGORY[song.primaryTag.id];

  const eligible = useMemo(() => Boolean(
    !song.stubhubArtist &&
    song.stubhubDeal &&
    category
  ), [song, category]);

  useEffect(() => {
    if (!eligible) return;
    if (alreadyCalled.current) return;

    alreadyCalled.current = true;

    const cfMetadata = getCloudflareMetadata();

    dispatch(stubhubCategoryRequested(
      song.stubhubDeal,
      category.id,
      {
        latitude: cfMetadata.latitude,
        longitude: cfMetadata.longitude,
      },
      {
        onSuccess: response => setStubhubCategory(merge({}, category, response)),
        onError: () => setStubhubCategory(merge({}, category)),
      },
    ));
  }, [dispatch, eligible, song, category]);

  const link = useStubhubTrackedUrl(song?.stubhubDeal, 'category', stubhubCategory?.id);

  if (!stubhubCategory && eligible) return {loading: true};
  if (!stubhubCategory) return null;

  if (stubhubCategory.city && stubhubCategory.minConcertTicketPriceForDisplay) {
    return {
      link,
      headline: t('stubhub.category.headline', {category: stubhubCategory.name, city: stubhubCategory.city}),
      body: t('stubhub.category.body', {ticketPrice: stubhubCategory.minConcertTicketPriceForDisplay}),
      trackParams: {
        stubhub_link_type: 'category',
        stubhub_category: stubhubCategory.name,
        stubhub_city: stubhubCategory.city,
        stubhub_min_ticket_price: stubhubCategory.minConcertTicketPriceForDisplay,
      },
    };
  }
  return {
    link,
    headline: t('stubhub.fallback.headline', {category: stubhubCategory.name}),
    body: t('stubhub.fallback.body'),
    trackParams: {
      stubhub_link_type: 'fallback',
      stubhub_category: stubhubCategory.name,
    },
  };
};

export default useStubhubCategoryProps;
