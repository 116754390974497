import {get} from './client';
import {userAttributionSchema, normalize} from '@/normalize';

export const getCommunityLeaderboard = async ({period = 'day', page = 1, perPage = 10}) => {
  const response = await get('/leaderboard', {
    period,
    page,
    per_page: perPage,
  });
  return normalize(response, {
    leaderboard: [userAttributionSchema],
  });
};
