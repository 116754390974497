import {useSelector} from 'react-redux';
import filter from 'lodash/filter';

import {useCurrentUser} from '@/hooks';
import {can} from '@/util';

const useSidebarAnnotationsTracker = () => {
  const currentUser = useCurrentUser();
  const canSeeUnreviewedAnnotations = can('see_unreviewed_referents', currentUser);

  const pendingAnnotationsCount = useSelector(state =>
    filter(state.entities.referents, referent => referent.classification === 'unreviewed' && !referent.isDescription).length);

  return canSeeUnreviewedAnnotations ? pendingAnnotationsCount : 0;
};

export default useSidebarAnnotationsTracker;
