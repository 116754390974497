import {useEffect, useCallback} from 'react';
import usePusher from './usePusher';
import {AppConfig} from '@/config';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';

export default ({name, channelName, onNewEvent, onMessageOpen, onMarkedRead, syncCountToTitle, unreadCount}) => {
  const onMarkedReadForName = useCallback((payload) => {
    if (camelCase(payload.inbox) === name) {
      onMarkedRead(payload);
    }
  }, [name, onMarkedRead]);
  usePusher({channelName, eventName: 'inbox-marked-read', callback: onMarkedReadForName});

  usePusher({channelName, eventName: `new-${kebabCase(name)}-event`, callback: onNewEvent});

  useEffect(() => {
    if (name === 'messagesInbox') {
      const handler = (message) => {
        if (
          message.origin === `https://${AppConfig.canonicalDomain}` &&
            get(message.data, 'type') === 'decrement_unread_messages_count'
        ) {
          onMessageOpen(message.data.decrement);
        }
      };
      window.addEventListener('message', handler);
      return () => window.removeEventListener('message', handler);
    }
  }, [name, onMessageOpen]);

  useEffect(() => {
    if (syncCountToTitle) {
      const replacement = unreadCount > 0 ? `(${unreadCount}) ` : '';
      document.title = document.title.replace(/^(\(\d+\) )?/, replacement);
    }
  }, [syncCountToTitle, unreadCount]);
};
