import PropTypes from 'prop-types';
import styled from 'styled-components';
import {breakWord} from '@/style';
import get from 'lodash/get';

const SquareButton = styled.div`
  ${p => buttonColor(p.theme, p.color)}
  ${breakWord}
  transition: background-color .1s, color .1s;
  background-color: transparent;
  border-width: ${p => p.theme.border.standardWidthPixels};
  border-style: solid;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-family: ${p => p.theme.font.accent};
  font-size: ${p => p.theme.fontSize.smallReading};
  line-height: 1;
  margin: 0;
  text-align: center;
  padding: ${p => p.theme.space.small} ${p => p.theme.space.large};
  vertical-align: top;
  user-select: none;
  -webkit-appearance: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:focus, &:active {
    outline: none;
  }
`;

SquareButton.propTypes = {
  value: PropTypes.string,
  as: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
};

SquareButton.defaultProps = {
  color: 'background.on',
};

const buttonColor = (theme, key) => {
  const color = get(theme.color, key);
  return `
    border-color: ${color};
    color: ${color};

    &:hover {
      background-color: ${color};
      color: ${theme.color.background.main};
    }
  `;
};

export default SquareButton;
