import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {useIsMobile} from '@/hooks';
import {Button} from '@/components';
import X from '@/svg/x.svg';
import Plus from '@/svg/plus.svg';

const RepeatableInputPair = ({
  addButtonText,
  allowAddRow,
  disabled,
  onAddClick,
  onRemoveClick,
  renderAfter,
  renderCategory,
  renderValue,
  rows,
}) => {
  const isMobile = useIsMobile();
  return (
    <RepeatableInputPair.Container>
      <RepeatableInputPair.Rows>
        {rows.map((row, index) => (
          <RepeatableInputPair.Row key={row.id}>
            <RepeatableInputPair.RowContents isMobile={isMobile}>
              {!disabled && (
                <RepeatableInputPair.Remove onClick={() => onRemoveClick(index)}>
                  <X aria-label="Remove" />
                </RepeatableInputPair.Remove>
              )}
              <RepeatableInputPair.RowItem isMobile={isMobile}>
                {renderCategory(row, index)}
              </RepeatableInputPair.RowItem>
              <RepeatableInputPair.RowItem isMobile={isMobile}>
                {renderValue(row, index)}
              </RepeatableInputPair.RowItem>
            </RepeatableInputPair.RowContents>
            {Boolean(renderAfter) && renderAfter(row, index)}
          </RepeatableInputPair.Row>
        ))}
      </RepeatableInputPair.Rows>
      {allowAddRow && !disabled && (
        <RepeatableInputPair.Button onClick={onAddClick}>
          <Plus /> {addButtonText}
        </RepeatableInputPair.Button>
      )}
    </RepeatableInputPair.Container>
  );
};

RepeatableInputPair.propTypes = {
  addButtonText: PropTypes.string.isRequired,
  allowAddRow: PropTypes.bool,
  disabled: PropTypes.bool,
  onAddClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  renderAfter: PropTypes.func,
  renderCategory: PropTypes.func.isRequired,
  renderValue: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
};

RepeatableInputPair.defaultProps = {
  allowAddRow: true,
  disabled: false,
};

export default React.memo(RepeatableInputPair);

RepeatableInputPair.Container = styled.div`
  font-family: ${p => p.theme.font.alternate};
`;

RepeatableInputPair.Rows = styled.div``;

RepeatableInputPair.Row = styled.div`
  &:not(:last-child) {
    margin-bottom: ${p => p.theme.space.small};
  }
`;

RepeatableInputPair.RowContents = styled.div`
  display: flex;
  background: ${p => p.theme.color.background.variant};
  position: relative;

  ${p => p.isMobile ? `
    flex-direction: column;
    padding: ${p.theme.space.small};
  ` : `
    padding: ${p.theme.space.large};
  `}
`;

RepeatableInputPair.Remove = styled.button.attrs({type: 'button'})`
  padding: ${p => p.theme.space.small};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  svg {
    display: block;
    width: ${p => p.theme.space.small};
    height: ${p => p.theme.space.small};
  }
`;

RepeatableInputPair.RowItem = styled.div`
  flex: 1;

  ${p => p.isMobile ? `
    &:not(:last-child) {
      margin-bottom: ${p.theme.space.full};
    }
  ` : `
    &:not(:last-child) {
      margin-right: ${p.theme.space.full};
    }
  `}
`;

RepeatableInputPair.Button = styled(props => <Button {...props} />)`
  padding: ${p => p.theme.space.small} ${p => p.theme.space.full};
  line-height: 1;
  display: flex;
  margin-top: ${p => p.theme.space.full};

  svg {
    margin-right: ${p => p.theme.space.xSmall};
    width: ${p => p.theme.space.half};
    height: ${p => p.theme.space.half};
  }
`;
