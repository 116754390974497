export const CHART_FILTER_UPDATED = 'homePage/CHART_FILTER_UPDATED';
export const chartFilterUpdated = updatedFilters => ({type: CHART_FILTER_UPDATED, updatedFilters});

export const CHART_FILTER_UPDATE_FAILED = 'homePage/CHART_FILTER_UPDATE_FAILED';
export const chartFilterUpdateFailed = () => ({type: CHART_FILTER_UPDATE_FAILED});

export const CHART_FILTER_UPDATE_LOADED = 'homePage/CHART_FILTER_UPDATE_LOADED';
export const chartFilterUpdateLoaded = response => ({
  type: CHART_FILTER_UPDATE_LOADED,
  ...response,
});
