import {useSelector} from 'react-redux';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

export default () => {
  const pinnedQuestions = useSelector((state) => {
    const pinnedQuestionIds = state.songPage.pinnedQuestions || [];
    const pinnedQuestionEntities = pinnedQuestionIds.map(
      id => get(state, ['entities', 'questions', id])
    );

    return orderBy(pinnedQuestionEntities || [], ['pinOrder']);
  });

  const pendingQuestions = useSelector((state) => {
    const pendingQuestionsIds = state.songPage.pendingQuestions || [];
    const questionEntities = pendingQuestionsIds.map(
      id => get(state, ['entities', 'questions', id])
    );

    return questionEntities;
  });

  return {
    pinnedQuestions,
    pendingQuestions,
  };
};
