import React from 'react';
import PropTypes from 'prop-types';
import {useFormState, Controller} from 'react-hook-form';
import get from 'lodash/get';

import {
  FieldContainer,
  FieldError,
  FieldHelpText,
  FieldLabel,
} from './Field.shared';

const Field = ({
  children,
  className,
  control,
  defaultValue,
  disabled,
  helpText,
  hideLabel,
  isRequired,
  label,
  name,
  rules,
}) => {
  const {errors} = useFormState({control});
  const fieldError = get(errors, name);

  return (
    <FieldContainer className={className}>
      {!hideLabel && <FieldLabel>{label}&nbsp;{isRequired && '*'}</FieldLabel>}
      <div>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({field, fieldState}) => children({
            ref: field.ref,
            value: field.value === null ? '' : field.value,
            hasError: fieldState.invalid,
            disabled,
            dataTestId: `${name}-input`,
            onChange: field.onChange,
            onBlur: field.onBlur,
            ...(hideLabel && {'aria-label': `${label} ${isRequired ? '*' : ''}`}),
          })}
          rules={rules}
        />
      </div>
      {fieldError && (
        <FieldError>{fieldError.message}</FieldError>
      )}
      {helpText && (
        <FieldHelpText>{helpText}</FieldHelpText>
      )}
    </FieldContainer>
  );
};

export default React.memo(Field);

Field.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};

Field.defaultProps = {
  defaultValue: '',
  disabled: false,
  hideLabel: false,
  isRequired: false,
  rules: {},
};
