import {post} from './client';
import {forumPostSchema, normalize} from '@/normalize';

export const createForumPost = async ({body, discussionId}) => {
  const response = await post('/forum_posts', {
    forum_post: {body},
    discussion_id: discussionId,
  });
  return normalize(response.forum_post, forumPostSchema);
};
