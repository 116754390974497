export const VOTE_SUBMITTED = 'songPage/VOTE_SUBMITTED';
export const voteSubmitted = ({entityType, entityId, vote}, callbacks) => ({
  type: VOTE_SUBMITTED,
  payload: {
    entityType,
    entityId,
    vote,
  },
  meta: callbacks,
});

export const VOTERS_FETCHED = 'songPage/VOTERS_FETCHED';
export const votersFetched = (entityType, entityId) => ({
  type: VOTERS_FETCHED,
  entityType,
  entityId,
});

export const VOTERS_FETCH_SUCCEEDED = 'songPage/VOTERS_FETCH_SUCCEEDED';
export const votersFetchSucceeded = (entityType, entityId, entities, voters) => ({
  type: VOTERS_FETCH_SUCCEEDED,
  entityType,
  entityId,
  entities,
  voters,
});

export const CLEAR_VOTES_SUBMITTED = 'songPage/CLEAR_VOTES_SUBMITTED';
export const clearVotesSubmitted = annotationId => ({
  type: CLEAR_VOTES_SUBMITTED,
  annotationId,
});

export const CLEAR_VOTES_SUCCEEDED = 'songPage/CLEAR_VOTES_SUCCEEDED';
export const clearVotesSucceeded = annotationId => ({
  type: CLEAR_VOTES_SUCCEEDED,
  annotationId,
});
