import {useEffect} from 'react';
import {IntersectionObserver} from '@/util';

export default ({ref, isObserving, onIntersecting}) => {
  useEffect(() => {
    if (!isObserving) return;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onIntersecting();
      }
    }, {
      threshold: 0,
      shim: {
        observe: onIntersecting,
      },
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [isObserving, onIntersecting, ref]);
};
