import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  font-size: ${p => p.theme.fontSize[p.fontSize]};
  font-weight: ${p => p.theme.fontWeight[p.fontWeight]};
  text-decoration: ${p => p.underline && 'underline'};
  color: ${p => p.useBackgroundColor ? p.theme.color.background.on : 'inherit'};

  &:hover {
    ${p => p.underline && 'text-decoration: none;'}
  }

  svg {
    fill: ${p => p.theme.color.background.on};
  }
`;

export default React.memo(StyledLink);

StyledLink.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  underline: PropTypes.bool,
  useBackgroundColor: PropTypes.bool,
};

StyledLink.defaultProps = {
  fontWeight: 'light',
  underline: true,
  useBackgroundColor: false,
};
