import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hamburger from '@/svg/hamburger.svg';
import XSmall from '@/svg/xSmall.svg';

const HamburgerToggle = ({isOpen, toggle}) =>
  <HamburgerToggle.Container onClick={toggle} $open={isOpen}>
    {isOpen ? <XSmall /> : <Hamburger />}
  </HamburgerToggle.Container>;

HamburgerToggle.Container = styled.button`
  display: flex;
  align-items: center;
  height: ${p => p.theme.header.height};
  width: calc(18px + ${p => p.theme.space.half} + ${p => p.theme.space.full});
  padding: ${p => `0 ${p.theme.space.half} 0 ${p.theme.space.full}`};

  svg {
    fill: ${p => p.theme.color.background.on};
    margin: 0 auto;
    display: block;
    width: ${p => p.$open ? '12px' : '18px'};
    position: relative;
  }
`;

HamburgerToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default React.memo(HamburgerToggle);
