import {get} from '@/api/client';
import {discographySchema, normalize} from '@/normalize';

export const getAlbums = async (artistId, {page = 1, perPage = 20, sort}) => {
  const response = await get(`/artists/${artistId}/albums`, {
    page,
    per_page: perPage,
    sort,
    pageviews: sort === 'popularity',
  });
  return normalize(response, {albums: [discographySchema]});
};

export const getSongs = async (artistId, {page = 1, perPage = 20, sort}) => {
  const response = await get(`/artists/${artistId}/songs`, {
    page: page,
    per_page: perPage,
    sort: sort,
  });
  return normalize(response, {songs: [discographySchema]});
};
