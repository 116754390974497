import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {SmallButton} from '@/components';

const SubNavigation = ({className, items}) => (
  <nav className={className}>
    <ul>
      {items.map(({isActive, label, url}) => (
        <SubNavigation.ListItem key={url}>
          <SmallButton inverted={isActive} as="a" href={url}>{label}</SmallButton>
        </SubNavigation.ListItem>
      ))}
    </ul>
  </nav>
);

export default React.memo(SubNavigation);

SubNavigation.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    isActive: PropTypes.bool,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

SubNavigation.ListItem = styled.li`
  display: inline-block;
  margin: 0 ${p => p.theme.space.half} ${p => p.theme.space.half} 0;

  &:last-of-type {
    margin-right: 0;
  }
`;
