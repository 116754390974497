import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import smoothScroll from '@/util/smoothScroll';

export default ({fragment, element}) => {
  const scrollToFragment = useSelector(state => state.songPage.scrollToFragment);

  useEffect(() => {
    if (fragment === scrollToFragment) {
      smoothScroll(element.current, 500, 200);
    }
  }, [fragment, scrollToFragment, element]);
};

