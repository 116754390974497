import {useSelector} from 'react-redux';
import get from 'lodash/get';

export default () => {
  const lyricsEditIds = useSelector(state => get(state, ['songPage', 'lyricsEditProposals'], []));
  const lyricsEditProposals = useSelector(state => lyricsEditIds.reduce((acc, id) => {
    acc[id] = get(state, ['entities', 'lyricsEditProposals', id]);
    return acc;
  }, {}));
  return lyricsEditIds.filter(id => get(lyricsEditProposals, [id, 'state']) === 'pending');
};
