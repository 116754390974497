import {useMemo} from 'react';

const useSidebarMetadataInputStyles = theme => useMemo(() => ({
  control: provided => ({
    ...provided,
    flexWrap: 'nowrap',
    minHeight: '34.5px',
    borderColor: theme.color.background.on,
    ':hover': {
      borderColor: 'unset',
    },
  }),
  input: provided => ({
    ...provided,
    padding: `0 ${theme.space.hair}`,
    margin: 0,
  }),
  multiValueLabel: provided => ({
    ...provided,
    padding: `0 ${theme.space.hair}`,
  }),
  option: provided => ({
    ...provided,
    fontSize: theme.fontSize.smallReading,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  valueContainer: provided => ({
    ...provided,
    margin: 0,
    padding: `0 ${theme.space.hair}`,
  }),
  placeholder: provided => ({
    ...provided,
    margin: 0,
    padding: `0 ${theme.space.hair}`,
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontSize: theme.fontSize.smallReading,
  }),
  singleValue: provided => ({
    ...provided,
    margin: 0,
    padding: `0 ${theme.space.hair}`,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    margin: `0 ${theme.space.xSmall} 0 ${theme.space.quarter}`,
    padding: 0,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
    'svg': {
      height: theme.fontSize.xxSmallReading,
    },
  }),
  loadingMessage: provided => ({
    ...provided,
    fontSize: theme.fontSize.smallReading,
  }),
}), [theme]);

export default useSidebarMetadataInputStyles;
