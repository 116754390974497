import React from 'react';
import styled from 'styled-components';
import {useSelector, shallowEqual} from 'react-redux';
import {useTranslation} from 'react-i18next';
import pick from 'lodash/pick';
import {TextLabel} from '@/components';
import {useIsMobile} from '@/hooks';

const PageFooterHotSongLinks = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const {hotSongsPreview = [], hotSongsLink = '/hot-songs'} = useSelector(
    state => pick(state[state.currentPage], ['hotSongsPreview', 'hotSongsLink']),
    shallowEqual
  );

  return (
    <>
      {hotSongsPreview.map(({id, url, title}) =>
        <PageFooterHotSongLinks.Link key={id} href={url} isMobile={isMobile}>
          <TextLabel size="smallReading">{title}</TextLabel>
        </PageFooterHotSongLinks.Link>
      )}
      <PageFooterHotSongLinks.Link href={hotSongsLink} isMobile={isMobile}>
        <TextLabel size="smallReading">{t('page_footer.hot_songs.view_all')}</TextLabel>
      </PageFooterHotSongLinks.Link>
    </>
  );
};

export default React.memo(PageFooterHotSongLinks);

PageFooterHotSongLinks.Link = styled.a`
  display: block;
  color: inherit;

  & + & {
    &:before {
      content: '•';
      margin: 0 ${p => p.isMobile ? p.theme.space.half : p.theme.space.small};
    }
  }
`;
