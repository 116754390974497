import {get} from '@/api/client';
import {tagAutocompleteSchema, normalize} from '@/normalize';

export const getTagAutocomplete = async (searchTerm) => {
  const response = await get('/tags/autocomplete', {q: searchTerm});
  return normalize(response.tags, tagAutocompleteSchema);
};

export const getPrimaryTags = async () => {
  const response = await get('/tags/home');
  return normalize(response.tags, tagAutocompleteSchema);
};
