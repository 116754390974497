import {useMemo} from 'react';

export default theme => useMemo(() => ({
  input: provided => ({
    ...provided,
    color: theme.color.background.on,
    fontSize: theme.fontSize.smallReading,
  }),
  menuList: provided => ({
    ...provided,
    fontSize: theme.fontSize.smallReading,
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    'svg': {
      fill: theme.color.background.on,
    },
    padding: theme.space.xSmall,
  }),
}), [theme]);
