import React from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';

const Placeholder = ({width, height, isLoading, children}) => (
  isLoading ?
    (<Placeholder.Container width={width} height={height} />) :
    (children)
);

Placeholder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.element,
};

const animation = keyframes`
  from { opacity: 1; }
  to { opacity: 0.5; }
`;

Placeholder.Container = styled.div`
  height: ${p => p.height || `${p.theme.lineHeight.short}rem`};
  width: ${p => p.width || '100%'};
  animation: ${animation} 800ms ease-in-out alternate infinite;
  background-color: ${p => p.theme.color.background.blockOnVariant}
`;

export default React.memo(Placeholder);
