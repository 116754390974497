export const setCSSVariable = (name, value) => {
  if (!document) return;
  document.documentElement.style.setProperty(name, value);
};

export const removeCSSVariable = (name) => {
  if (!document) return;
  document.documentElement.style.removeProperty(name);
};

export const isBlack = hex => (hex === '#000' || hex === '#000000');
