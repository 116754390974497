import {AppConfig} from '@/config';
import {isClient} from '@/util';
import once from 'lodash/once';
import noop from 'lodash/noop';
import get from 'lodash/get';
import getMixpanel from '@/mixpanel';
import {mixpanelPropertiesToFullstory} from '@/util/tracking';
import {onCLS, onFCP, onFID, onLCP, onTTFB, onINP} from 'web-vitals/attribution';

const notifyMixpanelLoaded = (mixpanel) => {
  window['Genius.cmp'] = window['Genius.cmp'] || [];
  window['Genius.cmp'].push((gcmp) => {
    gcmp.resolve_with_tracking(mixpanel);
  });
};

export const track = (eventName, properties = {}, state, done) => {
  const {deviceType, currentPage, session: {currentUser}} = state;
  const userEntity = get(state, ['entities', 'users', currentUser]);
  const pageTrackingData = get(state, [currentPage, 'trackingData'], {});

  getMixpanel().then((mixpanel) => {
    mixpanel.identify(currentUser);
    const eventProps = {
      'Is Editor': Boolean(currentUser && userEntity.isEditor),
      'Is Moderator': Boolean(currentUser && userEntity.isModerator),
      'Logged In': Boolean(currentUser),
      'Mobile Site': deviceType === 'mobile',
      assembly_uid: window.getAnaUid?.(),
      page: currentPage,
      react: true,
      ...pageTrackingData,
      ...properties,
    };
    mixpanel.track(eventName, eventProps, done);

    if (window.smartlook) {
      window.smartlook('track', eventName, eventProps);
    }
    if (window.FS) {
      window.FS.event(eventName, mixpanelPropertiesToFullstory(eventProps));
    }
  });
};

const getCause = ({name, attribution}) => {
  switch (name) {
  case 'CLS':
    return attribution.largestShiftTarget;
  case 'LCP':
  case 'FCP':
    return attribution.element;
  case 'FID':
  case 'INP':
    return attribution.eventTarget;
  }
};

export const logWebVitalsToMixpanel = (page, state, properties = {}) => {
  const trackMixpanelEvent = (eventName, metricProperties = {}) => {
    track(eventName, {...properties, ...metricProperties}, state);
  };

  const trackWebVital = (metric) => {
    trackMixpanelEvent(`${page}:${metric.name.toLowerCase()}`, {value: metric.value, cause: getCause(metric)});
  };

  onCLS(trackWebVital);
  onFID(trackWebVital);
  onLCP(trackWebVital);
  onFCP(trackWebVital);
  onTTFB(trackWebVital);
  onINP(trackWebVital);
};

export default once(
  () => import('mixpanel-browser').
    then(({default: mixpanel}) => {
      if (isClient && AppConfig.mixpanelEnabled) {
        mixpanel.init(AppConfig.mixpanelToken, {loaded: notifyMixpanelLoaded});
        mixpanel.register({
          AMP: false,
          genius_platform: 'web',
          user_agent: window.navigator.userAgent,
          assembly_uid: window.getAnaUid?.(),
        });

        [
          'AB Test - apple_desktop_static_cta',
          'AB Test - apple_player_position',
          'AB Test - apple_player_position_mobile',
          'Annotatable ID',
          'Annotatable Type',
          'Comment Count',
          'Has Apple Match',
          'Has Description',
          'Has Recirculated Articles',
          'Has Translation Q&A',
          'Has Youtube URL',
          'Lyrics Language',
          'Music?',
          'NRM Target Date',
          'NRM Tier',
          'Primary Album',
          'Primary Album ID',
          'Primary Artist',
          'Primary Artist ID',
          'Primary Tag',
          'Primary Tag ID',
          'Release Date',
          'Song ID',
          'Title',
          'amp_cache',
          'cohort_ids',
          'containing_frame_is_fullbleed',
          'created_at',
          'created_month',
          'created_year',
          'distinct_id',
          'featured_video',
          'has_featured_annotation',
          'has_verified_callout',
          'hot',
          'platform_variant',
          'provider',
          'provider_id',
          'react',
          'song',
          'song_id',
          'song_tier',
          'web_interstitial_variant',
        ].forEach((property) => {
          mixpanel.unregister(property);
        });
        return mixpanel;
      } else {
        return {
          identify: noop,
          track: noop,
        };
      }
    })
);
