import React, {useState, useCallback, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {TextLabel} from '@/components';
import {zIndex} from '@/style';
import {useOnClickOutside, useAnimatedDimensions, useTheme} from '@/hooks';
import SquareSelectOption from './SquareSelectOption';
import SquareSelectTitle from './SquareSelectTitle';
import {useSpring, animated} from 'react-spring';

const SquareManySelects = ({name, selects, onSelect, selected}) => {
  const [expanded, setExpanded] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState('auto');
  const element = useRef(null);
  const dimensionProps = useAnimatedDimensions({
    element,
    dep: expanded,
    options: style => ({
      to: async (next) => {
        if (expanded) {
          await next({width: style.width});
          await next({height: style.height});
        } else {
          await next({height: style.height});
          await next({width: style.width});
        }
      },
      config: {mass: 1, tension: 800, friction: 20, clamp: true},
    }),
  });
  const theme = useTheme();
  const boxShadowProps = useSpring({boxShadow: expanded ? theme.boxShadow.standard : '0px 0px 0px rgba(0, 0, 0, 0.2)'});

  useOnClickOutside({
    onClickOutside: useCallback(() => {
      if (expanded) {
        setExpanded(false);
      }
    }, [expanded]),
    element,
  });

  useEffect(() => {
    if (wrapperHeight === 'auto' && dimensionProps.height.value && dimensionProps.height.value !== 'auto') {
      setWrapperHeight(dimensionProps.height.value);
    }
  }, [wrapperHeight, dimensionProps]);

  const largestOptionIndex = Math.max(
    ...Object.values(selects).map(select => Object.keys(select.options).length)
  ) - 1;

  return (
    <SquareManySelects.Wrapper wrapperHeight={wrapperHeight}>
      <SquareManySelects.Container
        onClick={() => setExpanded(!expanded)}
        ref={element}
        style={{...dimensionProps, ...boxShadowProps}}
      >
        <SquareSelectTitle text={name} selecting={expanded} />
        <SquareManySelects.Selects expanded={expanded}>
          {Object.keys(selects).map(
            select =>
              <SquareManySelects.Select key={select}>
                <SquareManySelects.SelectTitle>
                  <TextLabel size="smallReading">{selects[select].name}</TextLabel>
                </SquareManySelects.SelectTitle>
                {Object.keys(selects[select].options).map(
                  (option, i) =>
                    <SquareManySelects.Option
                      key={option}
                      onClick={() => onSelect({...selected, [select]: option})}
                      border={i < largestOptionIndex}
                    >
                      <SquareSelectOption
                        text={selects[select].options[option]}
                        selected={selected[select] === option}
                      />
                    </SquareManySelects.Option>
                )}
              </SquareManySelects.Select>
          )}
        </SquareManySelects.Selects>
      </SquareManySelects.Container>
    </SquareManySelects.Wrapper>
  );
};

SquareManySelects.propTypes = {
  name: PropTypes.string.isRequired,
  selects: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
};

export default React.memo(SquareManySelects);

SquareManySelects.Wrapper = styled.div`
  ${zIndex('squareSelect')};
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  height: ${p => p.wrapperHeight}px;
`;

SquareManySelects.Container = styled(animated.div)`
  position: relative;
  background: ${p => p.theme.color.background.main};
  border: ${p => p.theme.border.standardWidthPixels} solid ${p => p.theme.color.background.on};
  cursor: pointer;
  user-select: none;
  line-height: 1;
  overflow: hidden;
`;

SquareManySelects.SelectTitle = styled.div`
  padding: ${p => p.theme.space.small} ${p => p.theme.space.full};
  border-bottom: ${p => p.theme.border.standardWidthPixels} solid ${p => p.theme.color.background.variant};
`;

SquareManySelects.Select = styled.div`
  flex: 1;
`;

SquareManySelects.Selects = styled.div`
  display: flex;
  border-top: ${p => p.theme.border.standardWidthPixels} solid ${p => p.theme.color.background.variant};
  ${p => p.expanded ? '' : 'position: absolute;'}

  ${SquareManySelects.Select} + ${SquareManySelects.Select} {
    border-left: ${p => p.theme.border.standardWidthPixels} solid ${p => p.theme.color.background.variant};
  }
`;

SquareManySelects.Option = styled.div`
  ${p => p.border ? `border-bottom: ${p.theme.border.standardWidthPixels} solid ${p.theme.color.background.variant};` : ''}
`;
