import {produce} from 'immer';
import {HEADER_ITEM_OPENED, HEADER_ITEM_CLOSED} from '@/actions';

export default produce((draft = null, action) => {
  switch (action.type) {
  case HEADER_ITEM_OPENED:
    return action.item;
  case HEADER_ITEM_CLOSED:
    return draft === action.item ? null : draft;
  default:
    return draft;
  }
});
