import {AppConfig} from '@/config';

const OLD_FILEPICKER_URL = new RegExp(`
  ^https?://(
    ${AppConfig.filepickerS3ImageBucket}\.(?<region>[^.]+\.)?s3\.amazonaws\.com |
    s3\.amazonaws\.com/${AppConfig.filepickerS3ImageBucket}
  )/(?<key>.*)$
`.replace(/[ \n]/g, ''));

export const rewriteFilepickerImageUrls = (src) => {
  if (!AppConfig.filepickerCdnDomain) {
    return src;
  }

  const match = OLD_FILEPICKER_URL.exec(src);

  if (match) {
    return `https://${AppConfig.filepickerCdnDomain}/${match.groups.key}`;
  } else {
    return src;
  }
};
