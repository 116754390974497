import {useSelector, shallowEqual} from 'react-redux';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import sum from 'lodash/sum';

import {IqByEventType} from '@/config/iq';

export default (action, entity) => {
  const virtualIqEventMap = useSelector(state => (
    get(state.entities.users, [state.session.currentUser, 'virtualIqEventTypeMap'], {})
  ), shallowEqual);

  if (entity) {
    const iqs = Object.values(get(entity, ['currentUserMetadata', 'iqByAction', action], {})).
      filter(a => a.applicable).
      map(a => a.base * a.multiplier);
    return sum(iqs);
  } else if (IqByEventType.hasOwnProperty(action)) {
    return IqByEventType[action];
  } else if (virtualIqEventMap.hasOwnProperty(action)) {
    const eventName = camelCase(virtualIqEventMap[action]);
    return IqByEventType[eventName];
  } else {
    return 0;
  }
};
