import {produce} from 'immer';

import {
  MODAL_TOGGLED,
} from '@/actions';

export default produce((draft, action) => {
  switch (action.type) {
  case MODAL_TOGGLED:
    draft.showing = action.visible;
    break;
  default:
    return draft;
  }
});
