import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {buttonHover} from '@/style';
import {isBlack} from '@/util/style';
import {Spinner} from '@/components';

const SmallButton = ({children, inverted, secondary, showLoadingSpinner, ...remainingProps}) => (
  <SmallButton.Container inverted={inverted} secondary={secondary} showLoadingSpinner={showLoadingSpinner} {...remainingProps}>
    {children}
    {showLoadingSpinner && <SmallButton.Spinner />}
  </SmallButton.Container>
);

SmallButton.propTypes = {
  children: PropTypes.node,
  inverted: PropTypes.bool,
  secondary: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
};

SmallButton.defaultProps = {
  inverted: false,
  secondary: false,
  showLoadingSpinner: false,
  type: 'button',
};

export default React.memo(SmallButton);

SmallButton.Container = styled.button.attrs(p => ({type: p.type}))`
  display: flex;
  align-items: center;
  border-radius: ${p => p.theme.borderRadius};
  padding: ${p => p.theme.space.quarter} ${p => p.theme.space.small};
  border: ${p => `1px solid ${p.theme.color.background.on}`};
  font-family: ${p => p.theme.font.alternate};
  font-size: ${p => p.theme.fontSize.smallReading};
  color: ${p => p.inverted ? p.theme.color.background.main : p.theme.color.background.on};
  line-height: ${p => p.theme.lineHeight.short};

  ${p => p.inverted && `background-color: ${p.theme.color.background.on};`}

  &:disabled {
    opacity: .5;
  }

  ${p => p.secondary ? css`
    border-color: transparent;

    &:hover:not(:disabled) {
      background-color: ${isBlack(p.theme.color.background.on) ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.3)'};
    }
  ` : css`
    &:hover:not(:disabled) {
      ${buttonHover(p.theme.color.background.on)}
    }
  `}
`;

SmallButton.Spinner = styled(props => <Spinner {...props} />)`
  fill: none !important;
  margin-left: ${p => p.theme.space.half};
  vertical-align: -2px;

  ${p => !p.$secondary && css`
    ${SmallButton.Container}:hover & {
      fill: none;
      stroke: currentColor;
    }
  `}
`;
