import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {SquareButton, PlaceholderSpinner} from '@/components';
import {loadMoreClicked} from '@/homePage/actions';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {useTranslation} from 'react-i18next';

const LoadMoreButton = ({path, onClick = noop, container}) => {
  const buttonPath = useSelector(state => get(state, path));
  const isLoading = Boolean(buttonPath.isLoading);
  const hasMore = Boolean(buttonPath.nextPage);
  const dispatch = useDispatch();
  const Container = container;
  const {t} = useTranslation();

  const loadMore = () => {
    onClick();
    dispatch(loadMoreClicked(path));
  };

  return hasMore && (
    <Container>
      {isLoading ?
        <LoadMoreButton.Spinner>
          <PlaceholderSpinner inline={false} />
        </LoadMoreButton.Spinner> :
        <SquareButton onClick={loadMore}>{t('load_more')}</SquareButton>
      }
    </Container>
  );
};

LoadMoreButton.propTypes = {
  path: PropTypes.string.isRequired,
  container: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default React.memo(LoadMoreButton);

LoadMoreButton.Spinner = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;
`;
