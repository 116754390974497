import {produce} from 'immer';
import {
  AUTH_MODAL_OPENED,
  AUTH_MODAL_CLOSED,
} from '@/actions';

export default produce((draft, action) => {
  switch (action.type) {
  case AUTH_MODAL_OPENED:
    draft.showing = true;
    draft.reason = action.reason;
    break;
  case AUTH_MODAL_CLOSED:
    draft.showing = false;
    draft.reason = null;
    break;
  default:
    return draft;
  }
});
