import {get, post, put} from '@/api/client';
import {questionSchema, normalize} from '@/normalize';

export const getQuestions = async (entity, id, {state, page}) => {
  const response = await get('/questions', {
    page,
    state,
    [`${entity}_id`]: id,
  });
  return normalize(response, {questions: [questionSchema]});
};

export const createQuestion = async (payload) => {
  const {question} = await post('/questions', {
    song_id: payload.song_id,
    question: {
      ...payload,
      text_format: 'plain',
    },
  });
  return normalize(question, questionSchema);
};

export const editQuestion = async (questionId, body) => {
  const {question} = await put(`/questions/${questionId}`, {
    question: {
      body,
    },
  });
  return normalize(question, questionSchema);
};

export const changeQuestionState = async (questionId, state) => {
  const {question} = await put(`/questions/${questionId}/change_state`, {
    state_event: state,
  });
  return normalize(question, questionSchema);
};

export const moveQuestion = async (questionId, direction) => {
  const {question} = await put(`/questions/${questionId}/move`, {
    direction,
  });
  return normalize(question, questionSchema);
};
