import {useState, useEffect} from 'react';
import pick from 'lodash/pick';
import {useSpring} from 'react-spring';
import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';

export default ({element, dep, options}) => {
  const [style, setStyle] = useState({width: 'auto', height: 'auto'});
  const [measured, setMeasured] = useState(true);
  const spring = useSpring(merge(
    {to: style, from: {width: 'auto', height: 'auto'}},
    isFunction(options) ? options(style) : options
  ));

  useEffect(() => {
    setStyle({width: spring.width.value, height: spring.height.value});
    setMeasured(false);
    const animationFrameId = window.requestAnimationFrame(
      () => {
        setStyle(pick(element.current.getBoundingClientRect(), ['height', 'width']));
        setMeasured(true);
      }
    );
    return () => window.cancelAnimationFrame(animationFrameId);
  }, [dep, element, spring.width, spring.height]);

  return measured ? spring : {width: 'auto', height: 'auto'};
};
