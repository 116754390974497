import React from 'react';
import {TextLabel} from '@/components';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const IconWithLabel = ({label, color = 'background.on', iconTop = 0, children}) =>
  <IconWithLabel.Container>
    <IconWithLabel.Icon top={iconTop} color={color}>
      {children}
    </IconWithLabel.Icon>
    <TextLabel color={color}>{label}</TextLabel>
  </IconWithLabel.Container>;

IconWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  iconTop: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default IconWithLabel;

IconWithLabel.Container = styled.div`
  display: flex;
  align-items: center;
`;

IconWithLabel.Icon = styled.div`
  width: 1rem;
  margin-right: ${p => p.theme.space.half};
  position: relative;
  top: ${p => p.top}px;
  flex-shrink: 0;

  svg {
    display: block;
    height: 100%;
    overflow: visible;
    ${p => p.color ? `fill: ${get(p.theme.color, p.color)}` : ''};
  }
`;
