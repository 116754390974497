import React, {useState, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {zIndex} from '@/style';
import {useAnimatedDimensions, useOnClickOutside, useTheme} from '@/hooks';
import SquareSelectOption from './SquareSelectOption';
import SquareSelectTitle from './SquareSelectTitle';
import {useSpring, animated} from 'react-spring';

const SquareSelect = ({options, onSelect, selected}) => {
  const [expanded, setExpanded] = useState(false);
  const element = useRef(null);
  const dimensionProps = useAnimatedDimensions({
    element,
    dep: expanded,
    options: {config: {mass: 1, tension: 800, friction: 20, clamp: true}},
  });
  const theme = useTheme();
  const boxShadowProps = useSpring({boxShadow: expanded ? theme.boxShadow.standard : '0px 0px 0px rgba(0, 0, 0, 0.2)'});

  useOnClickOutside({
    onClickOutside: useCallback(() => {
      if (expanded) {
        setExpanded(false);
      }
    }, [expanded]),
    element,
  });

  return (
    <SquareSelect.Wrapper>
      <SquareSelect.Container
        onClick={() => setExpanded(!expanded)}
        ref={element}
        style={{...dimensionProps, ...boxShadowProps}}
      >
        <SquareSelectTitle text={options[selected]} selecting={expanded} />
        <SquareSelect.Options expanded={expanded}>
          {Object.keys(options).map(
            key =>
              <SquareSelect.Option onClick={() => onSelect(key)} key={key}>
                <SquareSelectOption text={options[key]} selected={selected === key} />
              </SquareSelect.Option>
          )}
        </SquareSelect.Options>
      </SquareSelect.Container>
    </SquareSelect.Wrapper>
  );
};

SquareSelect.propTypes = {
  options: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export default React.memo(SquareSelect);

const optionHeight = p => `calc(${p.theme.space.small} * 2 + ${p.theme.space.full} + ${p.theme.border.standardWidthPixels} * 2)`;

SquareSelect.Wrapper = styled.div`
  ${zIndex('squareSelect')};
  text-align: left;
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: ${optionHeight};
`;

SquareSelect.Container = styled(animated.div)`
  position: relative;
  background: ${p => p.theme.color.background.main};
  border: ${p => p.theme.border.standardWidthPixels} solid ${p => p.theme.color.background.on};
  cursor: pointer;
  user-select: none;
  line-height: 1;
  overflow: hidden;
`;

SquareSelect.Options = styled.div`
  ${p => p.expanded ? '' : 'position: absolute;'}
`;

SquareSelect.Option = styled.div`
  border-top: ${p => p.theme.border.standardWidthPixels} solid ${p => p.theme.color.background.variant};
`;
