/* eslint-disable react/no-multi-comp */
import React, {useCallback, useMemo, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {useTheme} from 'styled-components';
import {components} from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import isPlainObject from 'lodash/isPlainObject';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import {breakWord, zIndex} from '@/style';
import {InlineSvg} from '@/components';
import DraggableHandle from '@/svg/draggableHandle.svg';
import Lock from '@/svg/lock.svg';
import X from '@/svg/x.svg';

import {reactSelectStyles, reactSelectTheme, ClearIndicator} from './Input.shared';
import {useGlobalEvent} from '@/hooks';

const MultiLabel = ({primary, secondary, secondaryPrefix}) =>
  <React.Fragment>{primary} <TagInput.SecondaryLabel>{secondaryPrefix}{secondary}</TagInput.SecondaryLabel></React.Fragment>;

MultiLabel.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
};

const MultiValue = (props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = {...props.innerProps, onMouseDown};
  const contents = isPlainObject(props.children) ?
    (
      <components.MultiValue {...props} innerProps={innerProps}>
        <MultiLabel secondaryPrefix={props.selectProps.secondaryPrefix} {...props.children} />
      </components.MultiValue>
    ) : (
      <components.MultiValue {...props} innerProps={innerProps} />
    );

  return (
    <TagInput.MultiValueWrapper>
      {contents}
    </TagInput.MultiValueWrapper>
  );
};

MultiValue.propTypes = {
  innerProps: PropTypes.object,
  children: PropTypes.node,
  selectProps: PropTypes.object,
};

const SortableMultiValue = SortableElement(MultiValue);

const SortableMultiValueLabel = SortableHandle(({children, ...otherProps}) => {
  const hasMultipleValues = otherProps.selectProps.value.length > 1;
  return (
    <TagInput.MultiValueLabel sortable={true}>
      {hasMultipleValues && <InlineSvg aspectRatio={14.6 / 22} lineHeight={.6} svg={DraggableHandle} verticalAlign={0} />}
      {children}
    </TagInput.MultiValueLabel>
  );
});

SortableMultiValueLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

const MultiValueLabel = ({children}) => <TagInput.MultiValueLabel>{children}</TagInput.MultiValueLabel>;

MultiValueLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

const MultiValueRemove = (props) => {
  const {selectProps} = props;
  if (selectProps.isDisabled) {
    return null;
  }

  return (
    <components.MultiValueRemove {...props}>
      <X />
    </components.MultiValueRemove>
  );
};

MultiValueRemove.propTypes = {
  selectProps: PropTypes.object,
};

const Input = props => <components.Input focus-id={props.selectProps.focusId} {...props} />;

Input.propTypes = {
  selectProps: PropTypes.object,
};

const Option = (props) => {
  if (isPlainObject(props.label)) {
    return <components.Option {...props}><MultiLabel {...props.label} /></components.Option>;
  }
  return <components.Option {...props} />;
};

Option.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    MultiLabel.propTypes,
  ]),
};

const selectMemoComparator = (prevProps, nextProps) => {
  if (!prevProps.value || !nextProps.value) {
    return false;
  }
  const diff = xorWith(prevProps.value, nextProps.value, isEqual);
  return prevProps.value.length === nextProps.value.length && diff.length === 0;
};

const MemoizedAsyncSelect = React.memo(AsyncSelect, selectMemoComparator);

const MemoizedAsyncCreatableSelect = React.memo(AsyncCreatableSelect, selectMemoComparator);

const SortableSelect = SortableContainer(React.memo(AsyncCreatableSelect, (prevProps, nextProps) => {
  if (!prevProps.value || !nextProps.value) {
    return false;
  }

  if (prevProps.value.length !== nextProps.value.length) {
    return false;
  }

  return prevProps.value.every((val, idx) => isEqual(val, nextProps.value[idx]));
}));

const IndicatorsContainer = (props) => {
  const {selectProps} = props;
  if (selectProps.isDisabled) {
    return <TagInput.DisabledIcon><Lock /></TagInput.DisabledIcon>;
  }
  return null;
};

IndicatorsContainer.propTypes = {
  selectProps: PropTypes.object,
};

const sortableComponents = {
  IndicatorsContainer: () => null,
  MultiValue: SortableMultiValue,
  MultiValueLabel: SortableMultiValueLabel,
  MultiValueRemove,
  Option,
  Input,
};

const standardComponents = {
  IndicatorSeparator: () => null,
  IndicatorsContainer,
  DropdownIndicator: () => null,
  ClearIndicator,
  MultiValue,
  MultiValueLabel,
  MultiValueRemove,
  Option,
  Input,
};

const TagInput = ({
  creatablePreservesOnBlur,
  dataTestId,
  defaultValue,
  disabled,
  isCreatable,
  isMulti,
  isSortable,
  loadOptions,
  onChange,
  placeholder,
  promptMessage,
  secondaryPrefix,
  valueBackgroundKey,
  customStyles,
  preservesOnBlur,
  focusId,
  onSelection,
}) => {
  const selectRef = useRef();
  const lastChangeAction = useRef();
  const theme = useTheme();
  const [selected, setSelected] = useState(defaultValue);
  const isSingleWithValue = !isMulti && selected && selected.length === 1;

  const onValueChange = useCallback((selectedOptions, {action}) => {
    if (isSingleWithValue && (action === 'remove-value' || action === 'pop-value')) {
      setTimeout(() => selectRef.current.focus(), 0);
    }

    setSelected(selectedOptions);

    if (onChange) {
      onChange(selectedOptions, action);
    }

    lastChangeAction.current = action;

    if (!isMulti && (action === 'select-option' || action === 'create-option')) {
      onSelection && onSelection();
      selectRef.current.blur();
    }
  }, [isSingleWithValue, onChange, isMulti, onSelection]);


  const metadataFormClearEvent = useGlobalEvent('clearSidebarContributorMetadataForm');

  useEffect(() => {
    const clear = () => {
      setSelected(null);
    };
    metadataFormClearEvent.subscribe(clear);
    return () => {
      metadataFormClearEvent.unsubscribe(clear);
    };
  }, [metadataFormClearEvent]);

  const onSortEnd = useCallback(({oldIndex, newIndex}) => {
    const newSelected = selected.slice();
    const updateItemValue = newSelected.splice(oldIndex, 1)[0];
    newSelected.splice(newIndex < 0 ? newSelected.length + newIndex : newIndex, 0, updateItemValue);
    onValueChange(newSelected, {action: 'set-value-on-sort'});
  }, [onValueChange, selected]);

  const onKeyDown = useCallback((evt) => {
    if (evt.key === 'Escape') {
      evt.target.blur();
      evt.stopPropagation();
    }
  }, []);

  const onBlur = () => {
    if (
      !(isCreatable || preservesOnBlur) ||
        !creatablePreservesOnBlur ||
        (!isMulti && (lastChangeAction.current === 'select-option' || lastChangeAction.current  === 'create-option'))
    ) {
      return;
    }

    const {inputValue} = selectRef.current.state;
    const label = inputValue?.trim();
    if (!label) {
      return;
    }

    const newTagValue = {label, value: label, __isNew__: true};
    const newValue = [...(selected || []), newTagValue];
    onValueChange(newValue, {action: 'set-value-on-blur'});
  };

  const noOptionsMessage = useCallback(() => promptMessage, [promptMessage]);
  const styles = useMemo(() => reactSelectStyles(theme), [theme]);
  const inputTheme = useMemo(() => reactSelectTheme(theme), [theme]);

  const commonSelectProps = {
    isDisabled: disabled,
    isMulti: true,
    isSearchable: !isSingleWithValue,
    loadOptions,
    styles: {...styles, ...customStyles},
    noOptionsMessage,
    onBlur,
    onChange: onValueChange,
    onKeyDown,
    openMenuOnFocus: !isSingleWithValue,
    tabSelectsValue: false,
    placeholder,
    ref: selectRef,
    theme: inputTheme,
    value: selected,
    secondaryPrefix,
    valueBackgroundKey,
    focusId,
  };

  if (isSingleWithValue) {
    commonSelectProps.menuIsOpen = false;
  }

  const SelectComponent = isCreatable ? MemoizedAsyncCreatableSelect : MemoizedAsyncSelect;

  const roundDimensionsUp = ({node}) => {
    const boundingRect = node.getBoundingClientRect();
    return {
      height: Math.ceil(boundingRect.height),
      width: Math.ceil(boundingRect.width),
    };
  };

  return (
    <TagInput.Container data-testid={dataTestId}>
      {isSortable ? (
        <SortableSelect
          isClearable
          useDragHandle
          axis="xy"
          onSortEnd={onSortEnd}
          distance={4}
          helperClass="helper-dragging"
          getHelperDimensions={roundDimensionsUp}
          components={sortableComponents}
          {...commonSelectProps}
        />
      ) : (
        <SelectComponent
          components={standardComponents}
          {...commonSelectProps}
        />
      )}
    </TagInput.Container>
  );
};

TagInput.propTypes = {
  creatablePreservesOnBlur: PropTypes.bool,
  dataTestId: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSortable: PropTypes.bool,
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  promptMessage: PropTypes.string.isRequired,
  secondaryPrefix: PropTypes.string,
  valueBackgroundKey: PropTypes.string,
  customStyles: PropTypes.object,
  preservesOnBlur: PropTypes.bool,
  focusId: PropTypes.string,
  onSelection: PropTypes.func,
};

TagInput.defaultProps = {
  creatablePreservesOnBlur: true,
  disabled: false,
  isCreatable: true,
  isMulti: true,
  isSortable: false,
  preservesOnBlur: false,
};

export default TagInput;

TagInput.Container = styled.div`
  font-family: ${p => p.theme.font.alternate};
`;

TagInput.DisabledIcon = styled.div`
  display: flex;
  align-self: center;
  width: ${p => p.theme.fontSize.xSmallReading};
  margin-right: ${p => p.theme.space.half};
`;

TagInput.MultiValueLabel = styled.div`
  color: ${p => p.theme.color.background.on};
  font-size: ${p => p.theme.fontSize.smallReading};
  padding: ${p => p.theme.space.hair} ${p => p.theme.space.half};
  ${breakWord}
  overflow: hidden;

  svg {
    margin-right: ${p => p.theme.space.xSmall};
  }

  ${p => p.sortable ? 'cursor: move;' : ''}
`;

TagInput.SecondaryLabel = styled.span`
  color: ${p => p.theme.color.background.onVariant};

  &:before {
    content: '\\2014';
    margin-right: ${p => p.theme.space.hair};
  }
`;

TagInput.MultiValueWrapper = styled.div`
  &.helper-dragging {
    ${zIndex('draggingValue')}
  }
`;
