import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTheme, useLoggedIn} from '@/hooks';

const COLLAPSED_MARQUEE_HEIGHT = '10vw';
const MOBILE_STICKY_BANNER_AD_HEIGHT = '50px';

export default (name) => {
  const {stickyNav, contributorStickyNav, grid, lineHeight} = useTheme();
  const deviceType = useSelector(state => state.deviceType);
  const isLoggedIn = useLoggedIn();

  const stickyLeaderboardHeight = useSelector((state) => {
    if (state.session.showAds) {
      if (deviceType === 'mobile') {
        return get(state.stickyNav, 'stickyBannerAdVisible', true) ? MOBILE_STICKY_BANNER_AD_HEIGHT : '0px';
      } else {
        return get(state.stickyNav, 'stickyLeaderboardHeight', 0);
      }
    } else {
      return '0px';
    }
  });

  const gridGap = get(grid, 'gap', 0);
  const stickyNavHeight = get(stickyNav, ['height', deviceType], 0);
  const stickyContributorHeight = isLoggedIn ? get(contributorStickyNav, ['height'], 0) : '0px';

  switch (name) {
  case 'stickyNav':
    return stickyLeaderboardHeight;
  case 'bottomStickyNav':
  case 'songHeader':
    return `${stickyLeaderboardHeight} + ${stickyNavHeight}`;
  case 'contributorStickyNav':
    return `${stickyLeaderboardHeight} + ${stickyNavHeight}`;
  case 'lyricsEditControls':
    return `${stickyLeaderboardHeight} + ${stickyNavHeight} + ${stickyContributorHeight} + ${gridGap}`;
  case 'stickyAd':
    return `${stickyLeaderboardHeight} + ${stickyNavHeight} + ${stickyContributorHeight} + ${gridGap}`;
  case 'referentFragment':
    return `max(${COLLAPSED_MARQUEE_HEIGHT}, ${stickyLeaderboardHeight}) + ${stickyNavHeight} + ${stickyContributorHeight} + (${lineHeight.reading} * 1em)`;
  default:
    throw new Error(`Distance above ${name} is not known`);
  }
};
