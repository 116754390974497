import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import XSmall from '@/svg/xSmall.svg';
import {AppConfig} from '@/config';
import Cookie from 'js-cookie';

const cookieName = `solidarity_flag_${AppConfig.solidarityText}_${AppConfig.solidarityUrl}`;

const SolidarityBanner = () => {
  const [open, setOpen] = useState(false);

  const close = useCallback(() => {
    setOpen(false);
    Cookie.set(cookieName, Date.now(), {path: '/'});
  }, []);

  useEffect(() => {
    const seen = Cookie.get(cookieName);

    if (!seen) {
      setOpen(true);
    }
  }, []);

  if (!AppConfig.solidarityText || !open) {
    return null;
  }

  const linkProps = {
    href: AppConfig.solidarityUrl,
  };
  if ((new URL(AppConfig.solidarityUrl).host) !== AppConfig.canonicalDomain) {
    linkProps.target = '_blank';
  }

  return (
    <SolidarityBanner.Main>
      <SolidarityBanner.Text data-nosnippet>
        {AppConfig.solidarityText}&nbsp;
        <SolidarityBanner.LearnLink {...linkProps}>
          Learn More
        </SolidarityBanner.LearnLink>
      </SolidarityBanner.Text>
      <SolidarityBanner.Close onClick={close}>
        <XSmall />
      </SolidarityBanner.Close>
    </SolidarityBanner.Main>
  );
};

SolidarityBanner.Main = styled.div`
  position: relative;
  border-top: 1px solid ${p => p.theme.color.background.variant};
  border-bottom: 1px solid ${p => p.theme.color.background.variant};
  background-color: ${p => p.theme.color.background.on};
  padding: 16px;
`;

SolidarityBanner.Text = styled.div`
  text-align: left;
  color: white;
  font-weight: ${p => p.theme.fontWeight.light};
  font-size: 12px;
  margin-right: 26px;

  @media screen and (min-width: 540px) {
    text-align: center;
    font-size: 14px;
  }
`;

SolidarityBanner.LearnLink = styled.a`
  color: ${p => p.theme.color.brandPrimary.main};
  text-transform: uppercase;
  text-decoration: underline;
`;

SolidarityBanner.Close = styled.a`
  position: absolute;
  padding: 16px;
  right: 0;
  top: 0;

  svg {
    cursor: pointer;
    display: block;
    fill: white;
    width: 10px;
    height: 10px;
  }
`;

export default SolidarityBanner;
