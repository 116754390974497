import React from 'react';
import PropTypes from 'prop-types';
import styled, {css, ThemeProvider} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {zIndex} from '@/style';
import {
  AuthenticationModal,
  Link,
  PageHeaderLogo,
  StickyNavLoggedIn,
  StickyNavSearch,
  StickyNavSignUp,
  TextLabel,
  ToastManager,
} from '@/components';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useDistanceAbove, useSubnavigationItems} from '@/hooks';
import {stickyNavTheme} from '@/style/theme';

const StickyNav = ({theme, children}) => {
  const {t} = useTranslation();
  const {loggedIn, links} = useSubnavigationItems();
  const siteLinksVisible = useSelector(state => get(state.stickyNav, 'siteLinksVisible', true));
  const distanceAboveStickyNav = useDistanceAbove('stickyNav');

  const siteLinks = (
    <>
      {links.map(({label, action, useLabelTranslation = true}) =>
        <StickyNav.SiteLink
          key={label}
          href={action}
          target={action.startsWith('http') ? '_blank' : null}
          rel="noopener"
          underline={false}
          showDownArrowForAnchor={false}
        >
          <TextLabel size="smallReading" fontWeight="light" textTransform="capitalize">
            {useLabelTranslation ? t(`page_header.section.${label}`) : label}
          </TextLabel>
        </StickyNav.SiteLink>
      )}
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <StickyNav.Container id="sticky-nav" distanceAboveStickyNav={distanceAboveStickyNav}>
        <ToastManager />
        <StickyNav.Left>
          <StickyNav.Logo />
          <StickyNav.Subnavigation>
            {(!siteLinksVisible && children) ? children : siteLinks}
          </StickyNav.Subnavigation>
        </StickyNav.Left>
        <StickyNav.Right>
          {loggedIn ?
            <StickyNav.Inboxes>
              <StickyNavLoggedIn />
            </StickyNav.Inboxes> :
            <StickyNav.AuthTextButton />
          }
          <StickyNavSearch />
        </StickyNav.Right>
      </StickyNav.Container>
      <AuthenticationModal />
    </ThemeProvider>
  );
};

StickyNav.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node,
};

StickyNav.defaultProps = {
  theme: stickyNavTheme,
};

export default React.memo(StickyNav);

StickyNav.Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${p => p.theme.fontWeight.light};
  font-size: ${p => p.theme.fontSize.smallReading};
  height: ${p => p.theme.stickyNav.height.desktop};
  background-color: ${p => p.theme.color.background.main};
  color: ${p => p.theme.color.background.on};
  position: sticky;
  top: ${p => `calc(${p.distanceAboveStickyNav})`};
  ${zIndex('stickyNav')};
`;

StickyNav.Left = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${p => p.theme.space.full};
`;

StickyNav.Right = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: ${p => p.theme.space.full};
`;

StickyNav.Inboxes = styled.div`
  margin-right: ${p => p.theme.space.xLarge};
`;

const stickyNavLinkButtonStyle = css`
  margin-right: ${p => p.theme.space.xLarge};
  line-height: 1.33;
  color: ${p => p.theme.color.background.on};

  span {
    white-space: nowrap;
    color: ${p => p.theme.color.background.on};
  }

  &:hover {
    text-decoration: underline;
  }
`;

StickyNav.SiteLink = styled(props => <Link {...props} />)`
  ${stickyNavLinkButtonStyle}
`;

StickyNav.AuthTextButton = styled(props => <StickyNavSignUp {...props} />)`
  ${stickyNavLinkButtonStyle}
`;

StickyNav.Subnavigation = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${p => p.theme.space.xxLarge};
`;

StickyNav.Logo = styled(props => <PageHeaderLogo {...props} />)`
  svg {
    height: 13px;
  }
`;
