import merge from 'lodash/merge';
import {isBlack} from '@/util/style';
import {lightTheme, light} from './theme';

export const songPageTheme = merge({}, lightTheme, {
  name: 'songPage',
  color: {
    unreviewedReferent: {
      main: '#fa7878',
      on: '#000',
    },
    unreviewedReferentHover: {
      main: '#ff6161',
      on: '#000',
    },
  },
  widths: {
    extraSmallBreakpoint: '360px',
  },
  borderRadius: '1.25rem',
  fontSize: {
    xxSmallReading: '.5rem',
    xSmallReading: '.625rem',
    smallReading: '.75rem',
    xSmallHeadline: '1.25rem',
    medium: '2rem',
  },
  contributorStickyNav: {
    height: '3.625rem',
  },
  space: {
    xxxLarge: '3rem',
  },
  lineHeight: {
    reading: '1.5',
  },
  angularFullSpacePixel: '16px',
  appleMusicPlayer: {
    desktopHeight: '91px',
  },
});

export const songPageLight = (coverArtColors = {gradient: {}}) => (merge({}, songPageTheme, {
  name: 'songPageLight',
  baseColors: coverArtColors,
  color: {
    referent: coverArtColors.gradient,
  },
}));

export const songPageGradient = theme => merge({}, theme, {
  name: 'songPageGradient',
  color: {
    background: theme.baseColors.gradient,
    cosign: {
      main: theme.baseColors.gradient.on,
      variant: 'transparent',
      on: isBlack(theme.baseColors.gradient.on) ? '#fff' : '#000',
    },
  },
});

export const songPageHeader = theme => merge({}, theme, {
  name: 'songPageHeader',
  color: {
    background: theme.baseColors.gradient,
    primary: light,
    lyricsBackground: theme.baseColors.gradient,
  },
});

export const brandColorBackground = () => merge({}, songPageTheme, {
  name: 'brandColorBackground',
  color: {background: songPageTheme.color.primary},
});

export const songPageQuestionHeader = isExpanded => theme => isExpanded ? merge({}, theme, {
  color: {
    background: {
      main: theme.color.background.on,
      on: theme.color.background.main,
    },
  },
}) : theme;
