import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled, {ThemeProvider} from 'styled-components';

import {PageHeaderDropdownMenu} from '@/components/styleAnchors';
import {zIndex} from '@/style';
import {lightTheme} from '@/style/theme';

const widthsPerSize = {
  large: '550px',
  medium: '370px',
  narrow: '210px',
};

const PageHeaderDropdown = ({
  children,
  isOpen,
  width,
  align = 'right',
}) => {
  const container = useRef(null);
  return isOpen ? (
    <ThemeProvider theme={lightTheme}>
      <PageHeaderDropdown.Container ref={container} {...{width, align}}>
        {children}
      </PageHeaderDropdown.Container>
    </ThemeProvider>
  ) : null;
};

export default React.memo(PageHeaderDropdown);

PageHeaderDropdown.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  align: PropTypes.string,
};

PageHeaderDropdown.Container = styled(PageHeaderDropdownMenu)`
  background-color: ${p => p.theme.color.background.main};
  overflow: hidden;
  ${zIndex('headerDropdown')}
  position: absolute;
  ${p => `${p.align || 'right'}: ${p.theme.space.full}`};
  box-shadow: ${p => p.theme.boxShadow.standard};
  top: ${p => `calc(${p.theme.header.height} + ${p.theme.space.full})`};
  max-height: ${p => `calc(100vh - ${p.theme.header.height} - ${p.theme.space.xxxLarge})`};
  width: ${p => widthsPerSize[p.width]};
`;
