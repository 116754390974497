import Cookies from 'js-cookie';
import {AppConfig} from '@/config';
import {ApiError, post} from '@/api/client';


export const logout = async () => {
  const response = await fetch(`https://${AppConfig.canonicalDomain}/logout`, {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
  });
  if (response.status >= 400) {
    throw new ApiError(response.statusText, response.status);
  }
};

export const impersonateSession = async (login) => {
  const response = await fetch(`https://${AppConfig.canonicalDomain}/users/impersonate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
    body: JSON.stringify({login}),
  });
  if (response.status >= 400) {
    throw new ApiError(response.statusText, response.status);
  }
};

export const signIn = async user => await post('/sessions', {user});

export const signUp = async user => await post('/users', {user});
