import PropTypes from 'prop-types';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import styled from 'styled-components';

import NewTab from '@/svg/newTab.svg';
import {useMixpanelLinkEvent} from '@/hooks';

const QuestionAnswerList = ({mixpanelPagePrefix, questionIds, questionTextTag}) => {
  const questionAnswers = useSelector(state => questionIds.map(id => state.entities.questionAnswers[id]), shallowEqual);

  const trackClickQuestionPage = useMixpanelLinkEvent(`${mixpanelPagePrefix}:click_question_page`);
  const handleClickQuestionPage = (event, questionAnswerId) => {
    const [slug, questionableType, questionableId] = questionAnswerId.split(':');
    trackClickQuestionPage(event, {
      'related_question_slug': slug,
      'related_questionable_type': questionableType,
      'related_questionable_id': questionableId,
    });
  };

  return (
    <>
      {questionAnswers.map(questionAnswer => (
        <QuestionAnswerList.ItemContainer
          key={questionAnswer.id}
          href={questionAnswer.path}
          onClick={event => handleClickQuestionPage(event, questionAnswer.id)}
        >
          <QuestionAnswerList.ItemQuestion as={questionTextTag} dangerouslySetInnerHTML={{__html: questionAnswer.question}} />
          <QuestionAnswerList.Icon />
        </QuestionAnswerList.ItemContainer>
      ))}
    </>
  );
};

export default React.memo(QuestionAnswerList);

QuestionAnswerList.propTypes = {
  mixpanelPagePrefix: PropTypes.string.isRequired,
  questionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  questionTextTag: PropTypes.string,
};

QuestionAnswerList.ItemContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${p => p.theme.space.full};
  color: ${p => p.theme.color.background.on};
  cursor: pointer;
  padding: ${p => p.theme.space.small};
  border: 1px solid ${p => p.theme.color.background.blockOnColor};

  &:not(:last-child) {
    border-bottom: none;
  }
`;

QuestionAnswerList.ItemQuestion = styled.div`
  font-size: ${p => p.theme.fontSize.reading};
  font-weight: ${p => p.theme.fontWeight.light};
  line-height: ${p => p.theme.lineHeight.medium};
`;

QuestionAnswerList.Icon = styled(props => <NewTab {...props} />)`
  flex-shrink: 0;
  height: .75rem;
`;
