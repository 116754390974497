import {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {mixpanelEventTriggered} from '@/actions';
import isEqual from 'lodash/isEqual';

const RIGHT_CLICK_EVENT = 2;
const MIXPANEL_TRACK_LINK_TIMEOUT = 300;

export default (eventName, eventProps) => {
  const dispatch = useDispatch();
  const eventPropsRef = useRef(eventProps);

  let eventPropsForCallback = eventPropsRef.current;
  if (!isEqual(eventPropsRef.current, eventProps)) {
    eventPropsRef.current = eventProps;
    eventPropsForCallback = eventProps;
  }

  return useCallback((event, additionalEventProps) => {
    const anchorEl = event.target.closest('a');
    const openInNewTab = event.which === RIGHT_CLICK_EVENT ||
      event.metaKey ||
      event.ctrlKey ||
      anchorEl.getAttribute('target') === '_blank';

    if (openInNewTab) {
      dispatch(mixpanelEventTriggered(eventName, {...eventPropsForCallback, ...additionalEventProps}));
      return;
    }
    event.preventDefault();

    const destination = anchorEl.getAttribute('href');
    const goToDestination = () => window.location.href = destination;
    const timeoutId = setTimeout(goToDestination, MIXPANEL_TRACK_LINK_TIMEOUT);
    const done = () => {
      clearTimeout(timeoutId);
      goToDestination();
    };

    dispatch(mixpanelEventTriggered(eventName, {...eventPropsForCallback, ...additionalEventProps}, done));
  }, [dispatch, eventName, eventPropsForCallback]);
};
