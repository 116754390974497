import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useEntity, useIq, useMixpanelEvent} from '@/hooks';
import {can} from '@/util';
import {acceptAnnotation} from '@/songPage/actions';

export default (id, isDescription, dispatcher = 'song') => {
  const dispatch = useDispatch();
  const trackingPrefix = isDescription ? 'description_annotation' : 'annotation';
  const trackAccept = useMixpanelEvent(`${dispatcher}:unreviewed_${trackingPrefix}_accepted`, {annotation_id: id});

  const [acceptError, setAcceptError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const annotation = useEntity('annotations', id);

  const accept = useCallback(() => {
    setIsSubmitting(true);
    dispatch(acceptAnnotation(id, {
      onSuccess: () => {
        setIsSubmitting(false);
        trackAccept();
      },
      onError: (e) => {
        setAcceptError(e.message);
        setIsSubmitting(false);
      },
    }));
  }, [id, dispatch, trackAccept]);

  return {
    actions: {
      accept,
    },
    iqPoints: {
      accept: useIq('accept', annotation),
      reject: useIq('reject', annotation),
    },
    can: {
      accept: can('accept', annotation),
      reject: can('reject', annotation),
    },
    state: {
      acceptError,
      isSubmitting,
    },
  };
};
