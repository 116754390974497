import React from 'react';
import styled, {keyframes} from 'styled-components';
import {Alert} from '@/components';
import PropTypes from 'prop-types';

const Toast = ({id, status, message, onClose, timeout}) => (

  <Toast.Container key={id} timeout={timeout}>
    <Alert
      status={status}
      message={message}
      onClose={onClose}
    />
  </Toast.Container>
);

Toast.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number.isRequired,
};

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

Toast.Container = styled.div`
  animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s ${p => p.timeout}s;
  margin-bottom: ${p => p.theme.space.small};
  background-color: ${p => p.theme.color.background.on};
`;

export default Toast;
