import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import iFrameResize from 'iFrameResize';
import styled from 'styled-components';
import {PlaceholderSpinner} from '@/components';
import PropTypes from 'prop-types';

const PlaceholderSpinnerIframe = React.forwardRef(({onLoad, src}, ref) => {
  const element = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const iframe = element.current;
    iFrameResize({}, iframe);
    const iframeLoaded = () => {
      setLoaded(true);
      onLoad();
    };
    iframe.addEventListener('load', iframeLoaded);
    return () => iframe.removeEventListener('load', iframeLoaded);
  }, [onLoad, ref]);

  useImperativeHandle(ref, () => ({
    getIframe: () => element.current,
  }));

  return (
    <>
      {loaded ||
        <PlaceholderSpinnerIframe.Placeholder>
          <PlaceholderSpinner />
        </PlaceholderSpinnerIframe.Placeholder>
      }
      <PlaceholderSpinnerIframe.Iframe
        hide={!loaded}
        src={src}
        frameBorder="0"
        ref={element}
      />
    </>
  );
});

PlaceholderSpinnerIframe.displayName = 'PlaceholderSpinnerIframe';

PlaceholderSpinnerIframe.defaultProps = {
  onLoad: () => {},
};

PlaceholderSpinnerIframe.propTypes = {
  onLoad: PropTypes.func,
  src: PropTypes.string.isRequired,
};

export default React.memo(PlaceholderSpinnerIframe);

PlaceholderSpinnerIframe.Iframe = styled.iframe`
  display: ${p => p.hide ? 'none' : 'block'};
  width: 100%;
`;

PlaceholderSpinnerIframe.Placeholder = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
