import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkmark from '@/svg/checkmark.svg';
import {overflowWithEllipsis} from '@/style';

const SquareSelectOption = ({text, selected}) =>
  <SquareSelectOption.Container>
    {text}
    {selected && <Checkmark />}
  </SquareSelectOption.Container>;

SquareSelectOption.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default React.memo(SquareSelectOption);

SquareSelectOption.Container = styled.div`
  ${overflowWithEllipsis}
  font-size: ${p => p.theme.fontSize.smallReading};
  font-weight: 100;
  position: relative;
  padding: ${p => p.theme.space.small} ${p => `calc(${p.theme.space.xLarge} + ${p.theme.space.full})`} ${p => p.theme.space.small} ${p => p.theme.space.full};

  svg {
    fill: ${p => p.theme.color.background.on};
    position: absolute;
    top: calc(${p => p.theme.space.small} + 1px);
    right: ${p => p.theme.space.small};
    width: ${p => p.theme.fontSize.xSmallReading};
    height: ${p => p.theme.fontSize.xSmallReading};
  }
`;
