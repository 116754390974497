import {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {mixpanelEventTriggered} from '@/actions';
import isEqual from 'lodash/isEqual';

export default (eventName, eventProps) => {
  const dispatch = useDispatch();
  const eventPropsRef = useRef(eventProps);

  let eventPropsForCallback = eventPropsRef.current;
  if (!isEqual(eventPropsRef.current, eventProps)) {
    eventPropsRef.current = eventProps;
    eventPropsForCallback = eventProps;
  }

  return useCallback(additionalEventProps => (
    dispatch(mixpanelEventTriggered(eventName, {...eventPropsForCallback, ...additionalEventProps}))
  ), [dispatch, eventName, eventPropsForCallback]);
};
