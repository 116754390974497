import {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {annotationOpenedFromPermalink} from '@/songPage/actions';

export default () => {
  const dispatch = useDispatch();
  const scrollToFragment = useSelector(state => state.songPage.scrollToFragment);

  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referentId = params.get('referent_id');
    const permalinkedFragment = `${referentId}:1`;

    if (referentId && !scrollToFragment) {
      dispatch(annotationOpenedFromPermalink({
        baseAnnotation: referentId,
        fragment: permalinkedFragment,
      }));
    }
  }, [scrollToFragment, dispatch]);
};

