export const CHART_ITEM_STATE_REQUESTED = 'songPage/CHART_ITEM_STATE_REQUESTED';
export const chartItemStateRequested = ({chartType, id, onDone}) => ({
  type: CHART_ITEM_STATE_REQUESTED,
  payload: {
    chartType,
    id,
    onDone,
  },
});

export const CHART_ITEM_STATE_FETCHED = 'songPage/CHART_ITEM_STATE_FETCHED';
export const chartItemStateFetched = ({chartType, id, excluded}) => ({
  type: CHART_ITEM_STATE_FETCHED,
  payload: {
    chartType,
    id,
    excluded,
  },
});

export const CHART_ITEM_STATE_FAILED = 'songPage/CHART_ITEM_STATE_FAILED';
export const chartItemStateFailed = ({chartType, id, error}) => ({
  type: CHART_ITEM_STATE_FAILED,
  payload: {
    chartType,
    id,
    error,
  },
});

export const CHART_ITEM_STATE_TOGGLE_REQUESTED = 'songPage/CHART_ITEM_STATE_TOGGLE_REQUESTED';
export const chartItemStateToggleRequested = ({chartType, id, onDone}) => ({
  type: CHART_ITEM_STATE_TOGGLE_REQUESTED,
  payload: {
    chartType,
    id,
  },
  meta: {
    onDone,
  },
});

export const CHART_ITEM_STATE_TOGGLE_FAILED = 'songPage/CHART_ITEM_STATE_TOGGLE_FAILED';
export const chartItemStateToggleFailed = ({chartType, id, error}) => ({
  type: CHART_ITEM_STATE_TOGGLE_FAILED,
  payload: {
    chartType,
    id,
    error,
  },
});

export const CHART_ITEM_STATE_TOGGLED = 'songPage/CHART_ITEM_STATE_TOGGLED';
export const chartItemStateToggled = ({result, entities}) => ({
  type: CHART_ITEM_STATE_TOGGLED,
  payload: {
    result,
    entities,
  },
});
