import React, {useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import MagnifyingGlass from '@/svg/magnifyingGlass.svg';
import {PageHeaderDropdown, PageHeaderSearchResults} from '@/components';
import {PageHeaderDropdownMenu} from '@/components/styleAnchors';
import {useHeaderItem, useOnClickOutside, useDebouncedCallback, useMixpanelEvent} from '@/hooks';

const StickyNavSearch = () => {
  const {isOpen, open, close} = useHeaderItem('search');

  const {t} = useTranslation();
  const [query, setQuery] = useState('');
  const [isFocused, setFocused] = useState(false);
  const formElement = useRef(null);
  const inputElement = useRef(null);
  const trackSearchFocus = useMixpanelEvent('song:search_focused');
  const trackSearchCancel = useMixpanelEvent('song:search_canceled');

  const debouncedSetQuery = useDebouncedCallback({
    callback: searchText => setQuery(searchText),
    delay: 500,
    dependencies: [],
  });

  const onClick = useCallback((event) => {
    event.preventDefault();
    if (inputElement.current.value.trim()) {
      formElement.current.submit();
    }
  }, []);

  const onChange = useCallback(() => {
    const searchInputText = inputElement.current.value.trim();
    if (searchInputText) {
      isOpen || open();
      debouncedSetQuery(searchInputText);
    } else {
      close();
    }
  }, [close, open, isOpen, debouncedSetQuery]);

  const onFocus = useCallback(() => {
    if (!isFocused) {
      trackSearchFocus();
      setFocused(true);
    }
    if (inputElement.current.value.trim()) {
      isOpen || open();
    }
  }, [isOpen, open, isFocused, trackSearchFocus]);

  const onClickOutside = useCallback(() => {
    if (isFocused) {
      trackSearchCancel();
      setFocused(false);
    }
    if (isOpen) {
      close();
    }
  }, [isOpen, close, isFocused, trackSearchCancel]);
  useOnClickOutside({element: formElement, onClickOutside});

  return (
    <StickyNavSearch.Form ref={formElement} action="/search" method="get">
      <StickyNavSearch.Input
        onChange={onChange}
        onFocus={onFocus}
        name="q"
        placeholder={t('page_header.search_bar.placeholder')}
        ref={inputElement}
        autoComplete="off"
        required
      />
      <PageHeaderDropdown
        width="medium"
        isOpen={isOpen}
      >
        <PageHeaderSearchResults query={query} />
      </PageHeaderDropdown>
      <StickyNavSearch.Icon onClick={onClick}>
        <MagnifyingGlass />
      </StickyNavSearch.Icon>
    </StickyNavSearch.Form>
  );
};

StickyNavSearch.propTypes = {};

export default React.memo(StickyNavSearch);

StickyNavSearch.Form = styled.form`
  display: flex;
  background: ${p => p.theme.color.primary.main};
  align-items: center;
  width: ${p => `calc(10 * ${p.theme.space.full} + ${p.theme.space.small})`};
  height: ${p => p.theme.space.xLarge};

  ${PageHeaderDropdownMenu} {
    right: 0;
  }
`;

StickyNavSearch.Icon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0 ${p => p.theme.space.small};

  svg {
    fill: ${p => p.theme.color.primary.on};
    width: .625rem;
  }
`;

StickyNavSearch.Input = styled.input`
  flex-grow: 1;
  position: relative;
  border: none;
  border-radius: 0;
  background: ${p => p.theme.color.primary.main};
  color: ${p => p.theme.color.primary.on};
  font-family: ${p => p.theme.font.accent};
  font-feature-settings: ${p => p.theme.font.programmeAlternates};
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  overflow: hidden;
  resize: none;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${p => p.theme.color.primary.on};
    font-size: ${p => p.theme.fontSize.smallReading};
  };
  ::-ms-input-placeholder {
    color: ${p => p.theme.color.primary.on};
    font-size: ${p => p.theme.fontSize.smallReading};
  };
  &:focus::placeholder {
    color: ${p => p.theme.color.primary.onVariant};
    font-size: ${p => p.theme.fontSize.smallReading};
  };
  padding-left: ${p => p.theme.space.small};
`;
