import React, {forwardRef, useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {TextButton} from '@/components';
import {useTranslation} from 'react-i18next';

const CalendarToggle = forwardRef(({onToggleClick, onClick, isOpen, ...props}, _ref) => {
  const {t} = useTranslation();

  const handleClick = useCallback((e) => {
    onClick(e);

    if (onToggleClick) {
      onToggleClick(!isOpen);
    }
  }, [isOpen, onClick, onToggleClick]);

  return (
    <TextButton {...props} onClick={handleClick} textDecoration="underline">
      <CalendarToggle.Text>{isOpen ? t('date_input.hide') : t('date_input.show')}</CalendarToggle.Text>
    </TextButton>
  );
});

CalendarToggle.displayName = 'CalendarToggle';

CalendarToggle.propTypes = {
  onClick: PropTypes.func,
  onToggleClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CalendarToggle;

CalendarToggle.Text = styled.span`
  white-space: nowrap;
`;
