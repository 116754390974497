import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioButtons = React.forwardRef(({options, name, hasError, className, value, ...props}, ref) => (
  <RadioButtons.Container className={className}>
    {options.map(({key, value: optionValue, label}) => (
      <RadioButtons.Label key={key} htmlFor={`${name}_${key}`}>
        <RadioButtons.RadioInput
          {...props}
          id={`${name}_${key}`}
          type="radio"
          name={name}
          checked={(optionValue || key) === value}
          value={optionValue || key}
          ref={ref}
        />
        <RadioButtons.Button hasError={hasError} />
        <span>{label}</span>
      </RadioButtons.Label>
    ))}
  </RadioButtons.Container>
));

RadioButtons.displayName = 'ForwardRef(RadioButtons)';

export default RadioButtons;

RadioButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  className: PropTypes.string,
};

RadioButtons.Container = styled.div`
  margin-top: ${p => p.theme.space.half};
`;

RadioButtons.Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: 100;
  margin-bottom: ${p => p.theme.space.half};
`;

RadioButtons.RadioInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

RadioButtons.Button = styled.div`
  display: flex;
  margin-right: ${p => p.theme.space.half};
  border: 1px solid ${p => p.hasError ? p.theme.color.accent.main : p.theme.color.background.on};
  border-radius: 100%;
  height: 1.25rem;
  width: 1.25rem;

  input[type="radio"]:checked ~ & {
    &::before {
      background-color: ${p => p.theme.color.background.on};
    }
  }

  &::before {
    content: '';
    border-radius: 100%;
    height: 60%;
    width: 60%;
    margin: auto;
  }
`;
