import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {AppConfig} from '@/config';

import Wordmark from '@/svg/wordmark.svg';

const PageHeaderLogo = ({className}) => (
  <PageHeaderLogo.Link
    href={`https://${AppConfig.canonicalDomain}`}
    className={className}
  >
    <Wordmark />
  </PageHeaderLogo.Link>);

export default React.memo(PageHeaderLogo);

PageHeaderLogo.propTypes = {
  className: PropTypes.string,
};

PageHeaderLogo.Link = styled.a`
  svg {
    display: block;
    fill: ${p => p.theme.color.background.on};
  }
`;
