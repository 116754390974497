import Cookies from 'js-cookie';
import {AppConfig} from '@/config';
import {ApiError} from './client';

export const removeAsset = async (url) => {
  const response = await fetch(`https://${AppConfig.canonicalDomain}/assets/remove`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
    body: JSON.stringify({asset_url: url}),
  });
  if (response.status >= 400) {
    throw new ApiError(response.statusText, response.status);
  }
};
