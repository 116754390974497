import React from 'react';
import styled from 'styled-components';
import SocialLinks from './SocialLinks';
import {useTranslation} from 'react-i18next';

const PageFooterSocial = () => {
  const {t} = useTranslation();
  return (
    <>
      <PageFooterSocial.Slogan>
        {t('page_footer.section.description')}
      </PageFooterSocial.Slogan>
      <SocialLinks ctx="footer" />
    </>
  );
};

export default React.memo(PageFooterSocial);

PageFooterSocial.Slogan = styled.h1`
  font-size: ${p => p.theme.fontSize.headline};
  font-weight: 100;
  line-height: ${p => p.theme.lineHeight.short};
  margin-bottom: ${p => p.theme.space.xxLarge};
`;
