import styled from 'styled-components';

export const FieldContainer = styled.div``;

export const FieldControlWithLabel = styled.div`
  ${p => p.inlineLabel && `
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;

    ${FieldLabel} {
      margin-left: ${p.theme.space.full};
      margin-bottom: 0;
    }
  `}
`;

export const FieldLabel = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.space.quarter};
  font-weight: ${p => p.theme.fontWeight.light};
  font-size: ${p => p.theme.fontSize.reading};
`;

export const FieldError = styled.div`
  padding: ${p => p.theme.space.quarter} ${p => p.theme.space.half};
  font-size: ${p => p.theme.fontSize.smallReading};
  background-color: ${p => p.theme.color.accent.main};
  color: white;
  font-weight: 100;
`;

export const FieldHelpText = styled.div`
  color: ${p => p.theme.color.background.onVariant};
  font-size: ${p => p.theme.fontSize.smallReading};
  margin-top: ${p => p.theme.space.xSmall};
`;
