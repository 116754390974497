import {get} from '@/api/client';
import {contributorsSchema, normalize} from '@/normalize';

export const getContributors = async (entity, id) => {
  const response = await get(`/${entity}/${id}/contributors`);
  return normalize(response, contributorsSchema);
};

export const getIqEarners = async (entity, id, {page = 1}) => {
  const response = await get(`/${entity}/${id}/iq_earners`, {
    page,
  });
  // API outputs the same resultant shape as /contributors, just excludes transcribers
  return normalize(response, contributorsSchema);
};
