import {
  useSidebarLyricsTracker,
  useSidebarMetadataTracker,
  useSidebarBioTracker,
  useSidebarAnnotationsTracker,
} from '@/songPage/hooks';

const useSidebarCompletionTracker = (songId) => {
  const bioCompleted = useSidebarBioTracker(songId);
  const {lyricsCompleted, lyricsExist} = useSidebarLyricsTracker(songId);
  const {pendingMetadataCount} = useSidebarMetadataTracker(songId);

  let totalPendingCount = useSidebarAnnotationsTracker();
  totalPendingCount += pendingMetadataCount;
  totalPendingCount += Number(!bioCompleted);
  totalPendingCount += Number(!lyricsCompleted);
  totalPendingCount += Number(!lyricsExist);

  return totalPendingCount;
};

export default useSidebarCompletionTracker;
