import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {toastAdded} from '@/actions';

export default (status, message) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(toastAdded({status, message}));
  }, [dispatch, status, message]);
};
