import {useSelector} from 'react-redux';
import bowser from 'bowser';

import {getCloudflareMetadata} from '@/util/cloudflare';
import {useAbTestCohort} from '@/hooks';
import {AppConfig} from '@/config';
import {isClient} from '@/util';

const useTonefuseInterstitialExperiment = () => {
  const showAds = useSelector(state => state.session.showAds);
  const {country} = getCloudflareMetadata();

  const abTestCohort = useAbTestCohort('_genius_ab_test_tonefuse_interstitial_cohort');

  if (!isClient) {
    return null;
  }

  const songPageQueryParams = new URLSearchParams(window.location.search);
  const tonefuseTestQueryParam = songPageQueryParams.get('x-ab-test-tonefuse_interstitial');

  if (tonefuseTestQueryParam !== null) {
    return tonefuseTestQueryParam || 'tonefuse';
  }

  const isElegible = showAds && bowser.ios && country !== 'US' && AppConfig.tonefuseInterstitialThreshold > abTestCohort;

  if (!isElegible) {
    return null;
  }

  return abTestCohort % 2 ? 'tonefuse' : 'control' ;
};

export default useTonefuseInterstitialExperiment;
