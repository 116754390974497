import {createEntityActions} from '@/songPage/actions/entities';

export const answerEntity = createEntityActions('answers');

export const ANSWER_SUBMITTED = 'songPage/ANSWER_SUBMITTED';
export const answerSubmitted = (data, callbacks) => ({
  type: ANSWER_SUBMITTED,
  payload: data,
  meta: callbacks,
});

export const ANSWER_EDITED = 'songPage/ANSWER_EDITED';
export const answerEdited = (data, callbacks) => ({
  type: ANSWER_EDITED,
  payload: data,
  meta: callbacks,
});

export const ANSWER_CLEARED = 'songPage/ANSWER_CLEARED';
export const answerCleared = ({answerId, questionId}, callbacks) => ({
  type: ANSWER_CLEARED,
  answerId,
  questionId,
  meta: callbacks,
});
