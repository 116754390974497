import {useEffect} from 'react';
import {camelizeKeys} from '@/util';
import once from 'lodash/once';
import {AppConfig} from '@/config';

const getPusher = once(
  () => import('pusher-js').
    then(({default: Pusher}) => new Pusher(AppConfig.pusherAppKey))
);

export default ({channelName, eventName, callback, disabled = false}) =>
  useEffect(() => {
    let channel;
    let handler;

    if (disabled) return;

    getPusher().then((pusher) => {
      if (pusher === null) return;
      channel = pusher.channel(channelName) || pusher.subscribe(channelName);
      handler = payload => callback(camelizeKeys(payload));
      channel.bind(eventName, handler);
    });

    return () => channel ? channel.unbind(eventName, handler) : null;
  }, [callback, eventName, channelName, disabled]);
