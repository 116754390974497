import {useSelector} from 'react-redux';
import {getCloudflareMetadata} from '@/util/cloudflare';
import {AppConfig} from '@/config';
import {isClient} from '@/util';
import {useIsMobile, useAbTestCohort} from '@/hooks';

const IS_WINDOWS_PATTERN = /Windows NT/;

const useTonefusePlayerExperiment = () => {
  const showAds = useSelector(state => state.session.showAds);
  const isMobile = useIsMobile();

  const abTestCohort = useAbTestCohort('_genius_ab_test_tonefuse_cohort');

  if (!isClient || !showAds || isMobile) {
    return null;
  }

  const songPageQueryParams = new URLSearchParams(window.location.search);
  const tonefuseTestQueryParam = songPageQueryParams.get('x-ab-test-tonefuse_windows');
  if (tonefuseTestQueryParam !== null) {
    return tonefuseTestQueryParam || 'tonefuse';
  }

  const userAgent = navigator.userAgent;
  const isWindows = IS_WINDOWS_PATTERN.test(userAgent);
  if (!isWindows) {
    return null;
  }

  const {country} = getCloudflareMetadata();
  const isCountrySupported = AppConfig.tonefusePlayerOnWindowsEnabledCountries.includes(country);

  if (!isCountrySupported) {
    return null;
  }

  if (abTestCohort > AppConfig.tonefusePlayerOnWindowsThreshold) {
    return null;
  }

  return 'tonefuse';
};

export default useTonefusePlayerExperiment;
