import React from 'react';
import PropTypes from 'prop-types';
import {PlayButtonOverlay, SizedImage} from '@/components';

const EditorialImage = ({playButton, url, lazy}) =>
  <>
    {playButton ?
      <PlayButtonOverlay>
        <SizedImage
          src={url}
          aspectRatio={16 / 9}
          fitHeightAndRepeat={true}
          lazy={lazy}
        />
      </PlayButtonOverlay>
      :
      <SizedImage
        src={url}
        aspectRatio={16 / 9}
        fitHeightAndRepeat={true}
        lazy={lazy}
      />
    }
  </>;

EditorialImage.propTypes = {
  url: PropTypes.string.isRequired,
  playButton: PropTypes.bool.isRequired,
  lazy: PropTypes.bool,
};

export default React.memo(EditorialImage);
