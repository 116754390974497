import get from 'lodash/get';
import map from 'lodash/map';
import merge from 'lodash/merge';
import minBy from 'lodash/minBy';
import compact from 'lodash/compact';

import {ThirdPartyClient} from '@/api/thirdPartyClient';
import {AppConfig} from '@/config';

const MAP_DEAL_TO_CLIENT = {
  stubhub: new ThirdPartyClient(AppConfig.stubhubUrlProxy),
  viagogo: new ThirdPartyClient(AppConfig.viagogoUrlProxy),
};
const getCategoryEvents = async (stubhubDeal, categoryId, options = {}) => {
  const client = MAP_DEAL_TO_CLIENT[stubhubDeal];
  const {
    tryNextOnEmptyPage,
    customParams,
  } = options;
  const params = merge({
    page: 1,
    page_size: 100,
    max_distance_in_meters: 80467,
    exclude_parking_passes: true,
  }, customParams);

  const response = await client.get(`/catalog/categories/${categoryId}/allevents`, params);
  const data = await response.json();

  const hasNextPage = Boolean(get(data, '_links.next'));
  const events = get(data, '_embedded.items', []).filter(event => event.min_ticket_price?.display);
  if (!events.length && hasNextPage && tryNextOnEmptyPage) {
    return getCategoryEvents(
      stubhubDeal,
      categoryId,
      {
        customParams: merge(params, {page: params.page + 1}),
        tryNextOnEmptyPage: false,
      }
    );
  }
  return events;
};

export const getStubhubCategoryDetails = async (stubhubDeal, categoryId, params = {}) => {
  const events = await getCategoryEvents(
    stubhubDeal,
    categoryId,
    {customParams: params, tryNextOnEmptyPage: true}
  );
  if (!events.length) return;

  const venues = compact(map(events, '_embedded.venue'));
  const closestVenue = minBy(venues, venue => Math.hypot(venue.latitude - params.latitude, venue.longitude - params.longitude));

  const eventsInClosestCity = events.filter(event => get(event, '_embedded.venue.city') === closestVenue.city);
  const minPrice = minBy(map(eventsInClosestCity, 'min_ticket_price'), 'amount');

  return {
    city: closestVenue.city,
    minConcertTicketPriceForDisplay: minPrice.display.split('.')[0],
  };
};
