import {post, put, get, del} from './client';
import {camelizeKeys} from '@/util';
import {
  songSchema,
  lyricsDataSchema,
  normalize,
  pendingLyricsEditProposalsSchema,
  songRecommendationSchema,
  referentsSchema,
} from '@/normalize';

export const countView = song_id => post(`/songs/${song_id}/count_view`);

export const updateLyrics = async (songId, {html, updatedByHumanAt, lyricsUpdatedAt, lyricsEditId}) => {
  const params = {
    react: true,
    lyrics: {body: {html}},
    client_timestamps: {
      updated_by_human_at: updatedByHumanAt,
      lyrics_updated_at: lyricsUpdatedAt,
    },
  };
  if (lyricsEditId) {
    params['lyrics_edit_id'] = lyricsEditId;
  }

  const {react_lyrics_update} = await put(`/songs/${songId}/lyrics`, params);
  return normalize(react_lyrics_update, lyricsDataSchema);
};

export const getLyrics = async (songId) => {
  const {react_lyrics} = await get(`/songs/${songId}/lyrics`, {react: true});
  return normalize(react_lyrics, lyricsDataSchema);
};

export const loadSong = async (songId) => {
  const response = await get(`/songs/${songId}`);
  return normalize(response, {song: songSchema});
};

export const updateSong = async (songId, data) => {
  const response = await put(`/songs/${songId}`, data);
  return normalize(response, {song: songSchema});
};

export const getLyricsForEditProposal = async (songId) => {
  const {lyrics_for_edit_proposal} = await get(`/songs/${songId}/lyrics_for_edit_proposal`);
  return camelizeKeys(lyrics_for_edit_proposal);
};

export const createLyricsProposal = async (songId, {editedLyrics, version}) => {
  const {lyrics_edit} = await post(`/songs/${songId}/lyrics_edits`, {
    lyrics_edit: {
      edited_lyrics: editedLyrics,
      before_version_number: version,
      edit_format: 'plain_text',
    },
  });
  return camelizeKeys(lyrics_edit);
};

export const getPendingLyricsEditProposals = async (songId) => {
  const {lyrics_edits} = await get(`/songs/${songId}/lyrics_edits`, {song_id: songId});
  return normalize(lyrics_edits, pendingLyricsEditProposalsSchema);
};


export const getSongAutocomplete = async (searchTerm) => {
  const response = await get('/search/song', {q: searchTerm});
  return response.sections[0].hits;
};

export const rejectLyricsEditProposal = lyricsEditId => post(`/lyrics_edits/${lyricsEditId}/reject`);

export const performAdminAction = async (songId, action, data = {}) => {
  const {song} = await put(`/songs/${songId}/${action}`, data);
  return normalize(song, songSchema);
};

export const getPreviewForLyricsExport = async (songId) => {
  const {preview_lyrics_for_export} = await get(`/songs/${songId}/preview_lyrics_for_export`);
  return preview_lyrics_for_export;
};

export const deleteSong = async songId => await del(`/songs/${songId}`);

export const getRecommendations = async (songId) => {
  const {song_recommendations} = await get(`/songs/${songId}/recommendations`);
  return normalize(song_recommendations, songRecommendationSchema);
};

export const getTopUnreviewedAnnotations = async (songId) => {
  const response = await get(`/songs/${songId}/top_unreviewed_annotations`, {text_format: 'html,markdown,preview'});
  return normalize(response, referentsSchema);
};

export const followSong = id => post(`/songs/${id}/follow`);

export const unfollowSong = id => post(`/songs/${id}/unfollow`);
