import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import i18next from 'i18next';

const stripFingerprint = field => field.replace(/_fingerprint$/, '');

const formatErrorForField = (field, error) => error.startsWith('^') ?
  error.slice(1) :
  [upperFirst(lowerCase(i18next.t(`form.field_names.${field}`, field))), error].join(' ');

export const getFormattedErrorsByField = errors => Object.entries(errors).map(([field, error_list]) => {
  const fieldName = stripFingerprint(field);
  return [fieldName, formatErrorForField(fieldName, error_list[0])];
});

export const applyValidationErrors = (validationErrors, setError) => {
  getFormattedErrorsByField(validationErrors).forEach(([field, error]) => {
    setError(field, {type: 'server', message: error});
  });
};

export const dirtyValues = (dirtyFields, allValues) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};
