import {produce} from 'immer';
import {
  USER_LOGGED_OUT,
  USER_LOGGED_OUT_FAILED,
  IMPERSONATED_USER,
  IMPERSONATED_USER_FAILED,
  REMOVE_ASSET_REQUESTED,
  REMOVE_ASSET_SUCCEEDED,
  REMOVE_ASSET_FAILED,
} from '@/actions';

export default produce((draft, action) => {
  switch (action.type) {
  case USER_LOGGED_OUT:
    draft.logoutLoading = true;
    break;
  case IMPERSONATED_USER:
    draft.impersonateLoading = true;
    break;
  case REMOVE_ASSET_REQUESTED:
    draft.removeAssetLoading = true;
    break;
  case USER_LOGGED_OUT_FAILED:
    draft.logoutLoading = false;
    break;
  case IMPERSONATED_USER_FAILED:
    draft.impersonateLoading = false;
    break;
  case REMOVE_ASSET_SUCCEEDED:
    draft.removeAssetLoading = false;
    break;
  case REMOVE_ASSET_FAILED:
    draft.removeAssetLoading = false;
    break;
  default:
    return draft;
  }
});
