import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: ${p => `
    [page-start]
      ${p.theme.grid.outerMargin}
    [grid-start header-left-start]
      1fr
    [left-start]
      repeat(2, 1fr)
    [header-left-end header-right-start]
      repeat(4, 1fr)
    [left-end right-start]
      repeat(4, 1fr)
    [right-end]
      1fr
    [grid-end header-right-end]
      ${p.theme.grid.outerMargin}
    [page-end];
  `};

  @media screen and (min-width: ${p => p.theme.widths.fixedWidthBreakpoint}) {
    grid-template-columns: ${p => `
      [page-start]
        1fr
      [grid-start header-left-start]
        ${p.theme.grid.columnWidth}
      [left-start]
        repeat(2, ${p.theme.grid.columnWidth})
      [header-left-end header-right-start]
        repeat(4, ${p.theme.grid.columnWidth})
      [left-end right-start]
        repeat(4, ${p.theme.grid.columnWidth})
      [right-end]
        ${p.theme.grid.columnWidth}
      [grid-end header-right-end]
        1fr
      [page-end];
    `};
  }

  @media screen and (min-width: ${p => p.theme.widths.largeTextBreakpoint}) {
    grid-template-columns: ${p => `
      [page-start]
        1fr
      [grid-start header-left-start]
        ${p.theme.grid.largeColumnWidth}
      [left-start]
        repeat(2, ${p.theme.grid.largeColumnWidth})
      [header-left-end header-right-start]
        repeat(4, ${p.theme.grid.largeColumnWidth})
      [left-end right-start]
        repeat(4, ${p.theme.grid.largeColumnWidth})
      [right-end]
        ${p.theme.grid.largeColumnWidth}
      [grid-end header-right-end]
        1fr
      [page-end];
    `};
  }

  grid-gap: ${p => p.theme.grid.gap};
`;
