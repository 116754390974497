import {useEntity} from '@/hooks';
import {can} from '@/util';

const useSidebarLyricsTracker = (songId) => {
  const song = useEntity('songs', songId);
  const canMarkLyricsComplete = can('mark_lyrics_evaluation_as_complete', song);

  const {
    lyricsMarkedStaffApprovedBy,
    lyricsMarkedCompleteBy,
    lyricsPlaceholderReason,
    lyricsVerified,
  } = song;

  const lyricsExist = !Boolean(lyricsPlaceholderReason) || lyricsPlaceholderReason === 'instrumental';
  const lyricsCompleted = lyricsMarkedCompleteBy || lyricsMarkedStaffApprovedBy || (lyricsVerified && !canMarkLyricsComplete);

  return {
    lyricsExist,
    lyricsCompleted,
  };
};

export default useSidebarLyricsTracker;
