import {useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {modalToggled} from '@/actions';

import {zIndex} from '@/style';

export const useModalVisibility = (show) => {
  const [open, setOpen] = useState(false);
  const scrollYRef = useRef(0);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (show) {
      scrollYRef.current = window.scrollY;
    }
    setOpen(show);
    dispatch(modalToggled(show));
  }, [dispatch, show]);

  return {isOpen: open, scrollY: scrollYRef.current};
};

export const onAfterOpen = (scrollY) => {
  document.body.scrollTo(0, scrollY);
};

export const scrollAfterClose = (scrollY) => {
  window.scrollTo(0, scrollY);
  document.body.scrollTo(0, 0);
};

export const ModalSharedContainer = styled.div`
  ${zIndex('modalLower')}
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  overflow-y: auto;
`;

export const ModalSharedButtonLabel = styled.div`
  color: inherit;
  font-size: ${p => p.theme.fontSize.smallReading};
  text-align: center;
  margin-top: ${p => p.theme.space.quarter};
  color: white;
`;
