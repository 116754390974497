import {useEntity} from '@/hooks';
import get from 'lodash/get';
import find from 'lodash/find';
import {useSelector} from 'react-redux';

export default (defaultQuestionId) => {
  const question = useSelector(state => find(state.entities.questions, ['defaultQuestion.id', defaultQuestionId]));
  const answer = useEntity('answers', question?.answer);
  const unansweredDefaultQuestions = useSelector(state => get(state.songPage, 'unansweredDefaultQuestions', []));
  const isAnswered = (unansweredDefaultQuestions.indexOf(defaultQuestionId) < 0) && Boolean(answer);

  return {
    question,
    answer,
    isAnswered,
  };
};
