export const SITE_LINKS_VISIBILITY_TOGGLED = 'stickyNav/SITE_LINKS_VISIBILITY_TOGGLED';
export const siteLinksVisibilityToggled = visible => ({
  type: SITE_LINKS_VISIBILITY_TOGGLED,
  visible,
});

export const STICKY_LEADERBOARD_HEIGHT_SET = 'stickyNav/STICKY_LEADERBOARD_HEIGHT_SET';
export const stickyLeaderboardHeightSet = height => ({
  type: STICKY_LEADERBOARD_HEIGHT_SET,
  height,
});

export const STICKY_BANNER_AD_VISIBILITY_TOGGLED = 'stickyNav/STICKY_BANNER_AD_VISIBILITY_TOGGLED';
export const stickyBannerAdVisibilityToggled = visible => ({
  type: STICKY_BANNER_AD_VISIBILITY_TOGGLED,
  visible,
});

export const SECTION_VISIBILITY_CHANGED = 'stickyNav/SECTION_VISIBILITY_CHANGED';
export const sectionVisibilityChanged = (section, visible) => ({
  type: SECTION_VISIBILITY_CHANGED,
  section,
  visible,
});
