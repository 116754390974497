import get from 'lodash/get';
import {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default () => {
  const {i18n} = useTranslation();
  const refI18n = useRef(i18n);

  return useMemo(() => {
    const languageByLangCode = get(refI18n.current, `store.data.${refI18n.current.language}.translation.languages`, {});
    return Object.entries(languageByLangCode).
      map(([value, label]) => ({label, value})).
      reduce((options, option) => options.concat([option]), []);
  }, []);
};
