import React from 'react';
import {useIsMobile} from '@/hooks';

const withIsMobile = (WrappedComponent) => {
  const InjectIsMobile = (props) => {
    const isMobile = useIsMobile();
    return <WrappedComponent {...props} isMobile={isMobile} />;
  };
  InjectIsMobile.displayName = `withIsMobile(${WrappedComponent.displayName || WrappedComponent.name})`;
  return InjectIsMobile;
};

export default withIsMobile;
