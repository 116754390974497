import {useEffect} from 'react';

const useMutationObserverCallback = (targetSelector, callback) => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const parentElement = document.querySelector(targetSelector);
      if (parentElement) {
        callback();
        observer.disconnect();
      }
    });

    observer.observe(document.body, {childList: true});
    return () => {
      observer.disconnect();
    };
  }, [targetSelector, callback]);
};

export default useMutationObserverCallback;
