import {useMemo} from 'react';
import Cookies from 'js-cookie';

const useAbTestCohort = cookieName =>
  useMemo(() => {
    let cohortString = Cookies.get(cookieName);
    if (cohortString === undefined) {
      cohortString = Math.floor(Math.random() * 100).toString();
      Cookies.set(cookieName, cohortString);
    }
    return parseInt(cohortString, 10);
  }, [cookieName]);

export default useAbTestCohort;
