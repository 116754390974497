import {useCallback, useEffect, useState, useRef} from 'react';
import {shallowEqual} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {useIsMobile} from '@/hooks';
import {sliceUnicodeString} from '@/util';

export default (lyricsRootRef, disabled) => {
  const isMobile = useIsMobile();
  const [selectionRect, setSelectionRect] = useState(null);
  const isSelectedRef = useRef(false);
  const [lyricsHighlighted, setLyricsHighlighted] = useState(false);
  const rangeRef = useRef(null);

  const handler = useCallback(() => {
    const selection = window.getSelection();
    if (selection.isCollapsed) {
      isSelectedRef.current = false;
      if (isMobile) {
        setLyricsHighlighted(false);
        setSelectionRect(null);
      }
      return;
    }

    const range = selection.getRangeAt(0);
    rangeRef.current = range;

    if (!isRangeAnnotatable(range)) {
      isSelectedRef.current = false;
      if (isMobile) {
        setLyricsHighlighted(false);
        setSelectionRect(null);
      }
      return;
    }

    const newSelectionRect = range.getBoundingClientRect();
    setSelectionRect((oldRect) => {
      const newRect = roundValues({
        x: newSelectionRect.x,
        y: newSelectionRect.y + window.scrollY,
        height: newSelectionRect.height,
        width: newSelectionRect.width,
      });

      if (shallowEqual(oldRect, newRect)) {
        return oldRect;
      } else {
        return newRect;
      }
    });

    isSelectedRef.current = true;
    if (isMobile) {
      setLyricsHighlighted(true);
    }
  }, [isMobile]);

  const handleMouseEvent = useCallback(() => {
    if (isSelectedRef.current) {
      setLyricsHighlighted(true);
    } else {
      setLyricsHighlighted(false);
      setSelectionRect(null);
    }
  }, []);

  const getReferent = useCallback(() => {
    const range = rangeRef.current;
    const container = lyricsRootRef.current;
    const contents = range.cloneContents();
    return {
      text: contents.textContent,
      previousContext: sliceUnicodeString(getPreviousContext(range, container).replace(/\s+/g, '')),
      nextContext: sliceUnicodeString(getNextContext(range, container).replace(/\s+/g, '')),
    };
  }, [lyricsRootRef]);

  const clearSelection = useCallback(() => {
    isSelectedRef.current = false;
    setLyricsHighlighted(false);
    setSelectionRect(null);
    const selection = window.getSelection();
    selection.removeAllRanges();
  }, []);

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('selectionchange', handler);
      if (!isMobile) {
        document.addEventListener('pointerup', handleMouseEvent);
        document.addEventListener('pointerdown', handleMouseEvent);
      }

      return () => {
        document.removeEventListener('selectionchange', handler);
        if (!isMobile) {
          document.removeEventListener('pointerup', handleMouseEvent);
          document.removeEventListener('pointerdown', handleMouseEvent);
        }
      };
    }
  }, [disabled, isMobile, handler, handleMouseEvent]);

  return {
    selectionRect,
    lyricsHighlighted,
    getReferent,
    clearSelection,
  };
};

const isRangeAnnotatable = (range) => {
  const contents = range.cloneContents();
  return (
    getRangeElementAncestor(range).closest('[data-lyrics-container]') !== null &&
    contents.querySelectorAll('a, img, [data-exclude-from-selection]').length === 0 &&
    getNodeElementAncestor(range.startContainer).closest('a, img, [data-exclude-from-selection]') === null &&
    getNodeElementAncestor(range.endContainer).closest('a, img, [data-exclude-from-selection]') === null &&
    !isEmpty(contents.textContent)
  );
};

const getRangeElementAncestor = (range) => {
  let ancestor = range.commonAncestorContainer;
  while (ancestor.nodeType !== Node.ELEMENT_NODE) {
    ancestor = ancestor.parentNode;
  }
  return ancestor;
};

const getNodeElementAncestor = (node) => {
  let ancestor = node;
  while (ancestor.nodeType !== Node.ELEMENT_NODE) {
    ancestor = ancestor.parentNode;
  }
  return ancestor;
};

const roundValues = (_rect) => {
  const rect = {
    ..._rect,
  };
  for (const key of Object.keys(rect)) {
    rect[key] = Math.round(rect[key]);
  }
  return rect;
};

const getPreviousContext = (range, container) => {
  const previousRange = rangeFromElement(container);
  previousRange.setEnd(range.startContainer, range.startOffset);
  return filterRangeForContext(previousRange).toString();
};

const getNextContext = (range, container) => {
  const nextRange = rangeFromElement(container);
  nextRange.setStart(range.endContainer, range.endOffset);
  return filterRangeForContext(nextRange).toString();
};

const rangeFromElement = (element) => {
  const range = document.createRange();
  range.selectNodeContents(element);
  return range;
};

const filterRangeForContext = (range) => {
  const clonedContents = range.cloneContents();

  [...clonedContents.children].forEach((element) => {
    if (!element.dataset.lyricsContainer) {
      clonedContents.removeChild(element);
    }
  });

  [...clonedContents.querySelectorAll('[data-exclude-from-selection]')].forEach((element) => {
    element.parentElement.removeChild(element);
  });

  return rangeFromElement(clonedContents);
};
