import {get} from './client';
import {editorialPlacementSchema, normalize} from '@/normalize';

export const getLatestEditorialPlacements = async ({page = 1, perPage = 10}) => {
  const response = await get('/editorial_placements/latest', {
    page,
    per_page: perPage,
  });
  return normalize(response, {
    editorialPlacements: [editorialPlacementSchema],
  });
};
