import {useSelector} from 'react-redux';
import get from 'lodash/get';
import compact from 'lodash/compact';

import {useEntity} from '@/hooks';

export default (annotationId) => {
  const {
    topComment,
    comments: commentIds = compact([topComment]),
  } = useEntity('annotations', annotationId);

  return useSelector(state => commentIds.filter((id) => {
    const commentState = get(state, ['entities', 'comments', id, 'state']);
    return !commentState || commentState === 'pending';
  }));
};
