export const SONG_COMMENTS_VISIBLE = 'songPage/SONG_COMMENTS_VISIBLE';
export const songCommentsVisible = songId => ({
  type: SONG_COMMENTS_VISIBLE,
  songId,
});

export const SONG_INITIAL_COMMENTS_LOADED = 'songPage/SONG_INITIAL_COMMENTS_LOADED';
export const songInitialCommentsLoaded = () => ({
  type: SONG_INITIAL_COMMENTS_LOADED,
});

export const MORE_COMMENTS_CLICKED = 'songPage/MORE_COMMENTS_CLICKED';
export const moreCommentsClicked = (songId, page = 1) => ({
  type: MORE_COMMENTS_CLICKED,
  songId,
  page,
});

export const COMMENTS_FETCHED = 'songPage/COMMENTS_FETCHED';
export const commentsFetched = (songId, {result, entities}) => ({
  type: COMMENTS_FETCHED,
  songId,
  result,
  entities,
});

export const COMMENTS_FETCH_FAILED = 'songPage/COMMENTS_FETCH_FAILED';
export const commentsFetchFailed = songId => ({
  type: COMMENTS_FETCH_FAILED,
  songId,
});

export const COMMENT_SUBMITTED = 'songPage/COMMENT_SUBMITTED';
export const commentSubmitted = ({songId, body, name, email}, meta) => ({
  type: COMMENT_SUBMITTED,
  songId,
  body,
  name,
  email,
  meta,
});

export const COMMENT_SUBMIT_SUCCEEDED = 'songPage/COMMENT_SUBMIT_SUCCEEDED';
export const commentSubmitSucceeded = ({result, entities}) => ({
  type: COMMENT_SUBMIT_SUCCEEDED,
  result,
  entities,
});

export const COMMENT_DELETED = 'songPage/COMMENT_DELETED';
export const commentDeleted = ({commentId}, meta) => ({
  type: COMMENT_DELETED,
  commentId,
  meta,
});

export const COMMENT_DELETE_SUCCEEDED = 'songPage/COMMENT_DELETE_SUCCEEDED';
export const commentDeleteSucceeded = commentId => ({
  type: COMMENT_DELETE_SUCCEEDED,
  commentId,
});

export const COMMENT_MARKED_AS_SPAM = 'songPage/COMMENT_MARKED_AS_SPAM';
export const commentMarkedAsSpam = ({commentId}, meta) => ({
  type: COMMENT_MARKED_AS_SPAM,
  commentId,
  meta,
});

export const COMMENT_MARK_AS_SPAM_SUCCEEDED = 'songPage/COMMENT_MARK_AS_SPAM_SUCCEEDED';
export const commentMarkAsSpamSucceeded = commentId => ({
  type: COMMENT_MARK_AS_SPAM_SUCCEEDED,
  commentId,
});
