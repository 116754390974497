import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {InlineSvg, StyledLink} from '@/components';
import DownArrow from '@/svg/angledDownArrow.svg';

const PAGE_SECTIONS = ['#about', '#primary-album', '#song-info', '#lyrics', '#comments'];

const Link = ({children, href, lineHeight, newTab, onClick, showDownArrowForAnchor, ...remainingProps}) => {
  const attrs = {};
  if (newTab) {
    attrs.target = '_blank';
    attrs.rel = 'noopener noreferrer';
  }

  const handleClick = useCallback((evt) => {
    if (!PAGE_SECTIONS.includes(href)) {
      return;
    }

    evt.preventDefault();
    if (onClick) onClick();
    document.querySelector(href)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [href, onClick]);

  return (
    <StyledLink href={href} onClick={handleClick} {...attrs} {...remainingProps}>
      {children}
      {href?.startsWith('#') && showDownArrowForAnchor && (
        <>&nbsp;<InlineSvg aspectRatio={6.6 / 16} lineHeight={lineHeight} svg={DownArrow} /></>
      )}
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.element,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  href: PropTypes.string,
  lineHeight: PropTypes.number,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  showDownArrowForAnchor: PropTypes.bool,
  underline: PropTypes.bool,
  useBackgroundColor: PropTypes.bool,
};

Link.defaultProps = {
  lineHeight: 1.33,
  showDownArrowForAnchor: true,
  size: 'smallReading',
};

export default Link;
