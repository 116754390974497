import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {authModalOpened} from '@/actions';
import {Button, TextButton} from '@/components';
import {useMixpanelEvent} from '@/hooks';

const SignUpButton = ({source, textButton, className}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const trackSignUpClick = useMixpanelEvent(`${source}:sign_up_clicked`);

  const onSignUpClick = (e) => {
    e.preventDefault();
    trackSignUpClick();
    dispatch(authModalOpened());
  };

  if (textButton) {
    return <TextButton onClick={onSignUpClick} className={className}>{t('sign_up')}</TextButton>;
  }

  return <Button onClick={onSignUpClick} className={className}>{t('sign_up')}</Button>;
};

SignUpButton.propTypes = {
  source: PropTypes.string.isRequired,
  textButton: PropTypes.bool,
  className: PropTypes.string,
};

export default SignUpButton;
