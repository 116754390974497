export const LOAD_MORE_CLICKED = 'homePage/LOAD_MORE_CLICKED';
export const loadMoreClicked = path => ({type: LOAD_MORE_CLICKED, path});

export const LOAD_MORE_FAILED = 'homePage/LOAD_MORE_FAILED';
export const loadMoreFailed = path => ({type: LOAD_MORE_FAILED, path});

export const LOAD_MORE_LOADED = 'homePage/LOAD_MORE_LOADED';
export const loadMoreLoaded = (path, {result, entities}) => ({
  type: LOAD_MORE_LOADED,
  path,
  result,
  entities,
});
