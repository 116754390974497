import {get} from './client';
import {videoListSchema, normalize} from '@/normalize';

export const getVideoLists = async ({page = 1, perPage = 10, excludeFeatured = false}) => {
  const response = await get('/video_lists', {
    page,
    per_page: perPage,
    exclude_featured: excludeFeatured,
  });
  return normalize(response, {
    videoLists: [videoListSchema],
  });
};
