import {useMemo} from 'react';

import {useIsMobile, useEntity} from '@/hooks';
import {useShowContributorSidebar} from '@/songPage/hooks';

const SIDEBAR_ADS = [
  {name: 'desktop_song_lyrics_sidebar', sticky: true, lazy: false},
  {name: 'desktop_song_lyrics_sidebar2', sticky: false, lazy: true},
  {name: 'desktop_song_lyrics_sidebar3', sticky: false, lazy: true},
  {name: 'desktop_song_lyrics_sidebar4', sticky: false, lazy: true},
];

export default (songId, body) => {
  const isMobile = useIsMobile();
  const song = useEntity('songs', songId);
  const hasContributorsSidebar = useShowContributorSidebar(songId);

  const {lyricsPlaceholderReason} = song;

  const partitionedLyrics = useMemo(() => {
    if (lyricsPlaceholderReason) {
      return [{lyricsPlaceholderReason}];
    }

    if (!body.children) return [];

    const [firstChild, ...rest] = body.children;
    const safeToMangle = firstChild.tag === 'p' && firstChild.children;

    if (hasContributorsSidebar) {
      const lyrics = safeToMangle ? firstChild.children : body.children;
      return [{lyrics}];
    }

    let sidebarAdNumber = 0;
    let currentPartition = {
      lyrics: [],
      sidebarAd: !isMobile && SIDEBAR_ADS[sidebarAdNumber++],
    };

    if (!safeToMangle) {
      currentPartition.lyrics = body.children;
      return [currentPartition];
    }

    const partitions = [];

    firstChild.children.forEach((elem) => {
      if (elem.tag === 'inread-ad') {
        partitions.push(currentPartition, {inreadAd: elem});
        currentPartition = {lyrics: [], sidebarAd: !isMobile && SIDEBAR_ADS[sidebarAdNumber++]};
      } else {
        currentPartition.lyrics.push(elem);
      }
    });

    if (currentPartition.lyrics.length || rest.length) {
      currentPartition.lyrics.push(...rest);
      partitions.push(currentPartition);
    }

    return partitions;
  }, [body, lyricsPlaceholderReason, isMobile, hasContributorsSidebar]);

  return partitionedLyrics;
};
