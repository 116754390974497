import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const TextButton = styled.button`
  font-family: inherit;
  font-size: ${p => get(p.theme.fontSize, p.size, 'inherit')};
  font-weight: ${p => get(p.theme.fontWeight, p.fontWeight, 'inherit')};
  color: ${p => get(p.theme.color, p.color, 'inherit')};
  line-height: 1;
  text-transform: ${p => p.textTransform};
  text-decoration: ${p => p.textDecoration};
`;

TextButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  fontWeight: PropTypes.string,
  textTransform: PropTypes.string,
  textDecoration: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
};

TextButton.defaultProps = {
  type: 'button',
};

export default TextButton;
