import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinkButton = styled.button`
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;

  text-decoration: underline;
  font-family: inherit;
  font-size: ${p => p.fontSize === 'inherit' ? p.fontSize : p.theme.fontSize[p.fontSize]};
  font-weight: ${p => p.fontSize === 'inherit' ? p.fontSize : p.theme.fontWeight[p.fontWeight]};

  &:hover {
    text-decoration: none;
  }
`;

LinkButton.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
};

LinkButton.defaultProps = {
  type: 'button',
  fontSize: 'reading',
  fontWeight: 'normal',
};

export default LinkButton;
