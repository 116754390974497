import React from 'react';
import styled from 'styled-components';
import {TextLabel} from '@/components';

const PageFooterArtistLinks = () =>
  <>
    {'abcdefghijklmnopqrstuvwxyz#'.split('').map(char =>
      <PageFooterArtistLinks.Link key={char} href={`/artists-index/${char === '#' ? '0' : char}`}>
        <TextLabel size="smallReading">{char}</TextLabel>
      </PageFooterArtistLinks.Link>
    )}
  </>;

export default React.memo(PageFooterArtistLinks);

PageFooterArtistLinks.Link = styled.a`
  display: block;
  color: inherit;
  margin: 0 ${p => p.theme.space.quarter};
`;
