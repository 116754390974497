export const REPORTED_AS_ABUSIVE_SUBMITTED = 'songPage/REPORTED_AS_ABUSIVE_SUBMITTED';
export const reportAsAbusiveSubmitted = (reportableType, reportableId) => ({
  type: REPORTED_AS_ABUSIVE_SUBMITTED,
  reportableType,
  reportableId,
});

export const REPORTED_AS_ABUSIVE = 'songPage/REPORTED_AS_ABUSIVE';
export const reportAsAbusive = (reportableType, reportableId, params) => ({
  type: REPORTED_AS_ABUSIVE,
  reportableType,
  reportableId,
  params,
});

export const REPORTED_AS_ABUSIVE_SUCCEEDED = 'songPage/REPORTED_AS_ABUSIVE_SUCCEEDED';
export const reportAsAbusiveSucceeded = (reportableType, reportableId) => ({
  type: REPORTED_AS_ABUSIVE_SUCCEEDED,
  reportableType,
  reportableId,
});

export const REPORTED_AS_ABUSIVE_FAILED = 'songPage/REPORTED_AS_ABUSIVE_FAILED';
export const reportAsAbusiveFailed = (reportableType, reportableId) => ({
  type: REPORTED_AS_ABUSIVE_FAILED,
  reportableType,
  reportableId,
});

export const REPORT_ABUSE_FORM_OPENED = 'songPage/REPORT_ABUSE_FORM_OPENED';
export const reportAbuseFormOpened = (reportableType, reportableId) => ({
  type: REPORT_ABUSE_FORM_OPENED,
  reportableType,
  reportableId,
});

export const REPORT_ABUSE_FORM_CLOSED = 'songPage/REPORT_ABUSE_FORM_CLOSED';
export const reportAbuseFormClosed = (reportableType, reportableId) => ({
  type: REPORT_ABUSE_FORM_CLOSED,
  reportableType,
  reportableId,
});
