import {produce} from 'immer';
import {
  AD_REQUESTED,
  AD_EMPTY,
  AD_FILLED,
  AD_CUSTOM_LOADED,
  AD_CREATIVE_LOADED,
  AD_CREATIVE_VIEWABILITY_COMPLETE,
  AD_REFRESH_REQUESTED,
  AD_IMPRESSION_VIEWABLE,
  AD_SLOT_CONTAINER_HEIGHT_SET,
} from '@/actions';
import get from 'lodash/get';

const findOrInitializeAd = (draft, name, instance) => {
  const initialAdState = {state: 'initial', metadata: {}, custom: {}};

  draft[name] = get(draft, name, {});
  draft[name][instance] = get(draft, [name, instance]) || initialAdState;

  return draft[name][instance];
};

export default produce((draft, action) => {
  const {type, name, instance} = action;
  let ad;

  switch (type) {
  case AD_REQUESTED:
    ad = findOrInitializeAd(draft, name, instance);
    ad.state = 'requested';
    break;
  case AD_EMPTY:
    ad = findOrInitializeAd(draft, name, instance);
    ad.state = 'empty';
    break;
  case AD_FILLED:
    ad = findOrInitializeAd(draft, name, instance);
    ad.state = 'filled';
    ad.metadata = action.metadata;
    break;
  case AD_CUSTOM_LOADED:
    ad = findOrInitializeAd(draft, name, instance);
    ad.custom = action.custom;
    break;
  case AD_CREATIVE_LOADED:
    ad = findOrInitializeAd(draft, name, instance);
    ad.creativeState = 'loaded';
    ad.creativeSize = action.creativeSize;
    break;
  case AD_CREATIVE_VIEWABILITY_COMPLETE:
    ad = findOrInitializeAd(draft, name, instance);
    ad.creativeState = 'viewabilityComplete';
    break;
  case AD_REFRESH_REQUESTED:
    ad = findOrInitializeAd(draft, name, instance);
    ad.impressionViewable = null;
    break;
  case AD_IMPRESSION_VIEWABLE:
    ad = findOrInitializeAd(draft, name, instance);
    ad.impressionViewable = true;
    break;
  case AD_SLOT_CONTAINER_HEIGHT_SET:
    ad = findOrInitializeAd(draft, name, instance);
    ad.containerHeight = action.height;
    break;
  default:
    return draft;
  }
});
