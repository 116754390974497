export class ThirdPartyClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get(path, params = {}) {
    if (!this.baseUrl) return;

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));

    return fetch(
      `${this.baseUrl}${path}?${searchParams.toString()}`,
      {method: 'GET'},
    );
  }
}
