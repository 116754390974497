import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import {useTranslation} from 'react-i18next';
import {useHotkeys} from 'react-hotkeys-hook';

import {useMixpanelEvent} from '@/hooks';
import {zIndex} from '@/style';

import {IconButton} from '@/components';
import Checkmark from '@/svg/checkmark.svg';
import X from '@/svg/x.svg';

import {
  ModalSharedButtonLabel,
  ModalSharedContainer,
  useModalVisibility,
  onAfterOpen,
  scrollAfterClose,
} from './Modal.shared';

const Modal = ({
  bodyId,
  bodyWidth,
  children,
  closeLabel,
  hideControls,
  isSaveActive,
  onAfterClose,
  onClose,
  onSave,
  overlayId,
  position,
  saveLabel,
  show,
  trackingName,
}) => {
  const {t} = useTranslation();
  const {isOpen, scrollY} = useModalVisibility(show);
  const trackKeyboardShortcut = useMixpanelEvent('song:keyboard_shortcut');

  const stopClickPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const onAfterModalClose = useCallback(() => {
    scrollAfterClose(scrollY);
    if (onAfterClose) {
      onAfterClose();
    }
  }, [onAfterClose, scrollY]);

  useHotkeys('shift+enter', () => {
    trackKeyboardShortcut({shortcut_name: 'modal:save', modal_name: trackingName || ''});
    onSave();
  }, {
    enableOnTags: ['INPUT'],
    filter: () => isOpen && Boolean(onSave) && isSaveActive,
  });

  const Controls = (
    <Modal.Controls onClick={stopClickPropagation}>
      <Modal.ControlsInternal>
        {onSave && (
          <Modal.ButtonField>
            <Modal.SaveIconButton
              aria-label={saveLabel || t('controls.modal.save')}
              onClick={onSave}
              disabled={!isSaveActive}
            >
              <Checkmark />
            </Modal.SaveIconButton>
            {saveLabel && <ModalSharedButtonLabel>{saveLabel}</ModalSharedButtonLabel>}
          </Modal.ButtonField>
        )}
        <Modal.ButtonField>
          <IconButton aria-label={closeLabel || t('controls.modal.close')} onClick={onClose}>
            <X />
          </IconButton>
          {closeLabel && <ModalSharedButtonLabel>{closeLabel}</ModalSharedButtonLabel>}
        </Modal.ButtonField>
      </Modal.ControlsInternal>
    </Modal.Controls>
  );

  return (
    <ReactModal
      bodyOpenClassName="noscroll"
      htmlOpenClassName="noscroll"
      id={bodyId}
      isOpen={isOpen}
      onAfterOpen={() => onAfterOpen(scrollY)}
      onAfterClose={onAfterModalClose}
      onRequestClose={onClose}
      ariaHideApp={false}
      overlayElement={({onClick, style, ...props}, content) => ( // eslint-disable-line no-unused-vars
        <Modal.Container {...props} id={overlayId} onClick={onClick} data-ignore-on-click-outside>
          {content}
        </Modal.Container>
      )}
      contentElement={({style, ...props}, modalChildren) => ( // eslint-disable-line no-unused-vars
        <Modal.BodyContainer onClick={stopClickPropagation} position={position}>
          <Modal.Body {...props} bodyWidth={bodyWidth}>
            {modalChildren}
          </Modal.Body>
          {!hideControls && Controls}
        </Modal.BodyContainer>
      )}
    >
      <Modal.Contents>
        {children}
      </Modal.Contents>
    </ReactModal>
  );
};

Modal.defaultProps = {
  bodyWidth: '40rem',
  hideControls: false,
  position: 'center',
  isSaveActive: true,
};

Modal.propTypes = {
  bodyId: PropTypes.string,
  bodyWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeLabel: PropTypes.string,
  hideControls: PropTypes.bool,
  isSaveActive: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  overlayId: PropTypes.string,
  position: PropTypes.oneOf(['center', 'top']),
  saveLabel: PropTypes.string,
  show: PropTypes.bool.isRequired,
  trackingName: PropTypes.string,
};

export default React.memo(Modal);

Modal.Container = styled(ModalSharedContainer)`
  justify-content: center;
`;

Modal.BodyContainer = styled.div`
  display: flex;
  height: max-content;
  ${p => p.position === 'center' ? 'margin-block: auto;' : ''};
`;

Modal.Body = styled.div`
  ${zIndex('modalUpper')};
  height: max-content;
  outline: none;
  width: ${p => p.bodyWidth};
`;

Modal.Contents = styled.div`
  padding: ${p => p.theme.space.xxLarge} 0;
`;

Modal.Controls = styled.div`
  position: sticky;
  top: 0;
  width: 0;
  font-family: ${p => p.theme.font.alternate};
  ${zIndex('modalLower')};
  height: 100%;
`;

Modal.ControlsInternal = styled.div`
  position: absolute;
  top: ${p => p.theme.space.xxLarge};
  right: calc(-1 * ${p => p.theme.space.small});
  transform: translateX(100%);
`;

Modal.ButtonField = styled.label`
  display: block;
  color: ${p => p.theme.color.background.on};

  &:not(:last-child) {
    margin-bottom: ${p => p.theme.space.full};
  }
`;

Modal.SaveIconButton = styled(props => <IconButton {...props} />)`
  background-color: ${p => p.theme.color.success.main};
  border-color: transparent;

  &:hover {
    background-color: ${p => p.theme.color.success.main};

    svg {
      fill: white;
    }
  }

  &:disabled {
    cursor: auto;
    background-color: ${p => p.theme.color.background.onVariant};
  }
`;
