import React, {useState} from 'react';
import styled from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import {Button, SignInForm, SignUpForm, TextButton, InlineSvg} from '@/components';
import {useMixpanelEvent} from '@/hooks';
import TwitterIcon from '@/svg/twitter.svg';
import FacebookIcon from '@/svg/facebook.svg';
import GoogleIcon from '@/svg/google.svg';

const csrfToken = Cookies.get('_csrf_token');

const setDesiredLocation = () => {
  Cookies.set('desired_location', window.location);
};

const AuthenticationForm = ({closeForm, showDivider, reason}) => {
  const {t} = useTranslation();
  const [signingUp, setSigningUp] = useState(true);

  const trackGoogleAuth = useMixpanelEvent('auth:google_click', {reason});
  const trackFacebookAuth = useMixpanelEvent('auth:facebook_click', {reason});
  const trackTwitterAuth = useMixpanelEvent('auth:twitter_click', {reason});

  const handleSocialAuthFacebook = () => {
    trackFacebookAuth();
    setDesiredLocation();
  };
  const handleSocialAuthTwitter = () => {
    trackTwitterAuth();
    setDesiredLocation();
  };
  const handleSocialAuthGoogle = () => {
    trackGoogleAuth();
    setDesiredLocation();
  };

  const action = signingUp ? 'sign_up' : 'sign_in';
  const reasonTree = reason ? reason.split('.').reduce((output, _, index, reasonComponents) => {
    output.push(reasonComponents.slice(0, index + 1).join('.'));
    return output;
  }, []).reverse() : [];

  return (
    <AuthenticationForm.Container>
      {showDivider && <AuthenticationForm.Divider/>}

      <AuthenticationForm.Title>
        {t([...reasonTree, 'default'].map(r => `auth_form.title_${action}.${r}`))}
      </AuthenticationForm.Title>

      {reason && <AuthenticationForm.Reason>
        {t(reasonTree.map(r => `auth_form.reason.${r}`))}
      </AuthenticationForm.Reason>}

      <AuthenticationForm.SocialButtonContainer>
        <form action="/auth/facebook" method="post" onSubmit={handleSocialAuthFacebook}>
          <input type="hidden" name="authenticity_token" value={csrfToken}/>
          <AuthenticationForm.SocialButton type="submit">
            <AuthenticationForm.InlineSvg aspectRatio={6.6 / 16} lineHeight={1} svg={FacebookIcon} />
            {t(`auth_form.facebook_${action}_button`)}
          </AuthenticationForm.SocialButton>
        </form>
        <form action="/auth/twitter" method="post" onSubmit={handleSocialAuthTwitter}>
          <input type="hidden" name="authenticity_token" value={csrfToken}/>
          <AuthenticationForm.SocialButton type="submit">
            <AuthenticationForm.InlineSvg aspectRatio={6.6 / 16} lineHeight={1} svg={TwitterIcon} />
            {t(`auth_form.twitter_${action}_button`)}
          </AuthenticationForm.SocialButton>
        </form>
        <form action="/auth/google" method="post" onSubmit={handleSocialAuthGoogle}>
          <input type="hidden" name="authenticity_token" value={csrfToken}/>
          <AuthenticationForm.SocialButton type="submit">
            <AuthenticationForm.InlineSvg aspectRatio={1} lineHeight={1} svg={GoogleIcon} />
            {t(`auth_form.google_${action}_button`)}
          </AuthenticationForm.SocialButton>
        </form>
      </AuthenticationForm.SocialButtonContainer>

      {signingUp ? <SignUpForm/> : <SignInForm/>}

      <AuthenticationForm.HelperText>
        <Trans
          i18nKey={signingUp ? 'auth_form.already_have_account' : 'auth_form.need_an_account'}
          components={{
            toggle: <AuthenticationForm.TextButton onClick={() => setSigningUp(!signingUp)} />,
          }}
        />
        {closeForm && <>
          / <TextButton onClick={closeForm}>{t('auth_form.close')}</TextButton>
        </>}
      </AuthenticationForm.HelperText>
    </AuthenticationForm.Container>
  );
};

export default React.memo(AuthenticationForm);

AuthenticationForm.propTypes = {
  closeForm: PropTypes.function,
  showDivider: PropTypes.bool,
  reason: PropTypes.string,
};

AuthenticationForm.defaultProps = {
  showDivider: false,
};

AuthenticationForm.Container = styled.div``;

AuthenticationForm.Divider = styled.hr`
  border-color: inherit;
  margin: ${p => p.theme.space.large} 0;
`;

AuthenticationForm.Title = styled.h1`
  text-align: center;
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: ${p => p.theme.fontWeight.normal};
  line-height: ${p => p.theme.lineHeight.short};
  margin-bottom: ${p => p.theme.space.full};
`;

AuthenticationForm.Reason = styled.div`
  font-weight: ${p => p.theme.fontWeight.light};
  text-align: center;
`;

AuthenticationForm.TextButton = styled(TextButton)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

AuthenticationForm.InlineSvg = styled(props => <InlineSvg {...props} />)`
  margin-right: ${p => p.theme.space.half};
`;

AuthenticationForm.SocialButtonContainer = styled.div`
  margin-bottom: ${p => p.theme.space.xLarge};
`;

AuthenticationForm.HelperText = styled.p`
  font-family: ${p => p.theme.font.alternate};
  font-weight: normal;
  margin-top: ${p => p.theme.space.half};
  text-align: center;
  font-size: ${p => p.theme.fontSize.smallReading};
`;

AuthenticationForm.SocialButton = styled(props => <Button {...props} />)`
  width: 100%;
  font-size: ${p => p.theme.fontSize.smallReading};
  margin: 0;
  margin-top: ${p => p.theme.space.small};
`;
