import React from 'react';
import styled from 'styled-components';
import PageHeaderInbox from './PageHeaderInbox';
import PageHeaderMenu from './PageHeaderMenu';
import Pin from '@/svg/pin.svg';
import LightningAlt from '@/svg/lightningAlt.svg';
import Bell from '@/svg/bell.svg';
import Envelope from '@/svg/envelope.svg';
import Iq from '@/svg/iq.svg';
import {useTranslation} from 'react-i18next';

const PageHeaderLoggedIn = () => {
  const {t} = useTranslation();
  return (
    <PageHeaderLoggedIn.Container>
      <PageHeaderLoggedIn.Item>
        <PageHeaderInbox
          name="groupsInbox"
          label={t('header.groups_inbox')}
          icon={Pin}
        />
      </PageHeaderLoggedIn.Item>
      <PageHeaderLoggedIn.Item>
        <PageHeaderInbox
          name="newsfeedInbox"
          label={t('header.newsfeed_inbox')}
          icon={LightningAlt}
          abbreviateAfter={20}
        />
      </PageHeaderLoggedIn.Item>
      <PageHeaderLoggedIn.Item>
        <PageHeaderInbox
          name="mainActivityInbox"
          label={t('header.main_activity_inbox')}
          icon={Bell}
          syncCountToTitle={true}
        />
      </PageHeaderLoggedIn.Item>
      <PageHeaderLoggedIn.Item>
        <PageHeaderInbox
          name="messagesInbox"
          label={t('header.messages_inbox')}
          icon={Envelope}
        />
      </PageHeaderLoggedIn.Item>
      <PageHeaderLoggedIn.Item>
        <PageHeaderInbox
          name="iqInbox"
          label={t('header.iq_inbox')}
          icon={Iq}
        />
      </PageHeaderLoggedIn.Item>
      <PageHeaderLoggedIn.Item>
        <PageHeaderMenu />
      </PageHeaderLoggedIn.Item>
    </PageHeaderLoggedIn.Container>
  );
};

export default React.memo(PageHeaderLoggedIn);

PageHeaderLoggedIn.Item = styled.div``;

PageHeaderLoggedIn.Container = styled.div`
  display: flex;
  align-items: center;

  ${PageHeaderLoggedIn.Item} + ${PageHeaderLoggedIn.Item} {
    margin-left: ${p => p.theme.space.full};
  }
`;
