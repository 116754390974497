import camelCase from 'lodash/camelCase';
import isError from 'lodash/isError';

export const ENTITY_ACTIONS = {
  FETCHED: 'FETCHED',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  OPTIMISTIC_UPDATED: 'OPTIMISTIC_UPDATED',
  REVERT_OPTIMISTIC_UPDATED: 'REVERT_OPTIMISTIC_UPDATED',
  REMOVED: 'REMOVED',
  OPTIMISTIC_REMOVED: 'OPTIMISTIC_REMOVED',
  REVERT_OPTIMISTIC_REMOVED: 'REVERT_OPTIMISTIC_REMOVED',
};

const createEntityActionType = (entityType, actionType) => `entity/${entityType}/${actionType}`;

export const createEntityAction = (entityType, actionType) => {
  const type = createEntityActionType(entityType, actionType);

  const actionCreator = (payload, meta = {}) => {
    const action = {
      type,
      payload,
      meta,
    };

    if (isError(payload)) {
      action.error = true;
    }

    action.meta.entityType = entityType;

    return action;
  };

  actionCreator.toString = () => type;

  return actionCreator;
};

/**
 * @typedef {Object} EntityActions
 * @property {function} fetched action creator
 * @property {function} created action creator
 * @property {function} updated action creator
 * @property {function} removed action creator
 * @property {function} optimisticUpdated action creator
 * @property {function} revertOptimisticUpdated action creator
 * @property {function} optimisticRemoved action creator
 * @property {function} revertOptimisticRemoved action creator
 * @property {String}   FETCHED action type
 * @property {String}   CREATED action type
 * @property {String}   UPDATED action type
 * @property {String}   REMOVED action type
 * @property {String}   OPTIMISTIC_UPDATED action type
 * @property {String}   REVERT_OPTIMISTIC_UPDATED action type
 * @property {String}   OPTIMISTIC_REMOVED action type
 * @property {String}   REVERT_OPTIMISTIC_REMOVED action type
 */

/**
 * Create standard CRUD actions for entities
 * Plus optimistic update and delete
 * @param {String} type Plural entity type (has to match what normalizr uses)
 * @returns {EntityActions} Action creators (camelCase) and action types (UPPER_SNAKE_CASE)
 */
export const createEntityActions = (type) => {
  const actions = {};
  Object.keys(ENTITY_ACTIONS).forEach((actionType) => {
    const actionCreator = createEntityAction(type, actionType);
    actions[actionType] = actionCreator.toString();
    actions[camelCase(actionType)] = actionCreator;
  });
  return actions;
};
