import {createEntityActions} from '@/songPage/actions/entities';

export const questionEntity = createEntityActions('questions');

export const MORE_QUESTIONS_CLICKED = 'songPage/MORE_QUESTIONS_CLICKED';
export const moreQuestionsClicked = (songId, state = 'pending') => ({
  type: MORE_QUESTIONS_CLICKED,
  songId,
  state,
});

export const QUESTIONS_FETCHED = 'songPage/QUESTIONS_FETCHED';
export const questionsFetched = ({result, entities}) => ({
  type: QUESTIONS_FETCHED,
  result,
  entities,
});

export const QUESTIONS_FETCH_FAILED = 'songPage/QUESTIONS_FETCH_FAILED';
export const questionsFetchFailed = songId => ({
  type: QUESTIONS_FETCH_FAILED,
  songId,
});

export const QUESTION_SUBMITTED = 'songPage/QUESTION_SUBMITTED';
export const questionSubmitted = (data, callbacks) => ({
  type: QUESTION_SUBMITTED,
  payload: data,
  meta: callbacks,
});

export const QUESTION_STATE_CHANGED = 'songPage/QUESTION_STATE_CHANGED';
export const questionStateChanged = (questionId, state) => ({
  type: QUESTION_STATE_CHANGED,
  questionId,
  state,
});

export const QUESTION_LIST_UPDATED = 'songPage/QUESTION_LIST_UPDATED';
export const questionListUpdated = ({pendingQuestions, pinnedQuestions}) => ({
  type: QUESTION_LIST_UPDATED,
  pendingQuestions,
  pinnedQuestions,
});

export const QUESTION_MOVED = 'songPage/QUESTION_MOVED';
export const questionMoved = (questionId, direction) => ({
  type: QUESTION_MOVED,
  questionId,
  direction,
});

export const QUESTION_EDITED = 'songPage/QUESTION_EDITED';
export const questionEdited = (data, callbacks) => ({
  type: QUESTION_EDITED,
  payload: data,
  meta: callbacks,
});
