import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {UserAvatar, TextLabel} from '@/components';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import RoleIconStaff from '@/svg/roleIconStaff.svg';
import RoleIconModerator from '@/svg/roleIconModerator.svg';
import RoleIconMediator from '@/svg/roleIconMediator.svg';
import RoleIconTranscriber from '@/svg/roleIconTranscriber.svg';
import RoleIconEditor from '@/svg/roleIconEditor.svg';
import RoleIconContributor from '@/svg/roleIconContributor.svg';
import Checky from '@/svg/checky.svg';
import {useEntity} from '@/hooks';
import {useTranslation} from 'react-i18next';

const UserBadge = ({id, avatarSize, usernameFontSize}) => {
  const {t} = useTranslation();
  const user = useEntity('users', id);
  const role = camelCase(user.roleForDisplay);
  const RoleIcon = svgByRole[role];
  const roleLabel = role ? t(`role.types.${snakeCase(role)}.one`) : '';

  return (
    <UserBadge.Container>
      <UserBadge.Avatar>
        <UserAvatar id={id} size={avatarSize} />
      </UserBadge.Avatar>
      <UserBadge.NameAndRole>
        <UserBadge.Name size={usernameFontSize}>{user.name}</UserBadge.Name>
        {role &&
          <UserBadge.Role role={role}>
            <TextLabel color="background.onVariant">{roleLabel}</TextLabel>
            {RoleIcon && <RoleIcon />}
          </UserBadge.Role>
        }
      </UserBadge.NameAndRole>
    </UserBadge.Container>
  );
};

UserBadge.propTypes = {
  id: PropTypes.number.isRequired,
  avatarSize: PropTypes.number,
  usernameFontSize: PropTypes.string,
};

const svgByRole = {
  staff: RoleIconStaff,
  moderator: RoleIconModerator,
  mediator: RoleIconMediator,
  editor: RoleIconEditor,
  contributor: RoleIconContributor,
  transcriber: RoleIconTranscriber,
  verifiedArtist: Checky,
};

export default React.memo(UserBadge);

UserBadge.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 100;
`;

UserBadge.Avatar = styled.div`
  flex-shrink: 0;
`;

UserBadge.NameAndRole = styled.div`
  margin-left: ${p => p.theme.space.xLarge};
  line-height: 1;
`;

UserBadge.Name = styled.div`
  font-size: ${p => p.theme.fontSize[p.size]};
`;

UserBadge.Role = styled.div`
  margin-top: ${p => p.theme.space.quarter};

  svg {
    margin-left: ${p => p.theme.space.quarter};
    height: 10px;
    width: 10px;
    stroke: ${p => p.theme.color[p.role].on};
    fill: ${p => p.theme.color[p.role].main};
    overflow: visible;
    position: relative;
    top: 1px;
  }
`;
