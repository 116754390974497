import React, {useState, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {useOnClickOutside} from '@/hooks';

const Dropdown = ({children, renderToggle, hover}) => {
  const [isVisible, setVisible] = useState(false);
  const [hovering, setHovering] = useState(false);

  const el = useRef(null);

  const handleOpen = useCallback(() => setVisible(true), []);
  const handleClose = useCallback(() => {
    setVisible(false);
    setHovering(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHovering(true);
    hover && handleOpen();
  }, [handleOpen, hover]);

  const handleMouseLeave = useCallback(() => {
    setHovering(false);
    hover && handleClose();
  }, [handleClose, hover]);

  const handleToggle = useCallback(() => !hover && setVisible(m => !m), [hover]);

  useOnClickOutside({
    element: el,
    onClickOutside: () => {
      isVisible && setVisible(false);
    },
  });

  return (
    <Dropdown.Container
      ref={el}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Dropdown.Toggle onClick={handleToggle}>
        {renderToggle({
          isVisible,
          hovering,
        })}
      </Dropdown.Toggle>
      <Dropdown.ContentContainer show={isVisible}>
        {children({onClose: handleClose})}
      </Dropdown.ContentContainer>
    </Dropdown.Container>
  );
};

export default React.memo(Dropdown);

Dropdown.propTypes = {
  children: PropTypes.func.isRequired,
  renderToggle: PropTypes.func.isRequired,
  hover: PropTypes.bool,
};

Dropdown.defaultProps = {
  hover: false,
};

Dropdown.Container = styled.div`
  position: relative;
  height: 100%;
`;

Dropdown.ContentContainer = styled.div`
  display: ${p => p.show ? 'block' : 'none'};
`;

Dropdown.Toggle = styled.button`
  display: block;
  height: 100%;
`;
