import {get} from '@/api/client';
import {artistAutocompleteSchema, artistSchema, normalize} from '@/normalize';

export const getArtistAutocomplete = async (searchTerm) => {
  const response = await get('/artists/autocomplete', {q: searchTerm});
  return normalize(response.artists, artistAutocompleteSchema);
};

export const getArtist = async (id) => {
  const response = await get(`/artists/${id}`);
  return normalize(response, {artist: artistSchema});
};
