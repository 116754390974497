import {useSelector, shallowEqual} from 'react-redux';
import get from 'lodash/get';

const usePendingAnnotationEditProposals = annotationId => useSelector(state => (
  Object.values(get(state, ['entities', 'annotationEdits'], {})).
    filter(annotationEdit => annotationEdit.annotationId === annotationId).
    filter(annotationEdit => annotationEdit.state === 'pending').
    sort((a, b) => a.createdAt < b.createdAt).
    map(annotationEdit => annotationEdit.id)
), shallowEqual);

export default usePendingAnnotationEditProposals;
