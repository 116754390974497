import Cookies from 'js-cookie';
import {AppConfig} from '@/config';
import bugsnag from '@/bugsnag';

export class ApiError extends Error {
  constructor(message, status, response) {
    super(message);
    this.status = status;
    this.stack = Error().stack;
    this.response = response;
  }

  get name() { return 'ApiError'; }
  get errors() { return this.response?.errors; }
  get validation_errors() { return this.response?.validation_errors; }
}

const handleResponse = async (request) => {
  const response = await request;
  const body = await response.text();
  if (!body) return;

  const data = JSON.parse(body);
  if (data.meta.status >= 200 && data.meta.status < 300) {
    bugsnag.leaveBreadcrumb('API Response Data', data.response);
    return data.response;
  } else {
    throw new ApiError(
      data.meta.message,
      data.meta.status,
      data.response,
    );
  }
};

export const post = async (path, params = {}) => await handleResponse(
  fetch(`${AppConfig.apiRootUrl}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
    body: JSON.stringify({text_format: 'html,markdown', ...params}),
  })
);

export const del = async (path, params = {}) => await handleResponse(
  fetch(`${AppConfig.apiRootUrl}${path}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
    body: JSON.stringify({text_format: 'html,markdown', ...params}),
  })
);

export const put = async (path, params = {}) => await handleResponse(
  fetch(`${AppConfig.apiRootUrl}${path}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
    body: JSON.stringify({text_format: 'html,markdown', ...params}),
  })
);

export const destroy = async path => await handleResponse(
  fetch(`${AppConfig.apiRootUrl}${path}`, {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': Cookies.get('_csrf_token'),
    },
  })
);

export const get = async (path, params = {}) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => searchParams.append(key, params[key]));
  if (!searchParams.has('text_format')) {
    searchParams.set('text_format', 'html,markdown');
  }

  return await handleResponse(
    fetch(`${AppConfig.apiRootUrl}${path}?${searchParams.toString()}`, {
      method: 'GET',
    })
  );
};
