import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import noop from 'lodash/noop';
import merge from 'lodash/merge';

import {dirtyValues, applyValidationErrors} from '@/util/forms';

/**
 * Set up a standard form
 * @param {Object}    options
 * @param {function}  options.submitAction action creator to be dispatched with form data on submit
 * @param {Object}    [options.extraValues] extra values to merge into payload
 * @param {function}  [options.transformPayload] transform submit payload before dispatching (takes one argument, the payload)
 * @param {boolean}   [options.onlySubmitChangedFields=false] only send changed (dirty) fields in payload
 * @param {Object}    [options.serverErrorFieldMapping] map of server field names to form field names
 * @param {Object}    [options.useFormOptions] react-hook-form useForm options
 * @return {Object}   {methods, onSubmit, isSubmitting}
 */
const useEntityForm = ({
  submitAction,
  extraValues,
  onlySubmitChangedFields = false,
  transformPayload,
  serverErrorFieldMapping = {},
  useFormOptions,
  onSubmit = noop,
  onSuccess = noop,
  onError = noop,
}) => {
  const dispatch = useDispatch();

  const methods = useForm(useFormOptions);
  const {handleSubmit, setError, clearErrors, formState} = methods;
  const {isSubmitting, dirtyFields} = formState;

  const createPayload = useCallback((data) => {
    const formPayload = onlySubmitChangedFields ? dirtyValues(dirtyFields, data) : data;
    const totalPayload = merge(formPayload, extraValues);
    const payload = transformPayload ? transformPayload(totalPayload) : totalPayload;
    return Promise.resolve(payload);
  }, [dirtyFields, extraValues, onlySubmitChangedFields, transformPayload]);

  const mapErrorFields = useCallback(validationErrors => (
    Object.fromEntries(Object.entries(validationErrors).map(([field, errors]) => (
      [serverErrorFieldMapping[field] || field, errors]
    )))
  ), [serverErrorFieldMapping]);

  const wrappedOnError = useCallback((apiError) => {
    onError(apiError.errors);
    applyValidationErrors(mapErrorFields(apiError.validation_errors ?? {}), setError);
  }, [mapErrorFields, onError, setError]);

  const onSubmitWithCallbacks = useCallback(data => new Promise((resolve, reject) => {
    createPayload(data).then((payload) => {
      dispatch(submitAction(payload, {
        onSuccess: () => {
          onSuccess();
          resolve();
        },
        onError: (error) => {
          wrappedOnError(error);
          reject(error);
        },
      }));
    });
    onSubmit(data);
  }), [createPayload, submitAction, dispatch, onSuccess, onSubmit, wrappedOnError]);

  const wrappedOnSubmit = useMemo(() => (event) => {
    clearErrors();
    handleSubmit(onSubmitWithCallbacks)(event);
  }, [clearErrors, handleSubmit, onSubmitWithCallbacks]);

  return {
    methods,
    onSubmit: wrappedOnSubmit,
    isSubmitting,
  };
};

export default useEntityForm;
