import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const List = ({className, items, ListItem, limit}) => {
  const [moreToShow, setMoreToShow] = useState(limit && items.length > limit);

  const showMore = useCallback(() => setMoreToShow(false), []);

  const itemsToShow = moreToShow ? items.slice(0, limit) : items;

  const itemSeparator = (index) => {
    const itemsLength = moreToShow ? itemsToShow.length + 1 : itemsToShow.length;
    if (index === itemsLength - 1) return '';
    if (index === itemsLength - 2) return ' & ';
    return ', ';
  };

  useEffect(() => {
    if (items.length < limit && moreToShow) {
      setMoreToShow(false);
    }
  }, [items, limit, moreToShow]);

  return (
    <div className={className}>
      {itemsToShow.map((item, index) => (
        <React.Fragment key={item.id}>
          <ListItem item={item} />
          {itemSeparator(index)}
        </React.Fragment>
      ))}
      {moreToShow && <List.More onClick={showMore}>{items.length - limit} more</List.More>}
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  ListItem: PropTypes.elementType.isRequired,
  limit: PropTypes.number,
};

List.defaultProps = {
  urlProp: 'url',
};

export default React.memo(List);

List.More = styled.button`
  display: inline;
  border: none;
  background: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: ${p => p.theme.color.background.on};
`;
