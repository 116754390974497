import React, {useEffect, useMemo, useRef} from 'react';
import {siteLinksVisibilityToggled} from '@/actions';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {zIndex} from '@/style';
import {IntersectionObserver} from '@/util';
import {useDistanceAbove} from '@/hooks';

const StickyNavSentinel = () => {
  const sentinel = useRef(null);
  const dispatch = useDispatch();
  const observer = useMemo(() => (
    new IntersectionObserver(([entry]) => {
      dispatch(siteLinksVisibilityToggled(entry.isIntersecting));
    }, {
      threshold: 0,
    })
  ), [dispatch]);

  useEffect(() => {
    observer.observe(sentinel.current);
    return () => observer.disconnect();
  }, [observer]);

  const distanceAboveBottomStickyNav = useDistanceAbove('bottomStickyNav');

  return (
    <StickyNavSentinel.Sentinel ref={sentinel} distanceAboveBottomStickyNav={distanceAboveBottomStickyNav} />
  );
};

StickyNavSentinel.Sentinel = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${p => `calc(${p.distanceAboveBottomStickyNav})`};
  ${zIndex('sentinel')}
`;

export default StickyNavSentinel;
