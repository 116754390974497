import {shallowEqual} from 'react-redux';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import without from 'lodash/without';

export const arrayElementsShallowEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (!shallowEqual(array1[i], array2[i])) {
      return false;
    }
  }

  return true;
};

const mergeCurrentUserMetadata = (objValue, srcValue) => {
  const currentUserMetadata = merge(
    omit(objValue, ['permissions', 'excludedPermissions']),
    omit(srcValue, ['permissions', 'excludedPermissions']),
  );

  currentUserMetadata.permissions = union(objValue?.permissions, srcValue?.permissions);
  if (srcValue?.excludedPermissions) {
    currentUserMetadata.permissions = without(currentUserMetadata.permissions, ...srcValue.excludedPermissions);
  }

  currentUserMetadata.excludedPermissions = union(objValue?.excludedPermissions, srcValue?.excludedPermissions);
  if (srcValue?.permissions) {
    currentUserMetadata.excludedPermissions = without(currentUserMetadata.excludedPermissions, ...srcValue.permissions);
  }

  return currentUserMetadata;
};

const mergeEntitiesCustomizer = (objValue, srcValue, key) => {
  if (key === 'currentUserMetadata') {
    return mergeCurrentUserMetadata(objValue, srcValue);
  } else if (Array.isArray(srcValue)) {
    return srcValue;
  } else {
    return undefined;
  }
};

export const mergeEntities = (existingEntities, newEntities) => {
  mergeWith(existingEntities, newEntities, mergeEntitiesCustomizer);
};
