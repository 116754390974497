import {useDispatch, useSelector} from 'react-redux';
import {useMixpanelEvent} from '@/hooks';
import {toggleContributionsModal} from '@/songPage/actions';

const useContributionsModal = (dispatcher = 'song') => {
  const dispatch = useDispatch();
  const mixPanelTrackKey = `${dispatcher}:contributors_click`;
  const trackContributionsOpen = useMixpanelEvent(mixPanelTrackKey);

  const isVisible = useSelector(state => state.songPage?.isContributionsModalVisible);

  const open = () => {
    dispatch(toggleContributionsModal(true));
    trackContributionsOpen();
  };

  const close = () => {
    dispatch(toggleContributionsModal(false));
  };

  return {open, close, isVisible};
};

export default useContributionsModal;
