import {post} from './client';
import {pyongableSchema, normalize} from '@/normalize';

export const pyong = async ({pyongableType, pyongableId}) => {
  const response = await post('/pyongs', {
    [`${pyongableType}_id`]: pyongableId,
    note_may_follow: true,
  });
  return normalize(response, pyongableSchema);
};

export const finalizePyong = async ({pyongableType, pyongableId, pyongNote}) => {
  const response = await post('/pyongs/finalize', {
    [`${pyongableType}_id`]: pyongableId,
    pyong_note: pyongNote,
  });
  return normalize(response, pyongableSchema);
};
