import transform from 'lodash/transform';

const transformTrackingDataToObject = (trackingData = []) => transform(
  trackingData,
  (obj, {key, value}) => { obj[key] = value; },
  {}
);

export default () => {
  const state = window.__PRELOADED_STATE__;
  const currentPage = state.currentPage;

  state[currentPage].trackingData = transformTrackingDataToObject(state[currentPage].trackingData);

  return state;
};
