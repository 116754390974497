import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import {DfpAd} from '@/components';
import {useAd} from '@/hooks';
import {zIndex} from '@/style';

const StickyBannerAd = ({name, position, placeholderSize}) => {
  const {state} = useAd(name);
  const showAds = useSelector(s => s.session.showAds);
  const stickyBannerAdVisible = useSelector(s => get(s, ['stickyNav', 'stickyBannerAdVisible'], true));
  const canHide = position === 'top';

  const shouldNotRender = !showAds || state === 'empty' || (canHide && !stickyBannerAdVisible);

  if (shouldNotRender) {
    return null;
  }

  return (
    <StickyBannerAd.Container stickyPosition={position} height={placeholderSize.height}>
      <DfpAd name={name} lazy={false} placeholderSize={placeholderSize} />
    </StickyBannerAd.Container>
  );
};

StickyBannerAd.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
  placeholderSize: PropTypes.object,
};

StickyBannerAd.defaultProps = {
  position: 'top',
  placeholderSize: {},
};

StickyBannerAd.Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${p => p.height && `height: ${p.height}px;`}
  background-color: black;
  position: sticky;
  ${p => p.stickyPosition === 'bottom' ? `
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
  ` : `
    top: 0;
  `}
  ${zIndex('mobileAdhesion')}
`;

export default React.memo(StickyBannerAd);
