import {useEntity} from '@/hooks';
import isEmpty from 'lodash/isEmpty';

const METADATA_FIELDS = [
  'releaseDateComponents',
  'producerArtists',
  'writerArtists',
  'albums',
];

const useSidebarMetadataTracker = (songId) => {
  const song = useEntity('songs', songId);

  const metadataCompleted = METADATA_FIELDS.
    every(field => !isEmpty(song?.[field]) || song?.metadataFieldsNa?.[field]);

  const pendingMetadataCount = METADATA_FIELDS.
    filter(field => isEmpty(song[field]) && !song?.metadataFieldsNa[field]).length;

  return {metadataCompleted, pendingMetadataCount};
};

export default useSidebarMetadataTracker;
