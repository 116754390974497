import {produce} from 'immer';

import {
  STICKY_LEADERBOARD_HEIGHT_SET,
  STICKY_BANNER_AD_VISIBILITY_TOGGLED,
  SITE_LINKS_VISIBILITY_TOGGLED,
  SECTION_VISIBILITY_CHANGED,
} from '@/actions';

export default produce((draft, action) => {
  switch (action.type) {
  case STICKY_LEADERBOARD_HEIGHT_SET:
    draft.stickyLeaderboardHeight = action.height;
    break;
  case SITE_LINKS_VISIBILITY_TOGGLED:
    draft.siteLinksVisible = action.visible;
    break;
  case STICKY_BANNER_AD_VISIBILITY_TOGGLED:
    draft.stickyBannerAdVisible = action.visible;
    break;
  case SECTION_VISIBILITY_CHANGED:
    draft.stickySectionVisibility = {
      ...draft.stickySectionVisibility,
      [action.section]: action.visible,
    };
    break;
  default:
    return draft;
  }
});
