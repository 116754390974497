import React from 'react';
import {hydrate} from 'react-dom';
import {Provider} from 'react-redux';
import {renderToString} from 'react-dom/server';
import {ServerStyleSheet} from 'styled-components';

import {normalize} from '@/normalize';
import {StyleWrapper} from '@/style';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import bugsnag from '@/bugsnag';
import {logPerfMetrics, logPageLoad, logReactRenderError} from '@/librato';
import {logWebVitalsToGA} from '@/ga';
import {logWebVitalsToMixpanel} from '@/mixpanel';
import {AppConfig} from '@/config';
import {AdsContext} from '@/util/ads';
import {BugsnagFallbackComponent} from '@/components';
import initializeClientState from './initializeClientState';
import '@/i18n';

export const wrapApp = (app, state, reducer, createMiddleware) => (
  <React.StrictMode>
    <Provider store={createStore(reducer, state, composeWithDevTools(applyMiddleware(createMiddleware())))}>
      <StyleWrapper>
        {app}
      </StyleWrapper>
    </Provider>
  </React.StrictMode>
);

export const clientRender = (page, app, reducer, createMiddleware) => {
  const state = initializeClientState();

  const abTestRandomInt = Math.floor(Math.random() * 100);
  const longTailCacheExperiment = state.songPage?.longTailCacheExperiment;

  if (abTestRandomInt < AppConfig.gaWebVitalsSamplingPercentage) {
    logWebVitalsToGA();
  }

  const shouldLogWebVitalsToMixpanel = page === 'song' &&
  (abTestRandomInt < AppConfig.mixpanelWebVitalsSamplingPercentage[state.deviceType] ||
    longTailCacheExperiment);

  if (shouldLogWebVitalsToMixpanel) {
    logWebVitalsToMixpanel(page, state, {
      cf_cache_status: document.querySelector('meta[itemprop="cf-cache-status"]')?.content,
      long_tail_cache: document.querySelector('meta[itemprop="long-tail-cache"]')?.content,
      rendered_with_cache: document.querySelector('meta[itemprop="rendered-with-cache"]')?.content,
    });
  }

  logPerfMetrics({page, deviceType: state.deviceType});
  logPageLoad({page, deviceType: state.deviceType, longTailCacheExperiment});
  const BugsnagWithErrorBoundary = bugsnag.getPlugin('react');
  hydrate(
    <BugsnagWithErrorBoundary
      FallbackComponent={BugsnagFallbackComponent}
      beforeSend={(error) => {
        error.updateMetaData('react', {reactCrash: true});
        logReactRenderError({page, deviceType: state.deviceType});
      }}
    >
      {wrapApp(app, state, reducer, createMiddleware)}
    </BugsnagWithErrorBoundary>,
    document.getElementById('application')
  );
};

export const serverRender = (app, data, schema, reducer, createMiddleware) => {
  const normalizedData = normalize(data, schema);
  const state = {...normalizedData.result, entities: normalizedData.entities};
  const sheet = new ServerStyleSheet();
  const ads = {};
  return {
    state,
    html: renderToString(
      sheet.collectStyles(
        <AdsContext.Provider value={ads}>
          {wrapApp(app, state, reducer, createMiddleware)}
        </AdsContext.Provider>
      )
    ),
    css: sheet.getStyleTags(),
    ads: Object.keys(ads),
  };
};
