import React from 'react';
import styled from 'styled-components';
import {TextLabel} from '@/components';
import {useTranslation} from 'react-i18next';

const PageHeaderAuthLinks = () => {
  const {t} = useTranslation();
  return (
    <PageHeaderAuthLinks.Container>
      <PageHeaderAuthLinks.Link href="/signup">
        <TextLabel>{t('page_header.auth_links.sign_up')}</TextLabel>
      </PageHeaderAuthLinks.Link>
      <PageHeaderAuthLinks.Link href="/login">
        <TextLabel>{t('page_header.auth_links.sign_in')}</TextLabel>
      </PageHeaderAuthLinks.Link>
    </PageHeaderAuthLinks.Container>
  );
};

export default React.memo(PageHeaderAuthLinks);

PageHeaderAuthLinks.Link = styled.a`
  color: ${p => p.theme.color.background.on};
`;

PageHeaderAuthLinks.Container = styled.div`
  display: flex;

  ${PageHeaderAuthLinks.Link} + ${PageHeaderAuthLinks.Link} {
    margin-left: ${p => p.theme.space.full}
  }
`;
