import React from 'react';
import PropTypes from 'prop-types';
import {useEntity} from '@/hooks';
import styled from 'styled-components';
import {SizedImage} from '@/components';

const UserAvatar = ({id, border, size, className}) => {
  const user = useEntity('users', id);
  return (
    <UserAvatar.Container border={border} className={className}>
      <SizedImage src={user.avatar.medium.url} width={size} height={size} />
    </UserAvatar.Container>
  );
};

UserAvatar.propTypes = {
  id: PropTypes.number.isRequired,
  border: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
};

UserAvatar.defaultProps = {
  border: false,
  size: 32,
};

export default React.memo(UserAvatar);

UserAvatar.Container = styled.div`
  overflow: hidden;
  border-radius: 100%;
  ${p => p.border && `border: 1px solid ${p.theme.color.background.on}`};
`;
