import React from 'react';
import styled, {keyframes} from 'styled-components';

import Ellipse from '@/svg/ellipse.svg';

const circumfrence = 2 * Math.PI * 7.3;

const dashoffset = keyframes`
  from {stroke-dashoffset: 0;}
  to   {stroke-dashoffset: -${circumfrence};}
`;

const Spinner = styled(Ellipse)`
  height: 1em;
  vertical-align: middle;

  animation: ${dashoffset} 3s linear infinite;
  stroke: ${p => p.theme.color.background.on};
`;

export default React.memo(Spinner);
