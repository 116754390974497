import {onCLS, onFCP, onFID, onINP, onLCP, onTTFB} from 'web-vitals/attribution';

const getTarget = (name, attribution) => {
  switch (name) {
  case 'CLS':
    return attribution.largestShiftTarget;
  case 'FCP':
  case 'LCP':
    return attribution.element;
  case 'FID':
  case 'INP':
    return attribution.eventTarget;
  }
};

export const logWebVitalsToGA = () => {
  const sendToGoogleAnalytics = ({name, delta, value, id, attribution}) => {
    window.gtag('event', name, {
      value: delta,
      metric_id: id,
      metric_value: value,
      metric_delta: delta,
      debug_target: getTarget(name, attribution),
    });
  };

  onCLS(sendToGoogleAnalytics);
  onFID(sendToGoogleAnalytics);
  onLCP(sendToGoogleAnalytics);
  onFCP(sendToGoogleAnalytics);
  onINP(sendToGoogleAnalytics);
  onTTFB(sendToGoogleAnalytics);
};

export const trackGADimension = (name, value) => {
  if (typeof window.gtag === 'function') {
    window.gtag('set', name, value);
  }
};
