import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InlineSvg = ({aspectRatio, lineHeight, svg, className, verticalAlign}) => {
  const Component = svg;
  const svgWidth = aspectRatio * lineHeight;

  return (
    <InlineSvg.Wrapper lineHeight={lineHeight} svgWidth={svgWidth} className={className} verticalAlign={verticalAlign}>
      <Component/>
    </InlineSvg.Wrapper>
  );
};

InlineSvg.Wrapper = styled.span`
  svg {
    height: ${p => p.lineHeight}em;
    margin-left: calc(-1em * ${p => p.svgWidth});
    vertical-align: ${p => p.verticalAlign};
  }

  ::before {
    content: '\\2003';
    font-size: ${p => p.svgWidth}em;
  }
`;

InlineSvg.defaultProps = {
  verticalAlign: 'top',
};

InlineSvg.propTypes = {
  lineHeight: PropTypes.number.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  svg: PropTypes.func.isRequired,
  className: PropTypes.string,
  verticalAlign: PropTypes.string,
};

export default React.memo(InlineSvg);
