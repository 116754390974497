import {post, destroy, put} from '@/api/client';
import {answerSchema, questionSchema, normalize} from '@/normalize';

const buildAnswerParams = payload => ({
  answer: {
    pin_question: payload.pin_question,
    answer_source: payload.add_source ? payload.answer.answer_source : null,
    body: payload.answer.body,
  },
});

export const createAnswer = async (questionId, payload) => {
  const response = await post(`/questions/${questionId}/answers`, buildAnswerParams(payload));
  return normalize(response, {answer: answerSchema, question: questionSchema});
};

export const editAnswer = async (answerId, payload) => {
  const response = await put(`/answers/${answerId}`, buildAnswerParams(payload));
  return normalize(response, {answer: answerSchema, question: questionSchema});
};

export const clearAnswer = async (answerId) => {
  const response = await destroy(`/answers/${answerId}`);
  return normalize(response, {answer: answerSchema, question: questionSchema});
};
