import {getCLS, getFCP, getFID, getLCP, getTTFB} from 'web-vitals';
import {measure, count} from '@/../modules/librato';

export const logPerfMetrics = ({page, deviceType}) => {
  const source = `react.${page}.${deviceType}`;
  const sendToAnalytics = metric => measure(metric.name, metric.value, {source});
  getCLS(sendToAnalytics);
  getFID(sendToAnalytics);
  getLCP(sendToAnalytics);
  getFCP(sendToAnalytics);
  getTTFB(sendToAnalytics);
};

export const logPageLoad = ({page, deviceType, longTailCacheExperiment}) => {
  count('react_page_load', {source: `react.${page}.${deviceType}`});
  if (longTailCacheExperiment) {
    count('s3_experiment_page_load', {source: `react.${longTailCacheExperiment}`});
  }
};

export const logReactRenderError = ({page, deviceType}) => count('react_render_error', {source: `react.${page}.${deviceType}`});

export const logCloudflareBanner = () => count('cloudflare_error.always_online_banner', {source: 'react'});
