import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Play from '@/svg/play.svg';

const PlayButtonOverlay = ({children}) =>
  <PlayButtonOverlay.Container>
    {children}
    <PlayButtonOverlay.Icon>
      <Play />
    </PlayButtonOverlay.Icon>
  </PlayButtonOverlay.Container>;

PlayButtonOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PlayButtonOverlay;

PlayButtonOverlay.Container = styled.div`
  position: relative;
`;

PlayButtonOverlay.Icon = styled.div`
  position: absolute;
  right: ${p => p.theme.space.full};
  bottom: ${p => p.theme.space.full};
  width: 3rem;
  height: 3rem;
`;
