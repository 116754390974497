import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from './TextInput';

const Textarea = React.forwardRef(({autoResize, expandOnFocus, onChange, onFocus, ...props}, ref) => {
  const innerRef = useRef();

  const setRef = useCallback((element) => {
    innerRef.current = element;
    if (ref) {
      ref.current = element;
    }
  }, [ref]);

  const resize = useCallback(() => {
    const element = innerRef.current;
    if (element) {
      element.style.height = 'auto';
      const computedStyle = window.getComputedStyle(element);
      element.style.height = `calc(${element.scrollHeight}px + ${computedStyle.borderTopWidth} + ${computedStyle.borderBottomWidth})`;
    }
  }, []);

  const handleOnChange = useCallback((e) => {
    onChange(e);
    autoResize && resize();
  }, [onChange, autoResize, resize]);

  const [minRows, setMinRows] = useState(expandOnFocus ? 1 : 2);
  const handleOnFocus = useCallback((e) => {
    onFocus && onFocus(e);
    setMinRows(2);
  }, [onFocus]);

  useEffect(() => {
    autoResize && resize();
  }, [autoResize, resize]);

  return (
    <Textarea.Input
      ref={setRef}
      rows={minRows}
      as="textarea"
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      $autoResize={autoResize}
      {...props}
    />
  );
});

Textarea.displayName = 'ForwardRef(Textarea)';

Textarea.propTypes = {
  autoResize: PropTypes.bool,
  className: PropTypes.string,
  expandOnFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
};

Textarea.defaultProps = {
  autoResize: true,
  expandOnFocus: false,
};

Textarea.Input = styled(TextInput)`
  ${p => p.$autoResize ? `
    overflow-y: hidden;
    resize: none;
  ` : `
    overflow-y: scroll;
  `}
`;

export default React.memo(Textarea);
