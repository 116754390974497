import {createContext} from 'react';

const adsClient = new Promise((ready) => {
  if (typeof window !== 'undefined') {
    window['Genius.ads'] = window['Genius.ads'] || [];
    window['Genius.ads'].push(ready);
  }
});

export const render = (name, instance) => adsClient.then(ads => ads.render(name, instance));
export const dispose = (name, instance) => adsClient.then(ads => ads.dispose(name, instance));
export const refresh = (name, instance, heightLimit) => adsClient.then(ads => ads.refresh(name, instance, heightLimit));

export const getBaseTargeting = name => adsClient.then(ads => ads.get_base_targeting(name));
export const getAsyncTargeting = (name, instance) => adsClient.then(ads => ads.get_async_targeting(name, instance));

export const AdsContext = createContext({});
