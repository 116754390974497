import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {IntersectionObserver} from '@/util';
import {adRefreshRequested} from '@/actions';
import {useAd} from '@/hooks';

const VIEWABILITY_SECONDS = 1;
const MS_IN_SECONDS = 1000;

export default ({name, instance, refreshIntervalSeconds, ref}) => {
  const {impressionViewable} = useAd(name, instance);
  const [inView, setInView] = useState(false);
  const [refreshTimeoutReached, setRefreshTimeoutReached] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ref.current || !refreshIntervalSeconds || !impressionViewable) return;

    let refreshRate = refreshIntervalSeconds;

    const iframe = ref.current.querySelector('iframe[data-genius-ana-refresh-rate-override]');
    if (iframe) {
      refreshRate = Math.max(
        refreshRate,
        Number(iframe.dataset.geniusAnaRefreshRateOverride)
      );
    }

    const timeoutId = setTimeout(() => {
      setRefreshTimeoutReached(true);
    }, (refreshRate - VIEWABILITY_SECONDS) * MS_IN_SECONDS);

    return () => clearTimeout(timeoutId);
  }, [ref, refreshIntervalSeconds, impressionViewable]);

  useEffect(() => {
    if (!ref.current || !refreshIntervalSeconds) return;

    const observer = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    }, {
      threshold: .5,
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, refreshIntervalSeconds]);

  useEffect(() => {
    if (refreshTimeoutReached && inView) {
      dispatch(adRefreshRequested(name, instance));
      setRefreshTimeoutReached(false);
    }
  }, [refreshTimeoutReached, inView, dispatch, name, instance]);
};
