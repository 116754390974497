import {get, post, put, del} from '@/api/client';
import {commentsSchema, commentSchema, normalize} from '@/normalize';

export const getComments = async (entity, id, {page = 1, perPage = 10}) => {
  const response = await get(`/${entity}/${id}/comments`, {
    page,
    per_page: perPage,
  });
  return normalize(response, commentsSchema);
};

export const createComment = async (entity, id, {userId, body, reason, name, email}) => {
  const commentRequest = {reason, body, name, email};
  if (!userId) {
    commentRequest['anonymous_user'] = {name};
  }
  const {comment} = await post(`/${entity}/${id}/comments`, {comment: commentRequest});
  return normalize(comment, commentSchema);
};

export const deleteComment = id => del(`/comments/${id}`);

export const changeCommentState = async (id, state) => {
  const {comment} = await put(`/comments/${id}/change_state`, {state_event: state});
  return normalize(comment, commentSchema);
};
