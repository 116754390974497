import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend, input, button,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: inherit;
  }

  html { line-height: 1; }

  ol, ul { list-style: none; }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
  }

  q, blockquote {
    quotes: none;

    &:before, &:after {
      content: "";
      content: none;
    }
  }

  a img { border: none; }

  article, aside, details, figcaption, figure, footer,
  header, hgroup, main, menu, nav, section, summary { display: block; }

  button {
    background: unset;
    box-shadow: unset;
    border: unset;
    text-shadow: unset;
    cursor: pointer;
  }

  body {
    overflow-x: hidden;
    background-color: ${p => p.theme.color.background.main};
    color: ${p => p.theme.color.background.on};
    font-family: ${p => p.theme.font.reading};
    line-height: ${p => p.theme.lineHeight.reading};
    -webkit-text-size-adjust: 100%;
  }

  img { max-width: 100%; }

  li { list-style: none; }

  a {
    color: ${p => p.theme.color.secondary.variant};
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  *, *:before, *:after { box-sizing: border-box; }

  hr {
    border: 1px solid ${p => p.theme.color.background.variant};
    border-width: 1px 0 0;
    margin: ${p => p.theme.space.full} 0;
  }

  pre {
    white-space: pre-wrap;
  }

  ::selection { background-color: ${p => p.theme.color.selection}; }

  .noscroll {
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  .noscroll-fixed {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  .grecaptcha-badge { visibility: hidden; }
`;
