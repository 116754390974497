import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DfpAd from './DfpAd';
import XSmall from '@/svg/xSmall.svg';
import {useAd} from '@/hooks';

const CloseableAd = ({name, lazy}) => {
  const [closed, setClosed] = useState(false);
  const [closeable, setCloseable] = useState(false);
  const close = useCallback(() => setClosed(true), []);
  const {state} = useAd(name);

  useEffect(() => {
    if (state === 'filled') {
      setTimeout(() => setCloseable(true), 3000);
    }
  }, [state]);

  return (
    closed ? null :
      <CloseableAd.Container>
        <DfpAd name={name} lazy={lazy} />
        {closeable &&
          <CloseableAd.Close onClick={close}>
            <XSmall />
          </CloseableAd.Close>
        }
      </CloseableAd.Container>
  );
};

CloseableAd.propTypes = {
  name: PropTypes.string.isRequired,
  lazy: PropTypes.bool,
};

CloseableAd.defaultProps = {
  lazy: true,
};

export default CloseableAd;

CloseableAd.Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

CloseableAd.Close = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: ${p => p.theme.space.half};

  svg {
    display: block;
    width: ${p => p.theme.fontSize.xSmallReading};
    height: ${p => p.theme.fontSize.xSmallReading};
    fill: ${p => p.theme.color.background.onVariant};
  }
`;
