import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useFormContext} from 'react-hook-form';


const TextInput = React.forwardRef(({className, as, label, name, id, type, ...props}, ref) => {
  const {formState: {errors}} = useFormContext();
  const hasError = Boolean(errors[name]) && errors[name].type !== 'notify';
  return (
    <>
      <TextInput.Container className={className}>
        <TextInput.Input
          as={as}
          name={name}
          id={name || id}
          placeholder=" "
          type={type}
          hasError={hasError}
          ref={ref}
          {...props}
        />
        <TextInput.Label htmlFor={name || id} hasError={hasError}>
          {label}
        </TextInput.Label>
      </TextInput.Container>
      {Boolean(errors[name]) && <TextInput.Notification isError={hasError}>{errors[name].message}</TextInput.Notification>}
    </>
  );
});

TextInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.string,
  label: PropTypes.string,
};

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  type: 'text',
};

export default React.memo(TextInput);

TextInput.Container = styled.div`
  text-align: left;
  display: flex;
  flex-flow: column-reverse;
`;

const floatingInputPaddingTop = '1.0625rem';
const floatingInputPaddingBottom = '0.1875rem';

TextInput.Input = styled.input`
  font-size: ${p => p.theme.fontSize.smallReading};
  line-height: 1.5;
  border: 1px solid ${p => p.theme.color.background.on};
  background-color: transparent;
  color: ${p => p.theme.color.background.on};
  padding: ${p => p.theme.space.small};
  padding-top: ${floatingInputPaddingTop};
  padding-bottom: ${floatingInputPaddingBottom};
  margin-top: ${p => p.theme.space.small};
  width: 100%;

  &:placeholder-shown + label {
    cursor: text;
  }

  &:focus + label, &:not(:placeholder-shown) + label {
    transform: scale(.5);
  }

  ${p => p.hasError && `border: 1px solid ${p.theme.color.brandAccent.main}`}
`;

TextInput.Notification = styled.div`
  font-size: ${p => p.theme.fontSize.xSmallReading};
  color: white;
  background-color: ${p => p.isError ? p.theme.color.brandAccent.main : p.theme.color.background.on};
  padding: ${p => p.theme.space.xSmall} ${p => p.theme.space.small};
  font-family: ${p => p.theme.font.alternate};
`;

TextInput.Label = styled.label`
  line-height: calc((${p => p.theme.fontSize.smallReading} * 1.5) + ${floatingInputPaddingTop} + ${floatingInputPaddingBottom} + 2px);
  transition: all 0.2s;
  position: absolute;
  font-family: ${p => p.theme.font.alternate};
  padding-left: ${p => p.theme.space.small};
  transform-origin: 0.875rem ${p => p.theme.space.quarter};
`;
