import React, {useCallback, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {TextLabel, PlaceholderSpinnerIframe, PageHeaderDropdown, PageHeaderInboxUnreadCount} from '@/components';
import {useInboxCounts, useOnClickOutside, useHeaderItem, useCurrentUser} from '@/hooks';
import {messageOpened, inboxOpened, newInboxEvent, inboxMarkedRead} from '@/actions';
import snakeCase from 'lodash/snakeCase';
import {capitalize} from '@/util';

const PageHeaderInbox = ({
  name,
  label,
  icon,
  syncCountToTitle,
  abbreviateAfter,
  maxViewportToHideLabel,
}) => {
  const dispatch = useDispatch();
  const {open, close, isOpen} = useHeaderItem(name);

  const currentUser = useCurrentUser();
  const channelName = currentUser.pusherChannel;
  const unreadCount = currentUser[`unread${capitalize(name)}Count`];

  const onMessageOpen = useCallback(
    messagesRead => dispatch(messageOpened({messagesRead, userId: currentUser.id})),
    [dispatch, currentUser.id]);

  const onNewEvent = useCallback(
    () => dispatch(newInboxEvent({userId: currentUser.id})),
    [dispatch, currentUser.id]);

  const onMarkedRead = useCallback(
    () => dispatch(inboxMarkedRead({inbox: name, userId: currentUser.id})),
    [dispatch, name, currentUser.id]);

  useInboxCounts({
    name,
    channelName,
    onNewEvent,
    onMessageOpen,
    onMarkedRead,
    syncCountToTitle,
    unreadCount,
  });

  const element = useRef(null);
  const onClickOutside = useCallback(() => {
    if (isOpen) {
      close();
    }
  }, [isOpen, close]);
  useOnClickOutside({element, onClickOutside});

  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
      dispatch(inboxOpened({inbox: name, userId: currentUser.id}));
    }
  }, [isOpen, open, close, dispatch, name, currentUser]);

  const Icon = icon;
  return (
    <div ref={element}>
      <PageHeaderInbox.Container onClick={toggle}>
        <Icon />
        <PageHeaderInbox.Label maxViewportToHideLabel={maxViewportToHideLabel}>
          <TextLabel>{label}</TextLabel>
        </PageHeaderInbox.Label>
        <PageHeaderInboxUnreadCount unreadCount={unreadCount} abbreviateAfter={abbreviateAfter} />
      </PageHeaderInbox.Container>
      <PageHeaderDropdown width="large" isOpen={isOpen}>
        <PlaceholderSpinnerIframe src={`/inboxes/${snakeCase(name)}?embed=true`} />
      </PageHeaderDropdown>
    </div>
  );
};

PageHeaderInbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  syncCountToTitle: PropTypes.bool,
  abbreviateAfter: PropTypes.number,
  maxViewportToHideLabel: PropTypes.string,
};

PageHeaderInbox.defaultProps = {
  maxViewportToHideLabel: '1200px',
};

export default React.memo(PageHeaderInbox);

PageHeaderInbox.Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: ${p => p.theme.header.height};

  svg {
    fill: ${p => p.theme.color.background.on};
    height: 18px;
    width: 18px;
  }
`;

PageHeaderInbox.Label = styled.div`
  margin-left: ${p => p.theme.space.xSmall};
  display: flex;
  align-items: center;

  @media (max-width: ${p => p.maxViewportToHideLabel}) {
    display: none;
  }
`;

PageHeaderInbox.Placeholder = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

PageHeaderInbox.Iframe = styled.iframe`
  display: ${p => p.hide ? 'none' : 'block'};
  width: 100%;
`;
