import React, {useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import {mixpanelEventTriggered} from '@/actions';
import Youtube from '@/svg/youtube.svg';
import Instagram from '@/svg/instagram.svg';
import Facebook from '@/svg/facebook.svg';
import Twitter from '@/svg/twitter.svg';

const SocialLinks = ({className, ctx, size = 22}) => {
  const dispatch = useDispatch();
  const trackClick = useCallback((source) => {
    dispatch(mixpanelEventTriggered('song:social_link_clicked', {source, ctx}));
  }, [ctx, dispatch]);

  const commonProps = {
    height: size,
    target: '_blank',
    rel: 'noopener',
  };

  return (
    <SocialLinks.Container className={className}>
      <SocialLinks.Link
        href="https://www.facebook.com/Genius/"
        onClick={() => trackClick('facebook')}
        {...commonProps}
      >
        <Facebook />
      </SocialLinks.Link>
      <SocialLinks.Link
        href="https://twitter.com/Genius"
        onClick={() => trackClick('twitter')}
        {...commonProps}
      >
        <Twitter />
      </SocialLinks.Link>
      <SocialLinks.Link
        href="https://www.instagram.com/genius/"
        onClick={() => trackClick('instagram')}
        {...commonProps}
      >
        <Instagram />
      </SocialLinks.Link>
      <SocialLinks.Link
        href="https://www.youtube.com/genius"
        onClick={() => trackClick('youtube')}
        {...commonProps}
        height={size / 22 * 19}
      >
        <Youtube />
      </SocialLinks.Link>
    </SocialLinks.Container>
  );
};

SocialLinks.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  ctx: PropTypes.string,
};

export default React.memo(SocialLinks);

SocialLinks.Slogan = styled.div`
  font-size: ${p => p.theme.fontSize.headline};
  font-weight: 100;
  line-height: ${p => p.theme.lineHeight.short};
`;

SocialLinks.Link = styled.a`
  svg {
    display: block;
    height: ${p => p.height}px;
    fill: ${p => p.theme.color.background.on};
  }
`;

SocialLinks.Container = styled.div`
  display: flex;
  align-items: center;

  ${SocialLinks.Link} + ${SocialLinks.Link} {
    margin-left: ${p => p.theme.space.xLarge};
  }
`;
