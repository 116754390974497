import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import Wordmark from '@/svg/wordmark.svg';

const BugsnagFallbackComponent = () => (
  <>
    <GlobalStyle />
    <BugsnagFallbackComponent.Banner>
      <BugsnagFallbackComponent.BannerLogoLink href="https://genius.com">
        <Wordmark />
      </BugsnagFallbackComponent.BannerLogoLink>
    </BugsnagFallbackComponent.Banner>
    <BugsnagFallbackComponent.Body>
      <BugsnagFallbackComponent.WinkingLogo alt="logo" src="https://assets.genius.com/images/winking_sgnarly.gif" />
      <h1>Oops! An error occurred</h1>
      <p>
        Sorry, we didn&#39;t mean for that to happen!
      </p>
      <p>
        If this problem persists, please <a href="https://genius.com/bugs">let us know!</a>
      </p>
    </BugsnagFallbackComponent.Body>
  </>
);

export default BugsnagFallbackComponent;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: Programme, Arial, sans-serif;
  }

  a {
    color: #7d8fe8;
    text-decoration: none;
  }
`;

BugsnagFallbackComponent.Banner = styled.div`
  height: 41px;
  background-color: #ffff64;
  display: flex;
  align-items: center;
  justify-content: center;
`;

BugsnagFallbackComponent.Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2.25rem 1.75rem;
`;

BugsnagFallbackComponent.WinkingLogo = styled.img`
  margin: 0 auto;
  width: 130px;
  height: 130px;
`;

BugsnagFallbackComponent.BannerLogoLink = styled.a`
  width: 135px;
  padding-top: 5px;
`;

