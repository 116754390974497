import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {sectionVisibilityChanged} from '@/actions';
import {useDistanceAbove} from '@/hooks';
import {zIndex} from '@/style';
import {IntersectionObserver} from '@/util';

const SectionScrollSentinel = ({section, children}) => {
  const dispatch = useDispatch();
  const element = useRef(null);

  const observer = useMemo(() => (
    new IntersectionObserver(([entry]) => {
      dispatch(sectionVisibilityChanged(section, entry.isIntersecting || entry.boundingClientRect.bottom < 0));
    }, {
      threshold: 0,
    })
  ), [section, dispatch]);

  useEffect(() => {
    observer.observe(element.current);
    return () => observer.disconnect();
  }, [observer, element]);

  const distanceAboveBottomStickyNav = useDistanceAbove('bottomStickyNav');

  return (
    <SectionScrollSentinel.Container>
      <SectionScrollSentinel.Element ref={element} distanceAboveBottomStickyNav={distanceAboveBottomStickyNav} />
      <SectionScrollSentinel.SectionAnchor id={section} distanceAboveBottomStickyNav={distanceAboveBottomStickyNav} />
      {children}
    </SectionScrollSentinel.Container>
  );
};

SectionScrollSentinel.propTypes = {
  section: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SectionScrollSentinel.Container = styled.div`
  position: relative;
`;
SectionScrollSentinel.Element = styled.div`
  position: absolute;
  top: ${p => `calc(100vh - (${p.distanceAboveBottomStickyNav}))`};
  bottom: ${p => `calc(${p.distanceAboveBottomStickyNav})`};
  width: 100%;
  ${zIndex('sentinel')}
`;
SectionScrollSentinel.SectionAnchor = styled.div`
  position: absolute;
  top: ${p => `calc(1px - (${p.distanceAboveBottomStickyNav}))`};
`;

export default React.memo(SectionScrollSentinel);
