import {useTranslation} from 'react-i18next';

import {useEntity} from '@/hooks';
import useStubhubTrackedUrl from './useStubhubTrackedUrl';

const useStubhubArtistProps = (songId) => {
  const {t} = useTranslation();

  const song = useEntity('songs', songId);
  const stubhubArtist = useEntity('stubhubArtists', song?.stubhubArtist);
  const artist = useEntity('artists', stubhubArtist?.artist);

  const link = useStubhubTrackedUrl(song?.stubhubDeal, 'artist', stubhubArtist?.stubhubId);

  if (!stubhubArtist?.minConcertTicketPriceForDisplay) return null;

  return {
    link,
    headline: t('stubhub.artist.headline', {artistName: artist.name}),
    body: t('stubhub.artist.body', {ticketPrice: stubhubArtist.minConcertTicketPriceForDisplay}),
    trackParams: {
      stubhub_link_type: 'artist',
      stubhub_artist_id: artist.id,
      stubhub_artist_name: artist.name,
      stubhub_min_ticket_price: stubhubArtist.minConcertTicketPriceForDisplay,
    },
  };
};

export default useStubhubArtistProps;
