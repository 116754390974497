import get from 'lodash/get';

import {useTheme, useCurrentUser} from '@/hooks';
import {can} from '@/util';

const useReferentColors = (classification) => {
  const theme = useTheme();
  const currentUser = useCurrentUser();
  const shouldHighlightUnreviewedReferents = can('see_unreviewed_referents', currentUser);

  if (classification === 'verified' || classification === 'cosigned') {
    return {
      activeColor: get(theme, 'color.brandPrimary'),
      inactiveColor: {
        main: get(theme, 'color.primary.variant'),
        on: get(theme, 'color.background.on'),
      },
    };
  } else if (classification === 'unreviewed' && shouldHighlightUnreviewedReferents) {
    return {
      activeColor: get(theme, 'color.unreviewedReferentHover'),
      inactiveColor: get(theme, 'color.unreviewedReferent'),
    };
  } else {
    return {
      activeColor: get(theme, 'color.referent'),
      inactiveColor: {
        main: get(theme, 'color.background.variant'),
        on: get(theme, 'color.background.on'),
      },
    };
  }
};

export default useReferentColors;
