import {useCallback} from 'react';
import {userLoggedOut} from '@/actions';
import noop from 'lodash/noop';
import {useSelector, useDispatch} from 'react-redux';
import {useLoggedIn, useMixpanelEvent} from '@/hooks';
import {AppConfig} from '@/config';
import styled from 'styled-components';

export const useRedux = () => {
  const loggedIn = useLoggedIn();
  const trackPrivacyPreferencesClicked = useMixpanelEvent('gdpr_consent:footer_recall_click');
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(userLoggedOut()), [dispatch]);
  const cmpEnabled = useSelector(state => state.session.cmpEnabled);
  const logoutLoading = useSelector(state => state.userActions.logoutLoading);

  const links = [
    [
      {label: 'about', action: '/about'},
      {label: 'contributor_guidelines', action: '/contributor_guidelines'},
      {label: 'press', action: '/press'},
    ],
    [
      {label: 'licensing', action: '/static/licensing'},
      {label: 'jobs', action: '/jobs'},
      {label: 'developers', action: '/developers'},
      {label: 'copyright_policy', action: '/static/copyright'},
      {label: 'contact_us', action: '/feedback/new'},
    ],
  ];

  if (AppConfig.artistPromoPortalLaunched) {
    links[0].push({label: 'shop', action: 'https://shop.genius.com'});
  }

  links[0].push({label: 'advertise', action: 'mailto:inquiry@genius.com'});
  links[0].push({label: 'privacy_policy', action: '/static/privacy_policy'});

  if (cmpEnabled) {
    links[0].push({
      label: 'privacy_preferences',
      className: 'optanon-toggle-display',
      action: trackPrivacyPreferencesClicked,
    });
  }

  if (loggedIn) {
    links[0].push({label: 'delete_account', action: '/static/privacy_policy#delete-account'});
    if (logoutLoading) {
      links[1].push({label: 'signing_out', action: noop});
    } else {
      links[1].push({label: 'sign_out', action: logout});
    }
  } else {
    links[1].push({label: 'sign_in', action: '/login'});
  }

  links[1].push({label: 'ccpa', action: '/static/ccpa'});

  return {links};
};

export const FooterGridMobile = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 
  [grid-start] 
    minmax(36px, 1fr) 
  [center-start] 
    minmax(24px, 100px) 20px  minmax(24px, 100px) 20px minmax(24px, 100px) 20px minmax(24px, 100px) 
  [center-end] 
    minmax(36px, 1fr) 
  [grid-end];
  grid-row-gap: 30px;
`;

export const FooterGridDesktop = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 
  [grid-start] 
    repeat(3, minmax(14px, 44px)) 
  [center-start] 
    repeat(6, minmax(14px, 44px)) 
  [center-end] 
    repeat(3, minmax(14px, 44px)) 
  [grid-end];
  grid-column-gap: 60px;
  grid-row-gap: 40px;
`;

export const FooterGridCenter = styled.div`
  grid-column: center-start / center-end;
`;
